import {BeverageDetails} from '../graphql/types/BeverageDetails';
import {BeverageInput} from '../graphql/types/globalTypes';
import {MapType} from './Base';
import {VersionedItem} from './VersionedItem';
import {BeverageType} from './BeverageType';
import {Ingredient} from './Ingredient';
import {ProducerCore} from './Producer';
import {BeverageMinimum} from '../graphql/types/BeverageMinimum';


export class BeverageCore extends VersionedItem {

  beverageId?: string;
  description?: string;
  style?: string;
  year?: number;
  website?: string;
  logoUrls?: MapType;
  links?: MapType;
  properties?: MapType;

  get currentId() {
    return this.beverageId;
  }

  static fromBeverage(beverage: Beverage): BeverageCore {
    const core = new BeverageCore();
    if (beverage != null) {
      core.id = beverage.id;
      core.name = beverage.name;
      core.beverageId = beverage.beverageId;
      core.description = beverage.description;
      core.style = beverage.style;
      core.year = beverage.year;
      core.website = beverage.website;
      core.logoUrls = beverage.logoUrls;
      core.links = beverage.links;
      core.properties = beverage.properties;
    }
    return core;
  }

  setFromGraphQL(data: BeverageMinimum|null) {
    if (data == null) return;

    super.setFromGraphQL(data);
    this.beverageId = data.beverageId == null ? undefined : data.beverageId;
    this.description = data.description == null ? undefined : data.description;
    this.style = data.style == null ? undefined : data.style;
    this.year = data.year == null ? undefined : data.year;
    this.website = data.website == null ? undefined : data.website;
    this.logoUrls = data.logoUrls;
    this.links = data.links;
    this.properties = data.properties;
  }
}


export class Beverage extends BeverageCore {
  beverageType?: BeverageType;
  producer?: ProducerCore;
  ingredients?: Ingredient[];

  setFromGraphQL(data: BeverageDetails|null) {
    if (data == null) return;

    super.setFromGraphQL(data);
    this.beverageType = data.beverageType == null
      ? undefined
      : BeverageType.fromGraphQL(data.beverageType);
    this.producer = data.producer == null
      ? undefined
      : ProducerCore.fromGraphQL(data.producer);
    this.ingredients = data.ingredients == null
      ? undefined
      : data.ingredients.map(i => Ingredient.fromGraphQL(i));
  }

  toCore = () => {
    return BeverageCore.fromBeverage(this);
  }

  toInput(allProps=false): BeverageInput {
    const input = super.toInput(allProps);
    if (allProps) {
      return {
        ...input,
        beverageId: this.beverageId,
        description: this.description,
        style: this.style,
        year: this.year,
        website: this.website,
        logoUrls: JSON.stringify(this.logoUrls),
        links: JSON.stringify(this.links),
        properties: JSON.stringify(this.properties),
        beverageType: this.beverageType?.toInput(),
        producer: this.producer?.toInput(),
        ingredients: this.ingredients?.map(i => i.toInput()),
      };
    } else {
      return {
        ...input,
        beverageId: this.beverageId,
      };
    }
  }
}
