import {Item} from './Item';


export class PicklistItem extends Item {
  picklistName?: string;

  setFromGraphQL(data: Partial<PicklistItem>|null) {
    if (data == null) {
      return;
    }
    const {picklistName} = data;
    this.picklistName = picklistName == null ? undefined : picklistName;
  }
}
