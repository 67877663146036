import React, { FunctionComponent, useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { AuthContext } from '../../contexts';
import useFormValidation, { FormErrors } from '../../helpers/useFormValidation';
import { FacebookIcon, GoogleIcon } from '../../icons';
import { BaseModel } from '../../models';
import { EmailNotVerifiedError } from '../../firebase';


class SignInValues extends BaseModel {
  email: string = '';
  password: string = '';
}

function validateSignIn(values: SignInValues) {
  let errors: FormErrors<SignInValues> = {};

  // Email Errors
  if (!values.email) {
    errors.email = 'Email required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  // Password Errors
  if (!values.password) {
    errors.password = 'Password required';
  } else if (values.password.length < 6) {
    errors.password = 'Password must be at least 6 characters';
  }

  return errors;
}

type SubmitEvent = React.FormEvent<HTMLFormElement>;

const INITIAL_VALUE = new SignInValues();

interface Props {
  onComplete?: () => void
}

const SignIn: FunctionComponent<Props> = props => {

  const { onComplete } = props;

  const navigate = useNavigate();

  const {
    signInWithEmailAndPassword, signInWithFacebook, signInWithGoogle
  } = useContext(AuthContext);

  const [authError, setAuthError] = useState<string|null>(null);

  async function authenticateUser(authFunc: () => Promise<any>) {
    try {
      await authFunc();
      onComplete && onComplete();
    } catch (e) {
      if (e instanceof EmailNotVerifiedError) {
        navigate('/resend-verification-email', {replace: true});
      } else {
        console.error('Authentication Error:', e);
        if (typeof e === 'string') {
            setAuthError(e);
        } else if (e instanceof Error) {
            setAuthError(e.message);
        }
      }
    }
  }

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    formItem,
    formErrors,
    isValid,
    isSubmitting
  } = useFormValidation<SignInValues>(INITIAL_VALUE, validateSignIn);

  const theme = useTheme();

  const handleEmailSignIn = async (event: SubmitEvent) => {
    if (formItem == null) {
      return;
    }
    const {email, password} = formItem;
    await handleSubmit(
      event,
      () => authenticateUser(
        () => signInWithEmailAndPassword(email, password)
      )
    );
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      onComplete && onComplete();
    } catch (e) {
      console.error('Google Authentication Error:', e);
      if (typeof e === 'string') {
          setAuthError(e);
      } else if (e instanceof Error) {
          setAuthError(e.message);
      }
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      await signInWithFacebook();
      onComplete && onComplete();
    } catch (e) {
      console.error('Facebook Authentication Error:', e);
      if (typeof e === 'string') {
          setAuthError(e);
      } else if (e instanceof Error) {
          setAuthError(e.message);
      }
    }
  };

  if (isSubmitting) {
    return <CircularProgress/>
  }

  return (
    <div>

      <Box component='form'
        sx={theme.signIn.form}
        onSubmit={handleEmailSignIn}
      >
        <Typography
          sx={theme.signIn.title}
          variant='h2'
        >
          Sign in
        </Typography>
        <Typography
          align='center'
          sx={theme.signIn.suggestion}
          color='textSecondary'
          variant='body1'
        >
          Sign in with social media
        </Typography>
        <Grid
          sx={theme.signIn.socialButtons}
          container
          spacing={2}
        >
          <Grid item>
            <Button
              color='primary'
              onClick={handleFacebookSignIn}
              size='large'
              variant='contained'
            >
              <FacebookIcon sx={theme.signIn.socialIcon} />
              Login with Facebook
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleGoogleSignIn}
              size='large'
              variant='contained'
            >
              <GoogleIcon sx={theme.signIn.socialIcon} />
              Login with Google
            </Button>
          </Grid>
        </Grid>
        <Typography
          align='center'
          sx={theme.signIn.suggestion}
          color='textSecondary'
          variant='body1'
        >
          or login with email address
        </Typography>
        <TextField
          sx={theme.signIn.textField}
          error={formErrors.email != null}
          fullWidth
          helperText={formErrors.email}
          label='Email address'
          name='email'
          onChange={handleChange}
          onBlur={handleBlur}
          type='text'
          value={formItem?.email || ''}
          variant='outlined'
        />
        <TextField
          sx={theme.signIn.textField}
          error={formErrors.password != null}
          fullWidth
          helperText={formErrors.password}
          label='Password'
          name='password'
          onChange={handleChange}
          onBlur={handleBlur}
          type='password'
          value={formItem?.password || ''}
          variant='outlined'
        />
        <Button
          sx={theme.signIn.signInButton}
          color='primary'
          disabled={!isValid}
          fullWidth
          size='large'
          type='submit'
          variant='contained'
        >
          Sign in now
        </Button>
        <Typography color='textSecondary' variant='body1'>
          Don't have an account?{' '}
          <Link
            component={RouterLink}
            to='/sign-up'
            replace={true}
            variant='h6'
          >
            Sign up
          </Link>
        </Typography>
        <Typography color='error' variant='body1'
        >
          {authError}
        </Typography>
      </Box>
    </div>
  );
};

export default SignIn;
