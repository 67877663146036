import {PureQueryOptions, useMutation} from '@apollo/client';
import getBeverageTypeQuery from '../queries/getBeverageTypeQuery.graphql';
import listBeverageTypesQuery from '../queries/listBeverageTypesQuery.graphql';
import updateBeverageTypeMutation from '../queries/updateBeverageTypeMutation.graphql';
import {UpdateBeverageType, UpdateBeverageTypeVariables} from '../types/UpdateBeverageType';
import deleteBeverageTypeMutation from '../queries/deleteBeverageTypeMutation.graphql';
import {DeleteBeverageType, DeleteBeverageTypeVariables} from '../types/DeleteBeverageType';
import {BeverageType} from '../../models';
import useGetBeverageTypeGraphQL from './useGetBeverageTypeGraphQL';
import {ListBeverageTypes} from '../types/ListBeverageTypes';

const Item = BeverageType;
type Item = BeverageType;
const UpdateMutation = updateBeverageTypeMutation;
type UpdateItem = UpdateBeverageType;
type UpdateVariables = UpdateBeverageTypeVariables;
const DeleteMutation = deleteBeverageTypeMutation;
type DeleteItem = DeleteBeverageType;
type DeleteVariables = DeleteBeverageTypeVariables;


type Props = {
  currentId?: string|null,
  name?: string,
  categoryName?: string,
  model?: { new (): Item; },
}

const useUpdateBeverageTypeGraphQL = (props?: Props) => {

  const {currentId, name, categoryName, model=Item} = {...props};
  const {item, gettingItem, getError} = useGetBeverageTypeGraphQL({currentId: currentId, name: name, model:model})

  const [_updateItem, {loading: updatingItem, error: updateError}] = useMutation<UpdateItem, UpdateVariables>(UpdateMutation, {
    refetchQueries: () => {
      const queries: PureQueryOptions[] = [{
        query: listBeverageTypesQuery,
        variables: {category: {name: categoryName}}
      }];
      if (currentId != null) {
        queries.push({
          query: getBeverageTypeQuery,
          variables: {beverageType: {beverageTypeId: currentId, name: name}}
        });
      }
      return queries;
    }
  });

  const [_deleteItem, {error: deleteError}] = useMutation<DeleteItem, DeleteVariables>(DeleteMutation, {
    update: (client) => {
      const data = client.readQuery<ListBeverageTypes>({
        query: listBeverageTypesQuery,
        variables: {category: {name: categoryName}}
      });
      const newData = {
        beverageTypes: data?.beverageTypes?.filter((i) => i?.beverageTypeId !== currentId)
      }
      client.writeQuery({
        query: listBeverageTypesQuery,
        variables: {category: {name: categoryName}},
        data: newData
      });
    },
    refetchQueries: () => [{
      query: listBeverageTypesQuery,
      variables: {beverageTypeType: {name: categoryName}}
    }]
  });

  const updateItem = async (updatedItem: Item) => {
    if (updatedItem == null || _updateItem == null) {
      return;
    }
    const input = updatedItem.toInput(true);
    if (categoryName != null && input.category == null) {
      input.category = {name: categoryName};
    }
    await _updateItem({
      variables: {beverageType: input},
    });
  }

  const deleteItem = async (deletedItem: Item, deleteReason?: string) => {
    if (deletedItem == null || _deleteItem == null) {
      return;
    }
    const input = deletedItem.toInput(true);
    await _deleteItem({
      variables: {beverageType: input, deleteReason: deleteReason},
    });
  }

  return {
    item,
    gettingItem,
    getError,
    updateItem,
    updatingItem,
    updateError: updateError?.message || null,
    deleteItem,
    deleteError: deleteError?.message || null,
  };
}

export default useUpdateBeverageTypeGraphQL;
