import React, { useEffect, useState } from 'react';
import { Box, Card, Paper, Tab, Tabs } from '@mui/material';

import { IngredientsList } from '../components/Ingredient';
import { HopEdit, HopView } from '../components/Hop';
import { Hop } from '../models';


const styles = {
  root: {
    flexGrow: 1,
  },
  card: {
    margin: '10px',
  },
  panel: {
    flexGrow: 1,
    margin: '10px',
  },
};


export default function BeerIngredientsView() {

  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    console.log('BeerIngredientsView useEffect');
  });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function HopsPanel() {
    return (
      <Box sx={styles.panel}>
        <Card sx={styles.card}>
          <IngredientsList
            typeName='Hop'
            title='Hops'
            model={Hop}
            EditComponent={HopEdit}
            ViewComponent={HopView}
          />
        </Card>
      </Box>
    );
  }

  function MaltsPanel() {
    return (
      <Box sx={styles.panel}>
        <Card sx={styles.card}>
          <IngredientsList
            typeName='Malt'
            title='Malts'
          />
        </Card>
      </Box>
    );
  }

  function YeastsPanel() {
    return (
      <Box sx={styles.panel}>
        <Card sx={styles.card}>
          <IngredientsList
            typeName='Yeast'
            title='Yeasts'
          />
        </Card>
      </Box>
    );
  }

  function AdjunctsPanel() {
    return (
      <Box sx={styles.panel}>
        <Card sx={styles.card}>
          <IngredientsList
            typeName='Adjunct'
            title='Adjuncts'
          />
        </Card>
      </Box>
    );
  }

  return (
    <Box>
      <Paper sx={styles.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          centered
        >
          <Tab label='Hops' />
          <Tab label='Malts' />
          <Tab label='Yeasts' />
          <Tab label='Adjuncts' />
        </Tabs>
      </Paper>
      {value === 0 && HopsPanel()}
      {value === 1 && MaltsPanel()}
      {value === 2 && YeastsPanel()}
      {value === 3 && AdjunctsPanel()}
    </Box>
  );
}
