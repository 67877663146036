import {Item} from './Item';
import {BeverageCategoryInput} from '../graphql/types/globalTypes';
import {BeverageCategoryDetails} from '../graphql/types/BeverageCategoryDetails';


export class BeverageCategory extends Item {
  description?: string;

  setFromGraphQL(data: Partial<BeverageCategoryDetails>|null) {
    super.setFromGraphQL(data);
    if (data != null) {
      this.description = data.description == null ? undefined : data.description;
    }
  }

  toInput(allProps=false): BeverageCategoryInput {
    const input = super.toInput(allProps);
    if (allProps) {
      return {
        ...input,
        description: this.description,
      };
    }
    if (this.id == null) {
      return {
        name: this.name,
      }
    }
    return input;
  }

  validate() {
    if (!super.validate()) {
      return false;
    }
    if (this.name == null || this.name === '') {
      return false;
    }
    return true;
  }
}
