import React, { FunctionComponent, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { AuthContext } from '../../contexts';
import useFormValidation, { FormErrors } from '../../helpers/useFormValidation';
import { BaseModel } from '../../models';


class SignInValues extends BaseModel {
  email: string = '';
  password: string = '';
}


function validateSignUp(values: SignInValues) {
  let errors: FormErrors<SignInValues> = {};

  // Email Errors
  if (!values.email) {
    errors.email = 'Email required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  // Password Errors
  if (!values.password) {
    errors.password = 'Password required';
  } else if (values.password.length < 6) {
    errors.password = 'Password must be at least 6 characters';
  }

  return errors;
}

type SubmitEvent = React.FormEvent<HTMLFormElement>;

const INITIAL_SIGN_IN = new SignInValues();

interface Props {
  onComplete?: () => void
}

const VerifySignIn: FunctionComponent<Props> = props => {

  const {onComplete} = props;
  const {confirmSignUpEmailLink} = useContext(AuthContext);
  const [authError, setAuthError] = React.useState<string|null>(null);

  async function authenticateUser(authFunc: () => Promise<any>) {
    try {
      await authFunc();
      onComplete && onComplete();
    } catch (e) {
      console.error('Authentication Error:', e);
      if (typeof e === 'string') {
          setAuthError(e);
      } else if (e instanceof Error) {
          setAuthError(e.message);
      }
    }
  }

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    updateProperty,
    formItem,
    formErrors,
    isSubmitting
  } = useFormValidation<SignInValues>(INITIAL_SIGN_IN, validateSignUp);

  const theme = useTheme();

  useEffect(() => {
    if (formItem != null && formItem.email === '') {
      const email = window.localStorage.getItem('emailForSignIn');
      if (email != null) {
        updateProperty('email', email);
      }
    }
  }, [formItem, updateProperty]);

  const handleEmailVerify = (event: SubmitEvent) => {
    if (formItem == null) {
      return;
    }
    const {email, password} = formItem;
    handleSubmit(
        event,
        () => authenticateUser(
            () => confirmSignUpEmailLink(email, password)
        )
    );
  };

  if (isSubmitting) {
    return <CircularProgress/>
  }

  return (
    <Box component='form'
      sx={theme.signIn.form}
      onSubmit={handleEmailVerify}
    >
      <Typography sx={theme.signIn.title} variant='h2'>
        Verify Login
      </Typography>
      <Typography
        align='center'
        sx={theme.signIn.suggestion}
        color='textSecondary'
        variant='body1'
      >
        Use your email and password to verify your new account
      </Typography>
      <TextField
        sx={theme.signIn.textField}
        error={formErrors.email != null}
        fullWidth
        helperText={formErrors.email}
        label='Email address'
        name='email'
        onChange={handleChange}
        onBlur={handleBlur}
        type='text'
        value={formItem?.email || ''}
        variant='outlined'
      />
      <TextField
        sx={theme.signIn.textField}
        error={formErrors.password != null}
        fullWidth
        helperText={formErrors.password}
        label='Password'
        name='password'
        onChange={handleChange}
        onBlur={handleBlur}
        type='password'
        value={formItem?.password || ''}
        variant='outlined'
      />
      <Button
        sx={theme.signIn.signInButton}
        color='primary'
        //disabled={!isValid}
        fullWidth
        size='large'
        type='submit'
        variant='contained'
      >
        Confirm Sign Up
      </Button>
      <Typography color='error' variant='body1'>
        {authError || ''}
      </Typography>
    </Box>
  );

};

export default VerifySignIn;
