import {getStorage, ref, uploadBytes} from 'firebase/storage';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {createUniqueId} from './createUniqueId';


export async function uploadFile(file: File, filename: string, dir: string) {

  let filePath = `uploads/${dir}/${filename}`;
  console.log('File to upload', filePath);

  // Upload image to firebase storage
  const firebaseStorage = getStorage();
  const firebaseStorageRef = ref(firebaseStorage, filePath);
  await uploadBytes(firebaseStorageRef, file);
  return filePath;
}


export async function uploadLogo(logoFile: File,
                                 logoDir: string) {

  const extension = logoFile.name.split('.').pop();
  console.log('Logo name', logoFile.name, extension);
  let newFilename = createUniqueId();
  if (extension !== logoFile.name) {
    newFilename = `${newFilename}.${extension}`;
  }

  let uploadPath: string|null = null;
  try {
    uploadPath = await uploadFile(logoFile, newFilename, logoDir);
    console.log('Got image path', uploadPath);
  } catch (e) {
    console.log('Error uploading logo', e);
    if (typeof e === 'string') {
        throw Error(e);
    } else if (e instanceof Error) {
        throw(e.message);
    }
  }

  if (uploadPath == null) {
    throw Error('Error getting logo upload path');
  }

  type LogoUrls = {[key:string]: string};
  const functions = getFunctions();
  const callable = httpsCallable<{logoURL: string}, LogoUrls>(functions,'handleCreateLogos');
  let logoUrls: LogoUrls = {};
  try {
    const result = await callable({
      logoURL: uploadPath,
    });
    console.log('Confirm logo upload response:', result.data);
    logoUrls = result.data;
  } catch (e) {
    console.log('Confirm logo upload error', e);
    if (typeof e === 'string') {
        throw Error(e);
    } else if (e instanceof Error) {
        throw(e.message);
    }
  }

  return logoUrls;
}
