import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Checkbox, FormControlLabel } from '@mui/material';

import CardGrid, { CardGridProps } from '../common/CardGrid';
import NumberEdit from '../common/NumberEdit';
import useListPlacesGraphQL, { ListPlacesProps } from '../../graphql/hooks/useListPlacesGraphQL';
import { Place } from '../../models';

import PlaceCard from './PlaceCard';

const styles = {
  header: {
    display: 'flex',
    marginTop: '-15px',
  },
  radiusLabel: {
    paddingTop: '15px',
  },
  radius: {
    maxWidth: '75px',
  },
};

type Props = CardGridProps & {
  onEdit?: (item: Place|null) => void,
  onSelect?: (item: Place|null) => void,
  typeName?: string,
  pluralName?: string,
}

const PlacesCardGrid = (props: Props) => {
  //console.log("Refreshing PlacesCardGrid");

  const {
    onEdit, onSelect, typeName, pluralName, // radius=20,
    ...cardGridProps} = props;

  const navigate = useNavigate();

  const [useNearby, setUseNearby] = useState<boolean>(true);
  const [radius, setRadius] = useState<number>(20);
  const [graphQLProps, setGraphQLProps] = useState<ListPlacesProps>({
    ...(typeName && {typeName: typeName}),
  });

  useEffect(() => {
    if (useNearby && 'geolocation' in navigator) {
      console.log('location enabled');
      navigator.geolocation.getCurrentPosition(function(position) {
        console.log('Latitude is :', position.coords.latitude);
        console.log('Longitude is :', position.coords.longitude);
        setGraphQLProps({
          typeName: typeName,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          radius: radius,
        })
      });
    } else {
      console.log('location not enabled');
      setGraphQLProps({typeName: typeName});
    }
  }, [radius, useNearby, typeName]);

  const handleNewPlace = () => {
    navigate('/place-edit/new')
  }

  const handleUseNearbyChange = () => {
    // Note that useNearby has not yet been toggled
    useNearby
      ? setGraphQLProps({typeName: typeName})
      : setGraphQLProps({typeName: typeName, radius: radius});
    setUseNearby(old => !old);
  };

  const handleRadiusChange = (r: number|null) => {
    if (r != null) {
      setRadius(r);
    }
  }

  const header =
    <Box sx={styles.header}>
      <FormControlLabel
        control={
          <Checkbox
            checked={useNearby}
            onChange={handleUseNearbyChange}
            name='Nearby Places Only'
            color='primary'
          />
        }
        label='Nearby Places Only'
        sx={styles.radiusLabel}
      />
      <NumberEdit
        sx={styles.radius}
        label='Radius (mi)'
        value={radius}
        onChange={handleRadiusChange}
        disabled={!useNearby}
      />
    </Box>;

  return (
    <CardGrid
      card={<PlaceCard/>}
      useListGraphQL={useListPlacesGraphQL}
      useListGraphQLProps={graphQLProps}
      headerComponent={header}
      name='Place'
      pluralName='Places'
      onAddClick={handleNewPlace}
      onEdit={onEdit}
      onSelect={onSelect}
      {...cardGridProps}
    />
  );
};

export default PlacesCardGrid;
