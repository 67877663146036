import React, { FunctionComponent } from 'react';
import { Box, Drawer } from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';

import SideBarNav from './SideBarNav';


interface Props {
  sx?: SxProps,
  onClose?: () => void,
  open: boolean,
  variant: 'permanent' | 'persistent' | 'temporary',
}

const SideBar: FunctionComponent<Props> = props => {
  const { open, variant, onClose, sx, ...rest } = props;

  const theme = useTheme();
  const styles = {
    root: {
      backgroundColor: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: theme.spacing(2)
    },
    divider: {
      margin: theme.spacing(2, 0)
    },
    drawer: {
      width: 240,
      [theme.breakpoints.up('lg')]: {
        marginTop: 64,
        height: 'calc(100% - 64px)'
      }
    },
    nav: {
      marginBottom: theme.spacing(2)
    }
  };

  return (
    <Drawer
      anchor='left'
      sx={{ paper: styles.drawer }}
      onClose={() => onClose && onClose()}
      open={open}
      variant={variant === null ? 'permanent' : variant}
    >
      <Box
        {...rest}
        sx={[styles.root, ...(Array.isArray(sx) ? sx : [sx]),]}
      >
        <SideBarNav
          isCollapsed={false}
          onSelect={onClose}
        />
      </Box>
    </Drawer>
  );
};

export default SideBar;