import React, { Fragment, FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import { Typography } from '@mui/material';

import { GridCardProps } from '../common/CardGrid';
import GridCard from '../common/GridCard';
import { Place } from '../../models';


type Props = GridCardProps<Place> & {
};


const PlaceCard: FunctionComponent<Props> = props => {

  const { item } = props;
  const navigate = useNavigate();

  //console.log('PlaceCard brewery', item);

  if (item == null) {
    return null;
  }

  const handleClick = () => {
    navigate(`/place/${item.placeId}`);
  };

  const logoUrl = item?.logoUrls && item.logoUrls['400px'];

  const CardContent = (
    <Fragment>
      <Typography gutterBottom variant='h5'>
        {item.name}
      </Typography>
      <Typography variant='body2' color='textSecondary'>
        {item.cityState}
      </Typography>
      {item.distance &&
        <Typography variant='body2' color='textSecondary'>
          {`${item.distance.toFixed(1)} mi away`}
        </Typography>
      }
    </Fragment>
  );

  return (
    <GridCard
      title={item.name}
      logoUrl={logoUrl}
      cardContent={CardContent}
      onClick={handleClick}
    />
  );

};

export default PlaceCard;
