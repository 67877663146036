import React, { Fragment, FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import { Typography } from '@mui/material';

import { GridCardProps } from '../common/CardGrid';
import GridCard from '../common/GridCard';
import { Beverage } from '../../models';


type Props = GridCardProps<Beverage> & {
}


const BeverageCard: FunctionComponent<Props> = props => {

  const {item, name='Beverage'} = props;
  const navigate = useNavigate();

  //console.log('BeverageCard', item);
  if (item == null) {
    return null;
  }

  const handleClick = () => {
    navigate(`/${name.toLowerCase()}/${item.beverageId}`);
  };

  const logoUrl = item?.logoUrls && item.logoUrls['400px'];

  const CardContent = (
    <Fragment>
      <Typography gutterBottom variant='h5'>
        {item.name}
      </Typography>
      <Typography variant='body2' color='textSecondary'>
        {item.style}
      </Typography>
      <Typography variant='body2' color='textSecondary'>
        {item.brewery?.name}
      </Typography>
    </Fragment>
  );

  return (
    <GridCard
      title={item.name}
      logoUrl={logoUrl}
      cardContent={CardContent}
      onClick={handleClick}
    />
  );
};

export default BeverageCard;
