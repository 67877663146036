import React, { useContext } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { AuthContext } from '../../contexts';
import { PlaceBeverage } from '../../models';
import PlaceBeverageView from './PlaceBeverageView';


interface Props {
  placeBeverage?: PlaceBeverage | null,
  onClose?: (value: string) => void,
  onEdit?: () => void,
  open?: boolean,
}


const PlaceBeverageViewDialog = (props: Props) => {

  const { placeBeverage, onClose, onEdit, open=false } = props;

  const handleClose = () => {
    onClose && onClose('Closed');
  };

  const {canContribute} = useContext(AuthContext);

  return (
    <Box>
      <Dialog
        maxWidth='xl'
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby='placebeverage-view-dialog-title'
      >
        <DialogTitle id='placebeverage-view-dialog-title'>
          Available Beverage
        </DialogTitle>
        <DialogContent>
          <PlaceBeverageView
            placeBeverage={placeBeverage}
            ignoreParamId
          />
        </DialogContent>
        <DialogActions>
          {onEdit && canContribute &&
            <Button onClick={onEdit} color='primary'>
              Edit
            </Button>
          }
          <Button onClick={handleClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PlaceBeverageViewDialog;
