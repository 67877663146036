import {transform, isEqual} from 'lodash';  // isObject

/**
 * Deep diff between two object, using lodash
 * @param  {Object} obj Object compared
 * @param  {Object} base   Object to compare with
 * @param  {string[]} exclude   List of property names to exclude
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(
	obj: {[key:string]: any},
	base: {[key:string]: any},
	exclude: string[] = []
) {
	return transform(obj, (result: {[key:string]: any}, value: any, key: string) => {
		if (!isEqual(value, base[key]) || exclude.includes(key)) {
			// Following line allows deep comparison through recursion
			//result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value;
			result[key] = value;
		}
	});
}