import React, { useState } from 'react';
import { PlaceCore } from '../../models';
import ItemEditDialog from '../common/ItemEditDialog';
import PlaceBeverageAdd from './PlaceBeverageAdd';


type DialogProps = {
  place: PlaceCore,
  beverageTypeName?: string,
  onClose: (value: string|null) => void,
  open: boolean,
}

export const PlaceBeverageAddDialog = (props: DialogProps) => {

  const {
    place, beverageTypeName,
    onClose, open,
  } = props;

  const [disableSave, setDisableSave] = useState<boolean>(false);

  const displayBeverageTypeName = beverageTypeName || 'Beverage';

  return (
    <ItemEditDialog
      onClose={onClose}
      open={open}
      title={`Add ${displayBeverageTypeName} to ${place.name}`}
      hideDelete
      disableSave={disableSave}
      maxWidth='xl'
      fullWidth={true}
    >
      <PlaceBeverageAdd
        place={place}
        setDisableSave={setDisableSave}
      />
    </ItemEditDialog>
  );
};

export default PlaceBeverageAddDialog;
