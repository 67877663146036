import {Item} from './Item';
import {OfferedInDetails} from '../graphql/types/OfferedInDetails';
import {OfferedInInput} from '../graphql/types/globalTypes';


export class OfferedIn extends Item {

  source?: string;
  volume?: number;
  unit?: string;
  quantity?: number;
  price?: number;
  location?: string;
  servedIn?: string;
  options?: string[];

  get volumeString(): string {
    let volumeStr = '';
    if (this.volume) {
      if (this.quantity) {
        volumeStr = this.quantity.toString() + ' ';
      }
      volumeStr += this.volume.toString();
      if (this.unit) {
        volumeStr += ' ' + this.unit;
      }
    }
    return volumeStr;
  }

  setFromGraphQL(data: Partial<OfferedInDetails>|null) {
    super.setFromGraphQL(data);
    if (data != null) {
      this.source = data.source == null ? undefined : data.source;
      this.volume = data.volume == null ? undefined : data.volume;
      this.unit = data.unit == null ? undefined : data.unit;
      this.quantity = data.quantity == null ? undefined : data.quantity;
      this.price = data.price == null ? undefined : data.price;
      this.location = data.location == null ? undefined : data.location;
      this.servedIn = data.servedIn == null ? undefined : data.servedIn;
      this.options = data.options == null ? undefined : data.options.filter(o => o != null) as string[];
    }
  }

  toInput(allProps=false): OfferedInInput {
    return {
      source: this.source,
      volume: this.volume,
      unit: this.unit,
      quantity: this.quantity,
      price: this.price,
      location: this.location,
      servedIn: this.servedIn,
      options: this.options,
    };
  }
}
