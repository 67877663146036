import {Item} from './Item';
import {GetPicklist_picklist} from '../graphql/types/GetPicklist';
import {PicklistInput} from '../graphql/types/globalTypes';


export class Picklist extends Item {
  strings?: string[];
  maps?: object[];

  setFromGraphQL(data: Partial<GetPicklist_picklist>|null) {
    super.setFromGraphQL(data);
    if (data != null) {
      this.strings = data.strings == null ? [] : data.strings.filter(v => v != null) as string[];
      this.maps = data.maps == null ? [] : data.maps.filter(v => v != null) as object[];
    }
  }

  toInput(): PicklistInput {
    return {
      id: this.id,
      name: this.name || '',
      strings: this.strings,
      maps: this.maps?.map(m => JSON.stringify(m)),
    };
  }

  validate() {
    if (!super.validate()) {
      return false;
    }
    if (this.name == null || this.name === '') {
      return false;
    }
    return true;
  }
}
