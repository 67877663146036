  /*
   * As defined in AutoId::newId()
   *  https://github.com/firebase/firebase-js-sdk/blob/master/packages/firestore/src/util/misc.ts
   */
export function createUniqueId() {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let autoId = '';
  for (let i = 0; i < 20; i++) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  if (autoId.length !== 20) {
    throw Error('Invalid auto ID: ' + autoId);
  }
  return autoId;
}
