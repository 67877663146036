import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ProducerSelect } from '../Producer';
import ImagePicker from '../common/ImagePicker';
import ItemEdit, { FieldRenderer, ItemEditProps } from '../common/ItemEdit';
import LinkListEdit from '../common/LinkListEdit';
import MapEdit from '../common/MapEdit';
import SingleSelect from '../common/SingleSelect';
import TextEdit from '../common/TextEdit';
import useListBeveragesGraphQL from '../../graphql/hooks/useListBeveragesGraphQL';
import useListBeverageTypesGraphQL from '../../graphql/hooks/useListBeverageTypesGraphQL';
import useUpdateBeverageGraphQL from '../../graphql/hooks/useUpdateBeverageGraphQL';
import { uploadLogo } from '../../helpers/imageUpload';
import useDuplicateDialog from '../../helpers/useDuplicateDialog';
import { FormErrors, InputEvent, SelectEvent } from '../../helpers/useFormValidation';
import { Beverage, ProducerCore } from '../../models';


function validateBeverage(values: Beverage) {
  let errors: FormErrors<Beverage> = {};

  // Name Errors
  if (!values.name) {
    errors.name = 'Name required';
  } else if (values.name.length < 2) {
    errors.name = 'Name must be at least 2 characters';
  }

  // Producer Errors
  if (values.producer?.id == null) {
    errors.producer = 'Producer required';
  }
  return errors;
}

const INITIAL_VALUE = new Beverage();

const createItemDescription = (item: Beverage) => {
  if (item != null) {
    return `${item.name} from ${item.producer?.name}`;
  }
  return '';
};


type Props = ItemEditProps<Beverage> & {
  beverage?: Beverage | null,
  producer?: ProducerCore | null,
  model?: typeof Beverage,
  typeName?: string,
  producerTypeName?: string,
  additionalFieldRenderer?: FieldRenderer<Beverage>,
}

const BeverageEdit = (props: Props) => {

  const {
    beverage, producer,
    model=Beverage, typeName='Beverage', producerTypeName='Producer',
    additionalFieldRenderer,
    ...itemEditProps
  } = props;

  //console.log('BeverageEdit', beverage);

  const theme = useTheme();
  const navigate = useNavigate();

  const [_beverage, setBeverage] = useState<Beverage|null>(null);

  const {
    duplicatesDialog,
    setDuplicateSearchString
  } = useDuplicateDialog({
    useListGraphQL: useListBeveragesGraphQL,
    useListGraphQLProps: {typeName: typeName},
    message: `This new beverage might be a duplicate of one of the following beverages.
             Please confirm that this new entry is not a duplicate`,
    createItemDescription: createItemDescription,
    onDuplicate: () => {navigate(-1);}
});

  useEffect(() => {
    if (beverage == null && producer != null) {
      setBeverage(() => {
        const newBeverage = INITIAL_VALUE;
        if (newBeverage != null) {
          newBeverage.producer = producer;
        }
        return newBeverage;
      });
    } else if (beverage != null && producer != null) {
      setBeverage((oldBeverage) => {
        const newBeverage = oldBeverage?.clone();
        if (newBeverage != null) {
          newBeverage.producer = producer;
          return newBeverage;
        }
        return null;
      });
    } else {
      setBeverage(beverage||null);
    }
  }, [producer, beverage]);

  const [_logoFile, setLogoFile] = useState<File|null>(null);

  async function preSubmit(item: Beverage) {
    if (_logoFile != null) {
      item.logoUrls = await uploadLogo(_logoFile, 'logos/beverages');
    }
    return item;
  }

  const {items: beverageTypes} = useListBeverageTypesGraphQL({
    categoryName: typeName,
    sortByAttributeName: 'picklistName',
  });

  const renderFields = (
    originalItem: Beverage|null,
    formItem: Beverage|null,
    formErrors: FormErrors<Beverage>,
    handleBlur: ()=> void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => {

    const logoUrl = formItem?.logoUrls && formItem.logoUrls['original'];

    function handleProducerSelect(producer: ProducerCore | null) {
      if (producer == null) {
        updateProperty('producer', undefined);
      } else {
        updateProperty('producer', producer);
      }
    }

    const handleNameBlur = () => {
      if (formItem?.id == null && formItem?.name && formItem.name.length >= 4) {
        setDuplicateSearchString(formItem.name);
      }
    }

    return (
      <Box sx={theme.itemEdit.form}>
        <Typography
          sx={theme.itemEdit.title}
          variant='h2'
        >
          Editing {originalItem?.name || `New ${typeName}`}
        </Typography>
        <ProducerSelect
          producer={formItem?.producer}
          typeName={producerTypeName}
          onChange={handleProducerSelect}
        />
        <TextEdit
          error={formErrors.name}
          label='Name'
          name='name'
          onBlur={handleNameBlur}
          onChange={handleChange}
          value={formItem?.name}
        />
        <div>
          <TextEdit
            error={formErrors.style}
            label='Style'
            name='style'
            tooltip="Producer's descriptive style"
            onBlur={handleNameBlur}
            onChange={handleChange}
            value={formItem?.style}
          />
        </div>
        <div>
          <SingleSelect
            label='Type'
            name='type'
            tooltip='Specific style type for categorization'
            items={beverageTypes}
            onChange={(val) => updateProperty('beverageType', val)}
            value={formItem?.beverageType}
          />
        </div>
        <TextEdit
          error={formErrors.description}
          label='Description'
          name='description'
          multiline
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.description}
        />
        <ImagePicker
          label='Logo'
          imageURL={logoUrl}
          folder='logos/beverages'
          onChange={setLogoFile}
        />
        <TextEdit
          error={formErrors.website}
          label='Website'
          name='website'
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.website}
        />
        <div>
          <LinkListEdit
            value={formItem?.links}
            onChange={(val) => updateProperty('links', val)}
          />
        </div>
        {formItem?.properties?.length &&
          <MapEdit
            label='Additional Properties'
            value={formItem?.properties}
          />
        }
        {additionalFieldRenderer && additionalFieldRenderer(
          originalItem,
          formItem,
          formErrors,
          handleBlur,
          handleChange,
          updateProperty,
        )}
      </Box>
    );
  };

  return (
    <div>
      <ItemEdit
        item={_beverage}
        defaultValue={INITIAL_VALUE}
        useUpdateGraphQL={useUpdateBeverageGraphQL}
        useUpdateGraphQLProps={{model: model, typeName: typeName}}
        fieldRenderer={renderFields}
        validator={validateBeverage}
        preSubmit={preSubmit}
        {...itemEditProps}
      />
      {duplicatesDialog}
    </div>
  )
};

export default BeverageEdit;
