import React, { Fragment, FunctionComponent, ReactNode } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ItemView from '../common/ItemView';
import LabeledLinks from '../common/LabeledLinks';
import LabeledMap from '../common/LabeledMap';
import LabeledText from '../common/LabeledText';
import useGetIngredientGraphQL from '../../graphql/hooks/useGetIngredientGraphQL';
import { Ingredient } from '../../models';


interface Props {
  ingredient?: Ingredient | null,
  id?: string|null,
  model?: typeof Ingredient,
  additionalFieldRenderer?: (item: Ingredient|null) => ReactNode,
}

const IngredientView: FunctionComponent<Props> = props => {

  const { ingredient, id, model=Ingredient, additionalFieldRenderer } = props;
  //console.log('IngredientView', ingredient);

  const theme = useTheme();

  const renderView = (item: Ingredient|null) => {
    return (
      <Fragment>
        <Typography sx={theme.itemView.title} variant='h2'>
          {item?.name}
        </Typography>
        <Typography sx={theme.itemView.body} variant='body1'>
          {item?.description}
        </Typography>
        <LabeledText
          label='Picklist Name'
          text={item?.picklistName}
        />
        <LabeledText
          label='Flavor'
          text={item?.flavor}
        />
        <LabeledText
          label='Aliases'
          text={item?.aliases && item.aliases.join(', ')}
        />
        {item?.creator &&
          <LabeledText
            label='Creator'
            text={item?.creator}
          />
        }
        <LabeledLinks
          label='Links'
          links={item?.links}
        />
        {additionalFieldRenderer && additionalFieldRenderer(item)}
        {item?.properties && item.properties.length > 0 &&
          <LabeledMap
            label='Properties'
            map={item?.properties}
          />
        }
        <LabeledText
          label='Updated'
          text={item?.getTimestampString()}
        />

      </Fragment>
    );
  };

  return (
    <ItemView
      item={ingredient}
      id={id}
      useGetGraphQL={useGetIngredientGraphQL}
      useGetGraphQLProps={{model: model}}
      viewRenderer={renderView}
    />
  )
};

export default IngredientView;
