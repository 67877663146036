import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ImagePicker from '../common/ImagePicker';
import ItemEdit, { FieldRenderer, ItemEditProps } from '../common/ItemEdit';
import LinkListEdit from '../common/LinkListEdit';
import LocationsEdit from '../common/LocationsEdit';
import MapEdit from '../common/MapEdit';
import TextEdit from '../common/TextEdit';
import useListProducersGraphQL from '../../graphql/hooks/useListProducersGraphQL';
import useListProducerTypesGraphQL from '../../graphql/hooks/useListProducerTypesGraphQL';
import useUpdateProducerGraphQL from '../../graphql/hooks/useUpdateProducerGraphQL';
import { uploadLogo } from '../../helpers/imageUpload';
import useDuplicateDialog from '../../helpers/useDuplicateDialog';
import { FormErrors, InputEvent, SelectEvent } from '../../helpers/useFormValidation';
import { Location, Producer } from '../../models';
import MultiSelect from '../common/MultiSelect';


function validateProducer(values: Producer) {
  let errors: FormErrors<Producer> = {};

  // Name Errors
  if (!values.name) {
    errors.name = 'Name required';
  } else if (values.name.length < 2) {
    errors.name = 'Name must be at least 2 characters';
  }

  // Producer Types Errors
  if (!values.producerTypes) {
    errors.producerTypes = 'At least one producer type required';
  }

  return errors;
}

const INITIAL_VALUE = new Producer();

const createItemDescription = (item: Producer) => {
  if (item != null) {
    return `${item.name}`;
  }
  return '';
};


type Props = ItemEditProps<Producer> & {
  producer?: Producer|null,
  model?: typeof Producer,
  typeName?: string,
  additionalFieldRenderer?: FieldRenderer<Producer>,
}

const ProducerEdit = (props: Props) => {

  const {
    producer, model=Producer, typeName,
    additionalFieldRenderer,
    ...itemEditProps
  } = props;

  //console.log('ProducerEdit', producer);

  const theme = useTheme();
  const navigate = useNavigate();

  const {
    duplicatesDialog,
    setDuplicateSearchString
  } = useDuplicateDialog({
    useListGraphQL: useListProducersGraphQL,
    useListGraphQLProps: {typeName: typeName},
    message: `This new producer might be a duplicate of one of the following producers.
             Please confirm that this new entry is not a duplicate`,
    createItemDescription: createItemDescription,
    onDuplicate: ()=>{navigate(-1);},
  });

  const [_logoFile, setLogoFile] = useState<File|null>(null);

  async function preSubmit(item: Producer) {
    if (_logoFile != null) {
      item.logoUrls = await uploadLogo(_logoFile, 'logos/producers');
    }
    return item;
  }

  const {items: producerTypes} = useListProducerTypesGraphQL();

  const renderFields = (
    originalItem: Producer|null,
    formItem: Producer|null,
    formErrors: FormErrors<Producer>,
    handleBlur: () => void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => {

    const logoUrl = formItem?.logoUrls && formItem.logoUrls['original'];

    function handleLocationsChange(locations: Location[]) {
      updateProperty && updateProperty('locations', locations);
    }

    const handleNameBlur = () => {
      if (formItem?.id == null && formItem?.name && formItem.name.length >= 4) {
        setDuplicateSearchString(formItem.name);
      }
    }

    return (
      <Box sx={theme.itemEdit.form}>
        <Typography
          sx={theme.itemEdit.title}
          variant='h2'
        >
          Editing {originalItem?.name || `New ${typeName||'Producer'}`}
        </Typography>
        <TextEdit
          error={formErrors.name}
          label='Name'
          name='name'
          onBlur={handleNameBlur}
          onChange={handleChange}
          value={formItem?.name}
        />
        <TextEdit
          error={formErrors.description}
          label='Description'
          name='description'
          multiline
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.description}
        />
        <div>
          <MultiSelect
            label='Producer Types'
            name='producerTypes'
            items={producerTypes}
            values={formItem?.producerTypes}
            onChange={vals => updateProperty('producerTypes', vals)}
          />
        </div>
        <LocationsEdit
          locations={formItem?.locations}
          onChange={handleLocationsChange}
        />
        <ImagePicker
          label='Logo'
          imageURL={logoUrl}
          folder='logos/producers'
          onChange={setLogoFile}
        />
        <TextEdit
          error={formErrors.website}
          label='Website'
          name='website'
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.website}
        />
        <div>
          <LinkListEdit
            value={formItem?.links}
            onChange={(val) => updateProperty('links', val)}
          />
        </div>
        {formItem?.properties?.length &&
          <MapEdit
            label='Additional Properties'
            value={formItem?.properties}
          />
        }
        {additionalFieldRenderer && additionalFieldRenderer(
          originalItem,
          formItem,
          formErrors,
          handleBlur,
          handleChange,
          updateProperty,
        )}
      </Box>
    );
  };

  return (
    <div>
      <ItemEdit
        item={producer}
        itemTypeName={typeName||'Producer'}
        defaultValue={INITIAL_VALUE}
        useUpdateGraphQL={useUpdateProducerGraphQL}
        useUpdateGraphQLProps={{model: model, typeName: typeName}}
        fieldRenderer={renderFields}
        validator={validateProducer}
        preSubmit={preSubmit}
        {...itemEditProps}
      />
      {duplicatesDialog}
    </div>
  )
};

export default ProducerEdit;
