import React, {ReactNode} from 'react';

interface Props {
  children: ReactNode;
  layout: React.ComponentType<any>;
}

const RouteWithLayout = (props: Props) => {
  const {layout: Layout, children} = props;

  return (
    <Layout>
      {children}
    </Layout>
  );
};

export default RouteWithLayout;