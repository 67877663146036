import {useEffect, useState} from 'react';

import {useQuery} from '@apollo/client';
import getQuery from '../queries/getPicklistQuery.graphql';
import {GetPicklist, GetPicklistVariables} from '../types/GetPicklist';

import {Picklist} from '../../models';

const Item = Picklist;
type GetItem = GetPicklist;
type GetVariables = GetPicklistVariables;
type Item = Picklist;


type Props = {
  name?: string,
}

const useGetPicklistGraphQL = (props?: Props) => {

  const {name} = {...props};

  const [item, setItem] = useState<Item|null>(null);
  const [strings, setStrings] = useState<string[]>([]);
  const [maps, setMaps] = useState<object[]>([]);

  const {loading, error} = useQuery<GetItem, GetVariables>(getQuery, {
    variables: {name: name||''},
    //fetchPolicy: 'cache-and-network',
    skip: name == null,
    onCompleted: data => {
      if (data.picklist != null) {
        const newItem = new Item();
        newItem.setFromGraphQL(data.picklist);
        setItem(newItem);
      }
    },
  });

  useEffect(() => {
    setStrings(item?.strings || []);
    setMaps(item?.maps || []);
  }, [item]);

  return {
    item,
    strings,
    maps,
    gettingItem: loading,
    getError: error?.message || null,
  };
}

export default useGetPicklistGraphQL;
