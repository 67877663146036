import {useState} from 'react';

import {useQuery} from '@apollo/client';
import getProducerQuery from '../queries/getProducerQuery.graphql';
import {GetProducer, GetProducerVariables} from '../types/GetProducer';

import {Producer} from '../../models';

const Item = Producer;
const getQuery = getProducerQuery;
type GetItem = GetProducer;
type GetVariables = GetProducerVariables;
type Item = Producer;

type Props = {
  currentId?: string|null,
  name?: string,
  model?: { new (): Item; },
}

const useGetProducerGraphQL = (props?: Props) => {

  const {currentId, name, model=Item} = {...props};

  const [item, setItem] = useState<Item|null>(null);

  const {loading, error} = useQuery<GetItem, GetVariables>(getQuery, {
    variables: {producer: {producerId: currentId, name: name}},
    fetchPolicy: 'cache-and-network',
    skip: currentId == null && name == null,
    onCompleted: data => {
      if (data.producer != null) {
        const newItem = new model();
        newItem.setFromGraphQL(data.producer);
        setItem(newItem);
      }
    },
  });

  return {
    item,
    gettingItem: loading,
    getError: error?.message || null,
  };
}

export default useGetProducerGraphQL;
