import React from 'react';

import {ProducersCardGrid} from '../components/Producer';


export default function BreweriesView() {

  return (
    <div>
      <ProducersCardGrid/>
    </div>
  );
}
