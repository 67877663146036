import React, { useEffect, useState } from 'react';
import { Box, Card, Paper, Tab, Tabs } from '@mui/material';

import { BeverageTypesList } from '../components/BeverageType';
import { BrewersAssociationStylesList } from '../components/BeerType';


const styles = {
  root: {
    flexGrow: 1,
  },
  card: {
    margin: '10px',
  },
  panel: {
    flexGrow: 1,
    margin: '10px',
  },
};


export default function BeverageTypesView() {

  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    console.log('BeverageTypesView useEffect');
  });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function BeerTypesPanel() {
    return (
      <Box sx={styles.panel}>
        <Card sx={styles.card}>
          <BeverageTypesList
            categoryName='Beer'
            title='Beer Types'
          />
        </Card>
      </Box>
    );
  }

  function BAStylesPanel() {
    return (
      <Box sx={styles.panel}>
        <Card sx={styles.card}>
          <BrewersAssociationStylesList />
        </Card>
      </Box>
    );
  }

  function CiderTypesPanel() {
    return (
      <Box sx={styles.panel}>
        <Card sx={styles.card}>
          <BeverageTypesList
            categoryName='Cider'
            title='Cider Types'
          />
        </Card>
      </Box>
    );
  }

  function SeltzerTypesPanel() {
    return (
      <Box sx={styles.panel}>
        <Card sx={styles.card}>
          <BeverageTypesList
            categoryName='Seltzer'
            title='Seltzer Types'
          />
        </Card>
      </Box>
    );
  }

  function SpiritTypesPanel() {
    return (
      <Box sx={styles.panel}>
        <Card sx={styles.card}>
          <BeverageTypesList
            categoryName='Spirit'
            title='Spirit Types'
          />
        </Card>
      </Box>
    );
  }

  function WineTypesPanel() {
    return (
      <Box sx={styles.panel}>
        <Card sx={styles.card}>
          <BeverageTypesList
            categoryName='Wine'
            title='Wine Types'
          />
        </Card>
      </Box>
    );
  }


  return (
    <Box>
      <Paper sx={styles.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          centered
        >
          <Tab label='Beer Types' />
          <Tab label='BA Styles' />
          <Tab label='Cider Types' />
          <Tab label='Seltzer Types' />
          <Tab label='Spirit Types' />
          <Tab label='Wine Types' />
        </Tabs>
      </Paper>
      {value === 0 && BeerTypesPanel()}
      {value === 1 && BAStylesPanel()}
      {value === 2 && CiderTypesPanel()}
      {value === 3 && SeltzerTypesPanel()}
      {value === 4 && SpiritTypesPanel()}
      {value === 5 && WineTypesPanel()}
    </Box>
  );
}
