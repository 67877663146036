import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Location } from '../../models';


interface Props {
  location?: Location,
  label?: string;
  labeled?: boolean,
}

const LocationView = (props: Props) => {

  const { location, label='Location', labeled=false } = props;

  const theme = useTheme();

  if (location == null) {
    return null;
  }

  const createLocationBlock = () => (
    <Box>
      {location.street &&
        <Typography sx={theme.itemView.text} variant='body1'>
          {location.street}
        </Typography>
      }
      {location.street2 &&
        <Typography sx={theme.itemView.text} variant='body1'>
          {location.country}
        </Typography>
      }
      {location.cityStateZip &&
        <Typography sx={theme.itemView.text} variant='body1'>
          {location.cityStateZip}
        </Typography>
      }
      {location.country &&
        <Typography sx={theme.itemView.text} variant='body1'>
          {location.country}
        </Typography>
      }
      {location.longitude && location.latitude &&
        <Typography sx={theme.itemView.text} variant='body1'>
          {`${location.latitude}, ${location.longitude}`}
        </Typography>
      }
    </Box>
  );

  if (labeled) {
    return (
      <Box>
        <Box sx={theme.itemView.textBlock}>
          <Typography sx={theme.itemView.label} variant='h6'>
            {label}
          </Typography>
          {createLocationBlock()}
        </Box>
      </Box>
    );
  } else {
    return createLocationBlock();
  }
};

export default LocationView