import {Item, ItemInput} from './Item';
import {User, UserInput} from './User';

export type VersionedItemInput = ItemInput & {
  version?: number|null;
  insertedAt?: Date;
  insertedBy?: Partial<UserInput>|null;
}

export class VersionedItem extends Item {

  version?: number;
  insertedAt?: Date;
  insertedBy?: User;

  get currentId() {
    return this.id;
  }

  setFromGraphQL(data: VersionedItemInput|null) {
    super.setFromGraphQL(data);
    if (data != null) {
      this.version = data.version == null ? undefined : data.version;
      this.insertedAt = data.insertedAt == null ? undefined : new Date(data.insertedAt);
      this.insertedBy = data.insertedBy == null ? undefined : User.fromGraphQL(data.insertedBy);
    }
  }

  getTimestampString() {
    let timestamp = '';
    if (this.insertedBy?.name != null) {
      timestamp += this.insertedBy.name;
    }
    if (this.insertedAt != null) {
      timestamp += ' on ' +
                   this.insertedAt.toLocaleDateString() +
                   ' at ' +
                   this.insertedAt.toLocaleTimeString();
    }
    return timestamp;
  }
}
