import React, { Fragment, FunctionComponent } from 'react';

import LabeledText from '../common/LabeledText';
import { Hop } from '../../models';
import { IngredientView } from '../Ingredient';


type Props = {
  hop?: Hop | null,
  id?: string|null,
}

const HopView: FunctionComponent<Props> = props => {

  const {hop, id} = props;

  const additionalFields = (item: Hop|null) => {
    return (
      <Fragment>
        <LabeledText
          label='Alpha:'
          text={`${item?.alphaMin || '?'} to ${item?.alphaMax || '?'}%`}
        />
      </Fragment>
    );
  };

  return (
    <IngredientView
      ingredient={hop}
      id={id}
      model={Hop}
      additionalFieldRenderer={additionalFields}
    />
  )
};

export default HopView;
