import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  InputAdornment,
  List, ListItemButton, ListItemText,
  TextField,
  Typography
} from '@mui/material';
import { Search } from '@mui/icons-material';

import { BUTTON_VARIANT } from '../common/ItemView';
import useListProducersGraphQL from '../../graphql/hooks/useListProducersGraphQL';
import { ProducerCore } from '../../models';


const styles = {
  root: {
    //marginLeft: 10,
  },
  selectRow: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'space-between',
    flex: 1,
    marginLeft: 10,
  },
  text: {
  },
  button: {
    alignSelf: 'center',
  }
};


interface SelectProps {
  producer?: ProducerCore|null,
  onSelect?: (item: ProducerCore|null) => void,
  typeName?: string,
}

const ProducerSelectList = (props: SelectProps) => {
  //console.log('Refreshing ProducerSelect');

  const { producer, onSelect, typeName='Producer' } = props;

  const [searchString, setSearchString] = useState('');

  const {items: graphQLItems} = useListProducersGraphQL({
    typeName: typeName,
    filterString: searchString,
  });

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    event.persist();
    setSearchString(event.target.value);
  }

  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    if (producer != null && graphQLItems != null) {
      const index = graphQLItems.findIndex(item => item.id === producer.id);
      if (index != null && index > 0) {
        setSelectedIndex(index);
      }
    }
  }, [producer, graphQLItems]);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    onSelect && onSelect(graphQLItems![index].toCore());
  };

  return (
    <Fragment>
      <TextField
        helperText={`Filter ${typeName}s`}
        value={searchString}
        onChange={handleSearchChange}
        type='text'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Search />
            </InputAdornment>
          )
        }}
      />
      <List component='nav'>
        {graphQLItems?.map((producer, i) =>
          <ListItemButton
            key={i}
            selected={selectedIndex === i}
            onClick={event => handleListItemClick(event, i)}
          >
            <ListItemText
              primary={producer.name}
              secondary={producer.locations?.map(l => l.cityState).join(', ')}
            />
          </ListItemButton>
        )}
      </List>
    </Fragment>
  );
};


interface DialogProps {
  producer: ProducerCore|null,
  onClose: (value: ProducerCore|null) => void,
  open: boolean,
  typeName?: string,
}

const ProducerSelectDialog = (props: DialogProps) => {

  const { producer, onClose, open, typeName='Producer' } = props;

  const [_producer, setProducer] = useState<ProducerCore|null>(producer);

  const handleClose = () => {
    onClose(_producer);
  };

  const handleSelect = (producer: ProducerCore|null) => {
    setProducer(producer);
    onClose(producer);
  };

  return (
    <div>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='producer-select-dialog-title'
      >
        <DialogTitle id='producer-select-dialog-title'>
          {`Select ${typeName}`}
        </DialogTitle>
        <DialogContent>
          <ProducerSelectList
              producer={_producer}
              onSelect={handleSelect}
              typeName={typeName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button
              onClick={handleClose}
              color='primary'
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};


interface Props {
  producer?: ProducerCore|null,
  onChange?: (item: ProducerCore|null) => void,
  typeName?: string,
}

const ProducerSelect = (props: Props) => {
  //console.log('Refreshing ProducerSelect');

  const {producer, onChange, typeName='Producer'} = props;

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);

  const handleCloseDialog = (value: ProducerCore|null) => {
    setOpenAddDialog(false);
    onChange && onChange(value);
  };

  return (
    <Box sx={styles.root}>
      <Typography sx={styles.text}>
        {`${typeName}:`}
      </Typography>
      <Box sx={styles.selectRow}>
        <Typography sx={styles.text}>
          {producer?.name || `No ${typeName} Selected`}
        </Typography>
        <Button
            sx={styles.button}
            color='primary'
            variant={BUTTON_VARIANT}
            onClick={() => setOpenAddDialog(true)}
        >
          {`Select A Different ${typeName}`}
        </Button>
      </Box>
      <ProducerSelectDialog
          producer={producer || null}
          onClose={handleCloseDialog}
          open={openAddDialog}
          typeName={typeName}
      />
    </Box>
  );
};

export default ProducerSelect;