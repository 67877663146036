import React, { useEffect, useState } from 'react';
import {
  Box, Step, StepButton, Stepper,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ItemEdit, { ItemEditProps } from '../common/ItemEdit';
import { FormErrors, InputEvent, SelectEvent } from '../../helpers/useFormValidation';
import {
  BeverageCore,
  OfferedInMap,
  PlaceBeverage,
  PlaceCore
} from '../../models';
import OfferedInTable from './OfferedInTable';
import useUpdatePlaceBeverageGraphQL from '../../graphql/hooks/useUpdatePlaceBeverageGraphQL';
import BeverageSelect from '../Beverage/BeverageSelect';


function getSteps() {
  return [
    'Select a beverage',
    'How Is It Offered?'
  ];
}

function validatePlaceBeverage(values: PlaceBeverage) {
  let errors: FormErrors<PlaceBeverage> = {};

  // Beverage Errors
  if (!values.beverage) {
    errors.beverage = 'Beverage required';
  }

  // Place Errors
  if (!values.place) {
    errors.place = 'Place required';
  }

  // Beverage Errors
  if (!values.offeredIn || Object.keys(values.offeredIn).length === 0) {
    errors.offeredIn = 'Packaging required';
  }

  return errors;
}

const INITIAL_VALUE = new PlaceBeverage();

type Props = ItemEditProps<PlaceBeverage> & {
  place: PlaceCore,
  beverageTypeName?: string,
  setDisableSave?: (disable: boolean) => void,
}

export const PlaceBeverageEdit = (props: Props) => {

  const {
    place,
    beverageTypeName,
    setDisableSave,
    ...itemEditProps
  } = props;

  //console.log('PlaceBeverageEdit', placeBeverage);

  const theme = useTheme();
  const styles = {
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    beverageList: {
      maxHeight: '200px',
      overflow: 'auto',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    beverageListItem: {
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
    },
    offeredIn: {
      marginTop: '10px',
    }
  };

  const [_placeBeverage, setPlaceBeverage] = useState<PlaceBeverage|null>(null);

  useEffect(() => {
    setDisableSave && setDisableSave(true);
  }, [setDisableSave]);

  useEffect(() => {
    console.log('place changed', place);
    const newPlaceBeverage = new PlaceBeverage();
    newPlaceBeverage.place = place;
    setPlaceBeverage(newPlaceBeverage);
  }, [place]);

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({
    0: false,
    1: false,
  });
  const steps = getSteps();

  const totalSteps = () => {
    return steps.length;
  };

  /*
  const completedSteps = () => {
    let numCompleted = 0;
    Object.entries(completed).forEach((step , isComplete) => {
      if (isComplete) {
        numCompleted += 1;
      }
    });
    return numCompleted;
  };
  */

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  /*
  const allStepsCompleted = () => {
    console.log('completedSteps', completedSteps());
    console.log('totalSteps', totalSteps());
    return completedSteps() === totalSteps();
  };
  */

  const handleNext = () => {
    if (!isLastStep()) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleCompleteStep = () => {
    setCompleted(old => ({
      ...old,
      [activeStep]: true,
    }));
    handleNext();
  };

  async function preSubmit(item: PlaceBeverage) {
    item.status = 'Available';
    return item;
  }

  const renderFields = (
    originalItem: PlaceBeverage|null,
    formItem: PlaceBeverage|null,
    formErrors: FormErrors<PlaceBeverage>,
    handleBlur: ()=> void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => {

    const handleSelectBeverage = async (beverage: BeverageCore|null) => {
      if (beverage != null) {
        await updateProperty('beverage', beverage);
        handleCompleteStep();
      }

    }

    const handleOfferedInChange = async (offeredIn: OfferedInMap|null) => {
      await updateProperty('offeredIn', offeredIn);
      handleCompleteStep();
    }

    const getStepContent = (step: number) => {
      switch (step) {
        case 0:
          return (
            <Box>
              <Box>
                <BeverageSelect
                  beverage={formItem?.beverage}
                  typeName={beverageTypeName}
                  onChange={val => handleSelectBeverage(val)}
                />
              </Box>
            </Box>
          );
        case 1:
          return (
            <Box sx={styles.offeredIn}>
              <OfferedInTable
                offeredIn={formItem?.offeredIn}
                setDisableSave={setDisableSave}
                onChange={val => handleOfferedInChange(val)}
              />
            </Box>
          );
        default:
          return 'Unknown step';
      }
    }

    return (
      <Box sx={styles.root}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Box>
          {getStepContent(activeStep)}
        </Box>
      </Box>
    );
  };

  return (
    <ItemEdit
      item={_placeBeverage}
      defaultValue={INITIAL_VALUE}
      useUpdateGraphQL={useUpdatePlaceBeverageGraphQL}
      useUpdateGraphQLProps={{place: place}}
      fieldRenderer={renderFields}
      validator={validatePlaceBeverage}
      preSubmit={preSubmit}
      formId='placeBeverageEdit'
      {...itemEditProps}
    />
  )
};

export default PlaceBeverageEdit;
