import React, { Fragment } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ItemEdit, {
  FieldRenderer,
  INPUT_VARIANT,
  ItemEditProps,
} from '../common/ItemEdit';
import LinkListEdit from '../common/LinkListEdit';
import MapEdit from '../common/MapEdit';
import StringListEdit from '../common/StringListEdit';
import TextEdit from '../common/TextEdit';
import useUpdateIngredientGraphQL from '../../graphql/hooks/useUpdateIngredientGraphQL';
import { FormErrors, InputEvent, SelectEvent } from '../../helpers/useFormValidation';
import useListDuplicateDialog from '../../helpers/useListDuplicateDialog';
import { Ingredient, PicklistItem } from '../../models';


function validateIngredient(item: Ingredient) {
  let errors: FormErrors<Ingredient> = {};

  // Name Errors
  if (!item.name) {
    errors.name = 'Name required';
  } else if (item.name.length < 2) {
    errors.name = 'Name must be at least 2 characters';
  }
  // Picklist Name Errors
  if (!item.picklistName) {
    errors.picklistName = 'Picklist Name required';
  } else if (item.picklistName.length < 2) {
    errors.picklistName = 'Picklist Name must be at least 2 characters';
  }

  return errors;
}

const INITIAL_VALUE = new Ingredient();

type Props = ItemEditProps<Ingredient> & {
  ingredient?: Ingredient | null,
  ingredients?: PicklistItem[],
  model?: typeof Ingredient,
  typeName?: string,
  additionalFieldRenderer?: FieldRenderer<Ingredient>,
}

const IngredientEdit = (props: Props) => {

  const {ingredient, ingredients,
    model=Ingredient, typeName='Ingredient', additionalFieldRenderer,
    onComplete, ...itemEditProps
  } = props;
  //console.log('IngredientEdit', ingredient);

  const theme = useTheme();

  const {
    duplicatesDialog,
    setDuplicateSearchString
  } = useListDuplicateDialog(
    ingredients||null,
    `This new ingredient might be a duplicate of one of the following ingredients.
             Please confirm that this new entry is not a duplicate`,
    onComplete
  );

  const renderFields = (
    originalItem: Ingredient|null,
    formItem: Ingredient|null,
    formErrors: FormErrors<Ingredient>,
    handleBlur: ()=> void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => {

    const handleNameBlur = () => {
      if (formItem?.id == null && formItem?.name && formItem.name.length >= 3) {
        setDuplicateSearchString(formItem.name);
      }
    }

    return (
      <Fragment>
        <Typography
          sx={theme.itemEdit.title}
          variant='h2'
        >
          {originalItem?.name || `New ${typeName}`}
        </Typography>
        <TextEdit
          error={formErrors.name}
          label='Name'
          name='name'
          variant={INPUT_VARIANT}
          onBlur={handleNameBlur}
          onChange={handleChange}
          value={formItem?.name}
        />
        <TextEdit
          error={formErrors.picklistName}
          label='Picklist Name'
          name='picklistName'
          variant={INPUT_VARIANT}
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.picklistName}
        />
        <TextEdit
          error={formErrors.description}
          label='Description'
          name='description'
          variant={INPUT_VARIANT}
          multiline
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.description}
        />
        <TextEdit
          error={formErrors.flavor}
          label='Flavor'
          name='flavor'
          variant={INPUT_VARIANT}
          multiline
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.flavor}
        />
        <StringListEdit
          label='Aliases'
          value={formItem?.aliases}
          onChange={(val) => updateProperty('aliases', val)}
        />
        <TextEdit
          error={formErrors.creator}
          label='Creator'
          name='creator'
          variant={INPUT_VARIANT}
          multiline
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.creator}
        />
        <LinkListEdit
          value={formItem?.links}
          onChange={(val) => updateProperty('links', val)}
        />
        {formItem?.properties?.length &&
          <MapEdit
            label='Additional Properties'
            value={formItem?.properties}
          />
        }
        {additionalFieldRenderer && additionalFieldRenderer(
          originalItem,
          formItem,
          formErrors,
          handleBlur,
          handleChange,
          updateProperty,
        )}
      </Fragment>
    );
  };

  return (
    <Box>
      <ItemEdit
        item={ingredient}
        defaultValue={INITIAL_VALUE}
        useUpdateGraphQL={useUpdateIngredientGraphQL}
        useUpdateGraphQLProps={{model: model, typeName: typeName}}
        fieldRenderer={renderFields}
        validator={validateIngredient}
        onCancel={onComplete}
        onComplete={onComplete}
        {...itemEditProps}
      />
      {duplicatesDialog}
    </Box>
  );
};

export default IngredientEdit;
