import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card, CardActionArea, CardMedia, CardContent,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Grid,
  Typography, CardActions
} from '@mui/material';

import { AuthContext } from '../../contexts';

import { Place, PlaceBeverage } from '../../models';
import useListPlaceBeveragesGraphQL, {
  ListPlaceBeveragesProps,
} from '../../graphql/hooks/useListPlaceBeveragesGraphQL';
import useUpdatePlaceBeverageGraphQL
  from '../../graphql/hooks/useUpdatePlaceBeverageGraphQL';


const styles = {
  root: {
    marginLeft: '10px',
  },
  section: {
    marginTop: '10px',
  },
  gridList: {
  },
  gridListTile: {
  },
  card: {
    width: '150px',
  },
  cardMedia: {
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  image: {
    height: '100px',
    maxWidth: '100%',
    objectFit: 'contain',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  actions: {
    paddingBottom: '5px',
    paddingTop: '5px',
  },
  removeButton: {
    color: 'DarkRed',
  }
};


type Props = {
  place?: Place|null,
  onSelect?: (item: PlaceBeverage|null) => void,
}

const PlaceBeveragesCardGrid = (props: Props) => {
  //console.log('Refreshing PlaceBeveragesCardGrid');

  const { place, onSelect } = props;

  const [draft, setDraft] = useState<PlaceBeverage[]>([]);
  const [packaged, setPackaged] = useState<PlaceBeverage[]>([]);

  const [graphQLProps, setGraphQLProps] = useState<ListPlaceBeveragesProps>({
    place: place,
  });
  useEffect(() => {
    setGraphQLProps({place: place});
  }, [place]);

  const [selectedPlaceBeverage, setSelectedPlaceBeverage] = useState<PlaceBeverage|null>(null);
  const [openConfirmHereDialog, setOpenConfirmHereDialog] = useState<boolean>(false);
  const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState<boolean>(false);

  const {items: placeBeverages} = useListPlaceBeveragesGraphQL(graphQLProps);
  const {updateItem, deleteItem} = useUpdatePlaceBeverageGraphQL({
    place: place,
  });
  
  useEffect(() => {
    console.log('PBCG placeBeverages', placeBeverages);
    if (placeBeverages != null) {
      const _draft: {[key: string]: PlaceBeverage} = {};
      const _packaged: {[key: string]: PlaceBeverage} = {};
      Object.entries(placeBeverages).forEach(([placeBeverageId, placeBeverage]) => {
        placeBeverage.id = placeBeverageId;
        if (placeBeverage.offeredIn && Object.keys(placeBeverage.offeredIn).length > 0) {
          Object.entries(placeBeverage.offeredIn)
            .forEach(([offeredInId, offeredIn]) => {
              if (offeredIn.source === 'Draft') {
                if (placeBeverageId in _draft) {
                  //_draft[placeBeverageId].offeredIn = {
                  //  ..._draft[placeBeverageId].offeredIn,
                  //  [offeredInId]: offeredIn,
                  //}
                } else {
                  _draft[placeBeverageId] = placeBeverage.clone();
                  //_draft[placeBeverageId].offeredIn = {[offeredInId]: offeredIn};
                }
              } else {
                if (placeBeverageId in _packaged) {
                  //_packaged[placeBeverageId].offeredIn = {
                  //  ..._packaged[placeBeverageId].offeredIn,
                  //  [offeredInId]: offeredIn
                  //}
                } else {
                  _packaged[placeBeverageId] = placeBeverage.clone();
                  //_packaged[placeBeverageId].offeredIn = {[offeredInId]: offeredIn};
                }
              }
            });
        } else {
          console.log('PlaceBeverage without offeredIn', placeBeverage);
          _packaged[placeBeverageId] = placeBeverage.clone();
        }
      });
      console.log('drafts', _draft);
      console.log('packaged', _packaged);
      const _draftList = Object.values(_draft);
      _draftList.sort((a,b) => (a.beverage?.name || '').localeCompare(b.beverage?.name || ''));
      const _packagedList = Object.values(_packaged);
      _packagedList.sort((a,b) => (a.beverage?.name || '').localeCompare(b.beverage?.name || ''));
      setDraft(_draftList);
      setPackaged(_packagedList);
    }
  }, [placeBeverages]);


  const {currentUser, canContribute} = useContext(AuthContext);

  const handleClick = (placeBeverage: PlaceBeverage) => {
    onSelect && onSelect(placeBeverage);
  };

  const handleHereClick = (placeBeverage: PlaceBeverage) => {
    if (placeBeverage != null) {
      setSelectedPlaceBeverage(placeBeverage);
      setOpenConfirmHereDialog(true);
    }
  };

  const handleConfirmClick = async (placeBeverage?: PlaceBeverage | null) => {
    setOpenConfirmHereDialog(false);
    if (placeBeverage != null) {
      await confirmPlaceBeverage(placeBeverage);
    }
  };

  const confirmPlaceBeverage = async (placeBeverage: PlaceBeverage) => {
    if (placeBeverage?.addedById !== currentUser?.id) {
      const updatedPlaceBeverage = placeBeverage.clone();
      updatedPlaceBeverage.status = 'Confirmed';
      await updateItem(updatedPlaceBeverage);
    }
  };

  const handleRemoveClick = (placeBeverage?: PlaceBeverage | null) => {
    setOpenConfirmHereDialog(false);
    if (placeBeverage != null) {
      setOpenConfirmRemoveDialog(true);
    }
  };

  const removePlaceBeverage = async (placeBeverage: PlaceBeverage) => {
    await deleteItem(placeBeverage);
  };

  const handleCloseConfirmHereDialog = () => {
    setOpenConfirmHereDialog(false);
  }

  const handleCloseConfirmRemoveDialog = async (placeBeverage?: PlaceBeverage | null) => {
    setOpenConfirmRemoveDialog(false);
    if (placeBeverage != null) {
      await removePlaceBeverage(placeBeverage);
    }
  }

  const renderCard = (item: PlaceBeverage) => {
    const logoUrl =  item?.beverage?.logoUrls && item?.beverage?.logoUrls['100px'];
    return (
      <Card sx={styles.card}>
        <CardActionArea
          onClick={() => handleClick(item)}
        >
          {logoUrl != null
            ? <CardMedia
                sx={styles.cardMedia}
                title={item.beverage?.name || ''}
              >
                <Box component='img' sx={styles.image} alt='logo' src={logoUrl}/>
              </CardMedia>
            : <Box sx={styles.image}/>
          }
          <CardContent>
            <Typography gutterBottom variant='h5'>
              {item.beverage?.name}
            </Typography>
            <Typography variant='body2' color='textSecondary'>
              {item.beverage?.style}
            </Typography>
            <Typography variant='body2' color='textSecondary' noWrap>
              {item.beverage?.producer?.name}
            </Typography>
          </CardContent>
        </CardActionArea>
        {canContribute &&
          <CardActions disableSpacing sx={styles.actions}>
              <Button size='small' color='primary'
                      onClick={() => handleHereClick(item)}>
                  Still Here?
              </Button>
          </CardActions>
        }
      </Card>

    );
  }

  return (
    <Box sx={styles.root}>
      {(draft.length >= 0) &&
        <Box sx={styles.section}>
          <Typography variant='h4'>
            On Draft
          </Typography>
          <Grid container sx={styles.gridList} spacing={1}>
            {draft.map(item => {
              return (
                <Grid item
                      key={item.id}
                      sx={styles.gridListTile}
                >
                  {renderCard(item)}
                </Grid>
              )
            })}
          </Grid>
        </Box>
      }
      {(draft.length >= 0) &&
        <Box sx={styles.section}>
          <Typography variant='h4'>
            Packaged
          </Typography>
          <Grid container sx={styles.gridList} spacing={1}>
            {packaged.map(item => {
              return (
                <Grid item
                      key={item.id}
                      sx={styles.gridListTile}
                >
                  {renderCard(item)}
                </Grid>
              )
            })}
          </Grid>
        </Box>
      }
      <ConfirmHereDialog
          placeBeverage={selectedPlaceBeverage}
          open={openConfirmHereDialog}
          onClose={handleCloseConfirmHereDialog}
          onConfirm={handleConfirmClick}
          onRemove={handleRemoveClick}
      />
      <ConfirmRemoveDialog
          placeBeverage={selectedPlaceBeverage}
          open={openConfirmRemoveDialog}
          onClose={handleCloseConfirmRemoveDialog}
      />
    </Box>
  )
};

export default PlaceBeveragesCardGrid;



type HereDialogProps = {
  onClose: () => void,
  onConfirm: (item?: PlaceBeverage | null) => void,
  onRemove: (item?: PlaceBeverage | null) => void,
  open: boolean,
  placeBeverage: PlaceBeverage | null,
}

const ConfirmHereDialog = (props: HereDialogProps) => {

  const {onClose, onConfirm, onRemove, open, placeBeverage} = props;

  const beverageName = placeBeverage?.beverage?.name || 'beverage';
  const placeName = placeBeverage?.place?.name || 'place';

  const handleConfirm = () => {
    if (placeBeverage?.status === 'Reported') {
      onConfirm(placeBeverage);
    } else {
      onClose();
    }
  }
  return (
      <Dialog
        open={open}
        onClose={() => onClose()}
        aria-labelledby='here-dialog-title'
        aria-describedby='here-dialog-description'
      >
        <DialogTitle id='here-dialog-title'>
          Is the Still Here?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='here-dialog-description'>
            {`Is the beverage ${beverageName} still available at ${placeName}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onRemove(placeBeverage)} color='primary'>
            No Longer Here
          </Button>
          <Button onClick={() => handleConfirm()} color='primary'>
            Still Here
          </Button>
          <Button onClick={() => onClose()} color='primary' autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  )
}

type RemoveDialogProps = {
  onClose: (item?: PlaceBeverage | null) => void,
  open: boolean,
  placeBeverage: PlaceBeverage | null,
}

const ConfirmRemoveDialog = (props: RemoveDialogProps) => {

  const {onClose, open, placeBeverage} = props;

  const beverageName = placeBeverage?.beverage?.name || 'beverage';
  const placeName = placeBeverage?.place?.name || 'place';

  return (
      <Dialog
        open={open}
        onClose={() => onClose()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Remove Beverage?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {`Are you sure you want to remove ${beverageName} from ${placeName}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color='primary'>
            Do Not Remove
          </Button>
          <Button onClick={() => onClose(placeBeverage)} color='primary' autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
  )
}