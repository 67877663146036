import {PureQueryOptions, useMutation} from '@apollo/client';
import getIngredientQuery from '../queries/getIngredientQuery.graphql';
import listIngredientsQuery from '../queries/listIngredientsQuery.graphql';
import updateIngredientMutation from '../queries/updateIngredientMutation.graphql';
//import IngredientDetailsFragment from '../fragments/IngredientFragment.graphql';
import {UpdateIngredient, UpdateIngredientVariables} from '../types/UpdateIngredient';
import deleteIngredientMutation from '../queries/deleteIngredientMutation.graphql';
import {DeleteIngredient, DeleteIngredientVariables} from '../types/DeleteIngredient';
import {Ingredient} from '../../models';
import useGetIngredientGraphQL from './useGetIngredientGraphQL';
import {ListIngredients} from '../types/ListIngredients';

const Item = Ingredient;
type Item = Ingredient;
const UpdateMutation = updateIngredientMutation;
type UpdateItem = UpdateIngredient;
type UpdateVariables = UpdateIngredientVariables;
const DeleteMutation = deleteIngredientMutation;
type DeleteItem = DeleteIngredient;
type DeleteVariables = DeleteIngredientVariables;

/*
const buildVariables = (id?: string|null, name?: string|null): GetVariables|null => {
  if (id != null) {
    return {ingredient: {ingredientId: id}}
  }
  if (name != null) {
    return {ingredient: {name: name}}
  }
  return null;
}
*/

type Props = {
  currentId?: string|null,
  name?: string,
  typeName?: string,
  model?: { new (): Item; },
}

const useUpdateIngredientGraphQL = (props?: Props) => {

  const {currentId, name, typeName, model=Item} = {...props};
  const {item, gettingItem, getError} = useGetIngredientGraphQL({currentId: currentId, name: name, model:model})

  const [_updateItem, {loading: updatingItem, error: updateError}] = useMutation<UpdateItem, UpdateVariables>(UpdateMutation, {
    /*
    update(cache, {data}) {
      const updatedItem = data?.updateIngredient;
      if (updatedItem == null) {
        return;
      }
      cache.modify({
        fields: {
          ingredients(existingItems = []) {
            const updatedItemRef = cache.writeFragment({
              data: updatedItem,
              fragment: IngredientDetailsFragment,
            });
            return [...existingItems, updatedItemRef];
          }
        }
      });
    },
    */
    refetchQueries: () => {
      const queries: PureQueryOptions[] = [{
        query: listIngredientsQuery,
        variables: {ingredientType: {name: typeName}}
      }];
      if (currentId != null) {
        queries.push({
          query: getIngredientQuery,
          variables: {ingredient: {ingredientId: currentId, name: name}}
        });
      }
      return queries;
    }
  });

  const [_deleteItem, {error: deleteError}] = useMutation<DeleteItem, DeleteVariables>(DeleteMutation, {
    update: (client) => {
      const data = client.readQuery<ListIngredients>({
        query: listIngredientsQuery,
        variables: {ingredientType: {name: typeName}}
      });
      const newData = {
        ingredients: data?.ingredients?.filter((i) => i?.ingredientId !== currentId)
      }
      client.writeQuery({
        query: listIngredientsQuery,
        variables: {ingredientType: {name: typeName}},
        data: newData
      });
    },
    refetchQueries: () => [{
      query: listIngredientsQuery,
      variables: {ingredientType: {name: typeName}}
    }]
  });

  const updateItem = async (updatedItem: Item) => {
    if (updatedItem == null || _updateItem == null) {
      return;
    }
    const input = updatedItem.toInput(true);
    if (typeName != null && input.ingredientType == null) {
      input.ingredientType = {name: typeName};
    }
    await _updateItem({
      variables: {ingredient: input},
    });
  }

  const deleteItem = async (deletedItem: Item, deleteReason?: string) => {
    if (deletedItem == null || _deleteItem == null) {
      return;
    }
    const input = deletedItem.toInput(true);
    await _deleteItem({
      variables: {ingredient: input, deleteReason: deleteReason},
    });
  }

  return {
    item,
    gettingItem,
    getError,
    updateItem,
    updatingItem,
    updateError: updateError?.message || null,
    deleteItem,
    deleteError: deleteError?.message || null,
  };
}

export default useUpdateIngredientGraphQL;
