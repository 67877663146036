import groupBy from 'lodash/groupBy';
import {PicklistItem} from './PicklistItem';
import {Beverage, BeverageCore} from './Beverage';
import {IngredientInput} from '../graphql/types/globalTypes';
import {BeverageDetails} from '../graphql/types/BeverageDetails';
import {Ingredient} from './Ingredient';

export class BeerCore extends BeverageCore {

  static fromBeer(beer: Beer): BeerCore {
    return BeverageCore.fromBeverage(beer);
  }

}

export class Beer extends Beverage {

  adjuncts?: Ingredient[];
  hops?: Ingredient[];
  malts?: Ingredient[];
  yeasts?: Ingredient[];

  abv?: number;
  ibu?: number;
  availability?: string;
  brewersAssociationStyle?: PicklistItem;
  properties?: {
    abv?: number,
    ibu?: number,
    availability?: string;
    brewersAssociationStyle?: PicklistItem;
    [key:string]: any,
  };

  setFromGraphQL(data: BeverageDetails) {
    super.setFromGraphQL(data);
    const ingredientsByType = groupBy(this.ingredients, 'ingredientType.name');
    this.adjuncts = ingredientsByType['Adjunct'];
    delete ingredientsByType['Adjunct'];
    this.hops = ingredientsByType['Hop'];
    delete ingredientsByType['Hop'];
    this.malts = ingredientsByType['Malt'];
    delete ingredientsByType['Malt'];
    this.yeasts = ingredientsByType['Yeast'];
    delete ingredientsByType['Yeast'];
    const remainingIngredients: Ingredient[] = [];
    Object.entries(ingredientsByType).forEach(
      ([_k, v]) => remainingIngredients.concat(v)
    );
    this.ingredients = remainingIngredients;

    const {abv, ibu, availability, brewersAssociationStyle, ...rest} = {...this.properties};
    this.abv = abv == null ? undefined : Number(abv);
    this.ibu = ibu == null ? undefined : Number(ibu);
    this.availability = availability == null ? undefined : availability;
    this.brewersAssociationStyle = brewersAssociationStyle == null ? undefined : PicklistItem.fromMap(brewersAssociationStyle);
    this.properties = rest;
  }

  toInput(allProps=false): IngredientInput {
    const input = super.toInput(allProps);
    if (allProps) {
      input.ingredients = [
        ...(this.ingredients?.map(i => i.toInput()) || []),
        ...(this.adjuncts?.map(i => i.toInput()) || []),
        ...(this.hops?.map(i => i.toInput()) || []),
        ...(this.malts?.map(i => i.toInput()) || []),
        ...(this.yeasts?.map(i => i.toInput()) || []),
      ];
      const properties = {
        ...this.properties,
        ...(this.abv == null ? {} : {'abv': this.abv}),
        ...(this.ibu == null ? {} : {'ibu': this.ibu}),
        ...(this.availability == null ? {} : {'availability': this.availability}),
        ...(this.brewersAssociationStyle == null ? {} : {'brewersAssociationStyle': JSON.stringify(this.brewersAssociationStyle)}),
      };
      input.properties = JSON.stringify(properties);
    }
    return input;
  }
}
