import {useState} from 'react';

import {useQuery} from '@apollo/client';
import {ListGraphQLProps} from './graphQLHookTypes';
import listProducersQuery from '../queries/listProducersQuery.graphql';
import {ListProducers, ListProducersVariables} from '../types/ListProducers';

import {Producer} from '../../models';

const listQuery = listProducersQuery;
const Item = Producer;
type ListItems = ListProducers;
type ListVariables = ListProducersVariables;
type Item = Producer;


export type ListProducersProps = ListGraphQLProps & {
  typeName?: string,
  filterString?: string,
  limit?: number,
  offset?: number,
  skip?: boolean,
}

const useListProducersGraphQL = (props?: ListProducersProps) => {

  const {typeName, skip=false, filterString, limit, offset} = {...props};

  const [items, setItems] = useState<Item[]>([]);

  const {loading, error, } =
    useQuery<ListItems, ListVariables>(listQuery, {
      variables: {
        ...(typeName && {producerType: {name: typeName}}),
        filterString: filterString,
        limit: limit,
        offset: offset,
      },
      skip: skip || (limit === 0),
      //fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        if (data.producers != null) {
          setItems(data.producers.map(r => Item.fromGraphQL(r)));
        }
      }
    });

  return {
    items,
    gettingItems: loading,
    listError: error?.message || null,
  };
}

export default useListProducersGraphQL;
