import React from 'react';
import ItemEditDialog from '../common/ItemEditDialog';
import { Place } from '../../models';
import PlaceEdit from './PlaceEdit';


interface Props {
  place?: Place | null,
  model?: typeof Place,
  typeName?: string,
  onClose?: (value: string) => void,
  onDelete?: () => Promise<void>,
  open?: boolean,
}


const PlaceEditDialog = (props: Props) => {

  const {
    place, onClose, onDelete, open=false,
    model=Place, typeName='Place',
  } = props;

  return (
    <ItemEditDialog
      onClose={onClose}
      open={open}
      title={`Edit ${typeName}`}
    >
      <PlaceEdit
        place={place}
        model={model}
        typeName={typeName}
        onDelete={onDelete}
      />
    </ItemEditDialog>
  );
};

export default PlaceEditDialog;
