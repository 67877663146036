import React, { Fragment, FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import LabeledText from '../common/LabeledText';
import ItemView from '../common/ItemView';
import LabeledURL from '../common/LabeledURL';
import { BrewersAssociationStyle } from '../../models';


interface Props {
  style?: BrewersAssociationStyle | null,
  id?: string|null,
}

const BrewersAssociationStyleView: FunctionComponent<Props> = props => {

  const {style, id} = props;

  const theme = useTheme();

  const renderView = (item: BrewersAssociationStyle|null) => {
    return (
      <Fragment>
        <Typography
          sx={theme.itemView.title}
          variant='h2'
        >
          {item?.name}
        </Typography>
        <Typography
          sx={theme.itemView.body}
          variant='body2' color='textSecondary'
        >
          {item?.description}
        </Typography>
        <Typography
          sx={theme.itemView.body}
          variant='body1'
        >
          {item?.family}
        </Typography>
        <Typography
          sx={theme.itemView.body}
          variant='body1'
        >
          {item?.origin}
        </Typography>
        <Typography
          sx={theme.itemView.body}
          variant='body1'
        >
          {item?.description}
        </Typography>
        <LabeledText
          label='Picklist Name'
          text={item?.picklistName}
        />
        <LabeledURL
          label='website'
          url={item?.website}
        />
      </Fragment>
    );
  };

  return (
    <ItemView
      item={style}
      id={id}
      viewRenderer={renderView}
    />
  )
};

export default BrewersAssociationStyleView;
