import {useState} from 'react';
import {sortBy} from 'lodash';

import {useQuery} from '@apollo/client';
import listBeverageTypesQuery from '../queries/listBeverageTypesQuery.graphql';
import {ListBeverageTypes, ListBeverageTypesVariables} from '../types/ListBeverageTypes';

import {BeverageType} from '../../models';

const listQuery = listBeverageTypesQuery;
const Item = BeverageType;
type ListItems = ListBeverageTypes;
type ListVariables = ListBeverageTypesVariables;
type Item = BeverageType;


type Props = {
  categoryName: string,
  sortByAttributeName?: string,
}

const useListBeverageTypesGraphQL = (props?: Props) => {

  const {categoryName, sortByAttributeName} = {...props};

  const [items, setItems] = useState<Item[]>([]);

  const {loading, error} =
    useQuery<ListItems, ListVariables>(listQuery, {
      variables: categoryName === 'Beverage' ? undefined : {category: {name: categoryName}},
      onCompleted: data => {
        if (data.beverageTypes != null) {
          let beverageTypes = data.beverageTypes.map(r => Item.fromGraphQL(r));
          if (sortByAttributeName != null) {
            beverageTypes = sortBy(beverageTypes, sortByAttributeName);
          }
          setItems(beverageTypes);
        }
      }
    });

  return {
    items,
    gettingItems: loading,
    listError: error?.message || null,
  };
}

export default useListBeverageTypesGraphQL;
