import {PlaceInput} from '../graphql/types/globalTypes';
import {PlaceDetails} from '../graphql/types/PlaceDetails';
import {PlaceMinimum} from '../graphql/types/PlaceMinimum';
import {MapType} from './Base';
import {Location} from './Location';
import {PlaceBeverage} from './PlaceBeverage';
import {PlaceType} from './PlaceType';
import {VersionedItem} from './VersionedItem';


export class PlaceCore extends VersionedItem {

  placeId?: string;
  description?: string;
  phone?: string;
  website?: string;
  logoUrls?: MapType;
  links?: MapType;
  properties?: {
    amenities?: string[];
    [key:string]: any,
  };

  get currentId() {
    return this.placeId;
  }

  static fromPlace(place?: Place|null): PlaceCore {
    const core = new PlaceCore();
    if (place != null) {
      core.id = place.id;
      core.name = place.name;
      core.placeId = place.placeId;
      core.description = place.description;
      core.phone = place.phone;
      core.website = place.website;
      core.logoUrls = place.logoUrls;
      core.links = place.links;
      core.properties = place.properties;
    }
    return core;
  }

  setFromGraphQL(data: Partial<PlaceMinimum>|null) {
    if (data == null) return;

    super.setFromGraphQL(data);
    this.placeId = data.placeId == null ? undefined : data.placeId;
    this.description = data.description == null ? undefined : data.description;
    this.phone = data.phone == null ? undefined : data.phone;
    this.website = data.website == null ? undefined : data.website;
    this.logoUrls = data.logoUrls;
    this.links = data.links;

    const {amenities, ...rest} = {...this.properties};
    this.amenities = amenities == null ? undefined : amenities;
    this.properties = rest;
  }
}

export type PlaceBeerMap = {[id: string]: PlaceBeverage};

export class Place extends PlaceCore {
  placeTypes?: PlaceType[];
  location?: Location;
  distance?: number;

  get cityState() {
    if (this.location != null) {
      return this.location.cityState;
    }
    return '';
  }

  setFromGraphQL(data: Partial<PlaceDetails>|null) {
    if (data == null) return;

    super.setFromGraphQL(data);
    this.placeTypes = data.placeTypes == null
      ? undefined
      : data.placeTypes.map(pt => PlaceType.fromGraphQL(pt));
    this.location = data.location == null
      ? undefined
      : Location.fromGraphQL(data.location);
    this.distance = data.distance == null ? undefined : data.distance/1609.34;
  }

  toCore = () => {
    return PlaceCore.fromPlace(this);
  }

  toInput(allProps=false): PlaceInput {
    const input = super.toInput(allProps);
    if (allProps) {
      const properties = {
        ...this.properties,
        ...(this.amenities == null ? {} : {'amenities': this.amenities}),
      };
      return {
        ...input,
        placeId: this.placeId,
        description: this.description,
        phone: this.phone,
        website: this.website,
        logoUrls: JSON.stringify(this.logoUrls),
        links: JSON.stringify(this.links),
        properties: JSON.stringify(properties),
        placeTypes: this.placeTypes?.map(pt => pt.toInput()),
        location: this.location?.toInput(),
      };
    } else {
      return {
        ...input,
        placeId: this.placeId,
      };
    }
  }
}
