import React, { Fragment, FunctionComponent } from 'react';

import LabeledText from '../common/LabeledText';
import { Seltzer } from '../../models';
import { BeverageView } from '../Beverage';
import SeltzerEditDialog from './SeltzerEditDialog';


interface Props {
  seltzer?: Seltzer | null,
  id?: string|null,
}

const SeltzerView: FunctionComponent<Props> = props => {

  const {seltzer, id} = props;

  const additionalFields = (item: Seltzer|null) => {
    return (
      <Fragment>
        <LabeledText
          label='ABV'
          text={item?.abv != null ? item?.abv.toLocaleString() : ''}
        />
        <LabeledText
          label='Availability'
          text={item?.availability}
        />
      </Fragment>
    );
  };

  return (
    <BeverageView
      beverage={seltzer}
      id={id}
      model={Seltzer}
      additionalFieldRenderer={additionalFields}
      editDialog={<SeltzerEditDialog/>}
    />
  )
};

export default SeltzerView;
