import React, { Fragment, FunctionComponent, ReactNode } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ItemView from '../common/ItemView';
import LabeledLinks from '../common/LabeledLinks';
import LabeledMap from '../common/LabeledMap';
import LabeledText from '../common/LabeledText';
import useGetBeverageTypeGraphQL from '../../graphql/hooks/useGetBeverageTypeGraphQL';
import { BeverageType } from '../../models';


interface Props {
  beerType?: BeverageType | null,
  id?: string|null,
  model?: typeof BeverageType,
  additionalFieldRenderer?: (item: BeverageType|null) => ReactNode,
}

const BeverageTypeView: FunctionComponent<Props> = props => {

  const {beerType, id, model=BeverageType, additionalFieldRenderer} = props;

  const theme = useTheme();

  const renderView = (item: BeverageType|null) => {
    return (
      <Fragment>
        <Typography
          sx={theme.itemView.title}
          variant='h2'
        >
          {item?.name}
        </Typography>
        <Typography
          sx={theme.itemView.body}
          variant='body1'
        >
          {item?.description}
        </Typography>
        <LabeledText
          label='Picklist Name'
          text={item?.picklistName}
        />
        <LabeledText
          label='Category'
          text={item?.category?.name}
        />
        <LabeledText
          label='Flavor'
          text={item?.flavor}
        />
        <LabeledText
          label='Aliases'
          text={item?.aliases && item.aliases.join(', ')}
        />
        <LabeledLinks
          label='Links'
          links={item?.links}
        />
        {item?.properties && item.properties.length > 0 &&
          <LabeledMap
            label='Properties'
            map={item?.properties}
          />
        }
        {additionalFieldRenderer && additionalFieldRenderer(item)}
      </Fragment>
    );
  };

  return (
    <ItemView
      item={beerType}
      id={id}
      useGetGraphQL={useGetBeverageTypeGraphQL}
      useGetGraphQLProps={{model: model}}
      viewRenderer={renderView}
    />
  )
};

export default BeverageTypeView;
