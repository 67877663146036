import React, {useEffect, useState} from 'react';
import {Item} from '../models';
import DuplicatesDialog from '../components/common/DuplicateDialog';

type Props<T extends Item> = {
  useListGraphQL?: (any: any) => any,
  useListGraphQLProps?: {[key: string]: any},
  message: string,
  createItemDescription: (item: T) => string,
  onDuplicate?: () => void,
}

function useDuplicateDialog<T extends Item>(
  props: Props<T>
) {

  const {
    useListGraphQL = () => {}, useListGraphQLProps,
    message, createItemDescription, onDuplicate,
  } = props;

  const [duplicateSearchString, setDuplicateSearchString] = useState<string>('');

  /*
  const [serviceArgs, setServiceArgs] = useState<PlaceBeerStreamServiceArgs>({});
  useEffect(() => {
    if (duplicateSearchString != null && duplicateSearchString !== '') {
      setRunQuery(true);
    }
    setServiceArgs({filterString: duplicateSearchString, limit: 5})
  }, [duplicateSearchString]);
  const [runQuery, setRunQuery] = useState<boolean>(false);
  const {firestoreItems: duplicateItems} = useStreamFromFirebaseService<S, T>(
      service,
      serviceArgs,
      runQuery
  );
  */

  const {items: duplicateItems} = useListGraphQL({
    filterString: duplicateSearchString,
    limit: 5,
    skip: duplicateSearchString.length <= 2,
    ...useListGraphQLProps
  });

  const [openDuplicateDialog, setOpenDuplicateDialog] = useState<boolean>(false);
  useEffect(() => {
    if (duplicateItems != null && duplicateItems.length > 0) {
        setOpenDuplicateDialog(true);
    }
  }, [duplicateItems]);

  const handleDuplicate = () => {
    console.log('Duplicate reported');
    setOpenDuplicateDialog(false);
    onDuplicate && onDuplicate();
  };

  const handleDuplicateDialogClose = () => {
    setOpenDuplicateDialog(false);
  };

  const duplicatesDialog = (
    <DuplicatesDialog
      onClose={handleDuplicateDialogClose}
      onDuplicate={handleDuplicate}
      open={openDuplicateDialog}
      items={duplicateItems}
      message={message}
      itemDescription={createItemDescription}
    />
  );

  return {
    duplicatesDialog,
    duplicateItems,
    handleDuplicate,
    handleDuplicateDialogClose,
    openDuplicateDialog,
    setDuplicateSearchString,
  };
}

export default useDuplicateDialog;
