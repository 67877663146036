import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import AuthRouteWithLayout from './AuthRouteWithLayout';
import RouteWithLayout from './RouteWithLayout';
import {BeerEdit, BeerView} from '../components/Beer';
import {PlaceBeerView} from '../components/Place';
import {SeltzerEdit, SeltzerView} from '../components/Seltzer';
import {BeverageEdit, BeverageView} from '../components/Beverage';
import {BeverageTypeView} from '../components/BeverageType';
import {ProducerEdit, ProducerView} from '../components/Producer';
import ComingSoon from '../components/common/ComingSoon';
import {Main as MainLayout, Minimal as MinimalLayout} from '../layouts';
import {
  BeerIngredientsView,
  BeersView,
  BeveragesView,
  BeverageTypesView,
  BreweriesView,
  CidersView,
  HomeView,
  NearbyBeveragesView,
  PlacesView,
  ProducersView,
  SeltzersView,
  SignInView,
  SpiritsView,
  WinesView,
} from '../views';
import {PlaceEdit, PlaceView} from '../components/Place';
import PrivacyPolicy from '../components/common/PrivacyPolicy';
import ResendVerificationEmail
    from '../components/SignIn/ResendVerificationEmail';
import TermsAndConditions from '../components/common/TermsAndConditions';


const AllRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/home' />} />
      <Route
        path='/beer/:id'
        element={
          <AuthRouteWithLayout layout={MainLayout}>
            <BeerView/>
          </AuthRouteWithLayout>
        }
      />
      <Route
        path='/beer-edit/:id'
        element={
          <AuthRouteWithLayout layout={MainLayout}>
            <BeerEdit/>
          </AuthRouteWithLayout>
        }
      />
      <Route
        path='/beer-ingredients'
        element={
          <RouteWithLayout layout={MainLayout}>
            <BeerIngredientsView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/beers'
        element={
          <RouteWithLayout layout={MainLayout}>
            <BeersView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/beverage/:id'
        element={
          <RouteWithLayout layout={MainLayout}>
            <BeverageView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/beverage-edit/:id'
        element={
          <AuthRouteWithLayout layout={MainLayout}>
            <BeverageEdit/>
          </AuthRouteWithLayout>
        }
      />
      <Route
        path='/beverage-type/:id'
        element={
          <RouteWithLayout layout={MainLayout}>
            <BeverageTypeView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/beverage-types'
        element={
          <RouteWithLayout layout={MainLayout}>
            <BeverageTypesView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/beverages'
        element={
          <RouteWithLayout layout={MainLayout}>
            <BeveragesView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/breweries'
        element={
          <RouteWithLayout layout={MainLayout}>
            <BreweriesView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/brewery/:id'
        element={
          <RouteWithLayout layout={MainLayout}>
            <ProducerView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/brewery-edit/:id'
        element={
          <AuthRouteWithLayout layout={MainLayout}>
            <ProducerEdit/>
          </AuthRouteWithLayout>
        }
      />
      <Route
        path='/ciders'
        element={
          <RouteWithLayout layout={MainLayout}>
            <CidersView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/distilleries'
        element={
          <RouteWithLayout layout={MainLayout}>
            <ComingSoon/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/home'
        element={
          <RouteWithLayout layout={MainLayout}>
            <HomeView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/places'
        element={
          <RouteWithLayout layout={MainLayout}>
            <PlacesView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/place/:id'
        element={
          <RouteWithLayout layout={MainLayout}>
            <PlaceView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/place-beer/:id'
        element={
          <RouteWithLayout layout={MainLayout}>
            <PlaceBeerView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/place-beverages'
        element={
          <RouteWithLayout layout={MainLayout}>
            <NearbyBeveragesView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/place-edit/:id'
        element={
          <AuthRouteWithLayout layout={MainLayout}>
            <PlaceEdit/>
          </AuthRouteWithLayout>
        }
      />
      <Route
        path='/privacy'
        element={
          <RouteWithLayout layout={MainLayout}>
            <PrivacyPolicy/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/producer/:id'
        element={
        <RouteWithLayout layout={MainLayout}>
          <ProducerView/>
        </RouteWithLayout>
        }
      />
      <Route
        path='/producer-edit/:id'
        element={
          <AuthRouteWithLayout layout={MainLayout}>
            <ProducerEdit/>
          </AuthRouteWithLayout>
        }
      />
      <Route
        path='/producers'
        element={
          <RouteWithLayout layout={MainLayout}>
            <ProducersView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/resend-verification-email'
        element={
          <RouteWithLayout layout={MinimalLayout}>
            <ResendVerificationEmail/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/seltzer/:id'
        element={
          <AuthRouteWithLayout layout={MainLayout}>
            <SeltzerView/>
          </AuthRouteWithLayout>
        }
      />
      <Route
        path='/seltzer-edit/:id'
        element={
          <AuthRouteWithLayout layout={MainLayout}>
            <SeltzerEdit/>
          </AuthRouteWithLayout>
        }
      />
      <Route
        path='/seltzers'
        element={
          <RouteWithLayout layout={MainLayout}>
            <SeltzersView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/sign-in'
        element={
          <RouteWithLayout layout={MinimalLayout}>
            <SignInView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/sign-up'
        element={
          <RouteWithLayout layout={MinimalLayout}>
            <SignInView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/spirit-ingredients'
        element={
          <RouteWithLayout layout={MainLayout}>
            <ComingSoon/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/spirits'
        element={
          <RouteWithLayout layout={MainLayout}>
            <SpiritsView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/terms'
        element={
          <RouteWithLayout layout={MainLayout}>
            <TermsAndConditions/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/verify-sign-in'
        element={
          <RouteWithLayout layout={MinimalLayout}>
            <SignInView/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/wineries'
        element={
          <RouteWithLayout layout={MainLayout}>
            <ComingSoon/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/wine-ingredients'
        element={
          <RouteWithLayout layout={MainLayout}>
            <ComingSoon/>
          </RouteWithLayout>
        }
      />
      <Route
        path='/wines'
        element={
          <RouteWithLayout layout={MainLayout}>
            <WinesView/>
          </RouteWithLayout>
        }
      />
    </Routes>
  );
};

export default AllRoutes;