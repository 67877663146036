import React from 'react';
import {
  FormControl,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import renameObjectKey from '../../helpers/renameObjectKey';
import { INPUT_VARIANT } from './ItemEdit';


interface Links {
  [key:string]: string;
}


interface Props {
  onChange?: (val: Links) => void;
  value: Links|null|undefined;
}

const LinkListEdit = (props: Props) => {
  const { value={}, onChange } = props;

  const theme = useTheme();
  const styles = {
    url: {
      marginLeft: theme.spacing(2)
    },
  };

  const handleNameChange = (oldName: string, newName: string) => {
    const newLinks = renameObjectKey(oldName, newName, value);
    if (newLinks != null) {
      onChange && onChange(newLinks);
    }
  };

  const handleURLChange = (name: string, newValue: string) => {
    const newLinks = {
      ...(value || {}),
      [name]: newValue,
    };
    onChange && onChange(newLinks);
  };

  const handleAddClick = () => {
    const newLinks = {
      ...(value || {}),
      '': '',
    };
    onChange && onChange(newLinks);
  };

  return (
    <div>
    <FormControl sx={theme.itemEdit.formControl}>
      {value && Object.keys(value).map((name, i) =>
        <div key={i}>
          <TextField
            sx={theme.itemEdit.textField}
            label='Name'
            name='name'
            onChange={e => handleNameChange(name, e.target.value)}
            type='text'
            value={name || ''}
            variant={INPUT_VARIANT}
          />
          <TextField
            sx={{...theme.itemEdit.textField, ...styles.url}}
            label='URL'
            name='url'
            onChange={e => handleURLChange(name, e.target.value)}
            type='text'
            value={value[name] || ''}
            variant={INPUT_VARIANT}
          />
        </div>
      )}
      <IconButton
        color='secondary'
        sx={theme.itemEdit.addButton}
        aria-label='add'
        onClick={handleAddClick}
        size='large'>
        <Add/>
        <Typography
          sx={theme.itemEdit.textField}
          variant='body1'
        >
          Add a New Link
        </Typography>
      </IconButton>
      </FormControl>
    </div>
  );
};

export default LinkListEdit;
