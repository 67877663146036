import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Input, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';


interface Props {
  folder?: string,
  imageURL?: string,
  label?: string|null;
  onChange?: (file: File) => void;
}


const ImagePicker = ({folder='', ...props}: Props) => {

  const { imageURL, label, onChange } = props;
  const [url, setURL] = useState<string>("");

  useEffect(() => {
    if (imageURL != null) {
      setURL(imageURL);
    }
  }, [imageURL]);

  const theme = useTheme();
  const styles = {
    root: {
      display: 'flex',
    },
    label: {
    },
    input: {
      marginTop: theme.spacing(3),
      flexDirection: 'column',
      justifyContent: 'center',
    },
    imagePicker: {
    },
    image: {
      height: 300,
      width: '100%',
      objectFit: "contain",
      maxWidth: 400,
      backgroundSize: 'contain',
    },
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    console.log('files', files);
    if (files == null) {
      return;
    }
    if (files[0]) {
      const file = files[0];
      setURL(URL.createObjectURL(file));
      onChange && onChange(file);
    }
  };

  const createImagePicker = () => (
    <Box sx={styles.imagePicker}>
      <Input
        inputProps={{accept: 'image/jpeg,image/gif,image/png,image/webp'}}
        sx={styles.input}
        id='icon-button-photo'
        onChange={handleChange}
        type='file'
        placeholder='No new file selected'
      />
      {url &&
        <Box component='img'
          sx={styles.image}
          src={url}
          alt='Uploaded Images'
        />
      }
    </Box>

  );

  if (label != null) {
    return (
      <Box sx={styles.root}>
        <Typography
            sx={styles.label}
            variant='h6'
            display='inline'
        >
          {label}
        </Typography>
        {createImagePicker()}
      </Box>
    )
  }
  return createImagePicker();
};

export default ImagePicker;
