import React from 'react';

import { Seltzer, ProducerCore } from '../../models';
import SeltzerEdit from './SeltzerEdit';
import ItemEditDialog from '../common/ItemEditDialog';


type Props = {
  beverage?: Seltzer | null,
  producer?: ProducerCore | null,
  onClose?: (value: string) => void,
  onDelete?: () => Promise<void>,
  open?: boolean,
}


const SeltzerEditDialog = (props: Props) => {

  const { beverage, producer, onClose, onDelete, open=false } = props;

  console.log('Seltzer Edit Dialog', beverage);
  return (
    <ItemEditDialog
      onClose={onClose}
      open={open}
      title='Edit Seltzer'
    >
      <SeltzerEdit
        beverage={beverage}
        producer={producer}
        onDelete={onDelete}
      />
    </ItemEditDialog>
  );
};

export default SeltzerEditDialog;
