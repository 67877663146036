import React, { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ItemView, { ItemViewProps } from '../common/ItemView';
import LabeledText from '../common/LabeledText';
import {PlaceBeverage} from '../../models';
import OfferedInTable from './OfferedInTable';


type Props = ItemViewProps<PlaceBeverage> & {
  placeBeverage?: PlaceBeverage | null,
}

const PlaceBeverageView: FunctionComponent<Props> = props => {

  const { placeBeverage,
    ...itemViewProps
  } = props;

  //console.log('PlaceBeverageView', placeBeverage);

  const theme = useTheme();

  const renderView = (item: PlaceBeverage|null) => {
    return (
      <Box sx={theme.itemView.body}>
        {item?.place &&
          <LabeledText
              label='Place'
              text={placeBeverage?.place?.name}
              link={`/place/${item?.place?.placeId}`}
          />
        }
        {item?.beverage &&
          <LabeledText
              label='Beverage'
              text={placeBeverage?.beverage?.name}
              link={`/beverage/${item?.beverage?.beverageId}`}
          />
        }
        {item?.beverage?.producer &&
          <LabeledText
              label='Producer'
              text={placeBeverage?.beverage?.producer?.name}
              link={`/producer/${placeBeverage?.beverage?.producer?.producerId}`}
          />
        }
        {item?.offeredIn &&
          <OfferedInTable
              offeredIn={item.offeredIn}
          />
        }
      </Box>
    );
  };

  return (
    <ItemView
      item={placeBeverage}
      viewRenderer={renderView}
      {...itemViewProps}
    />
  )
};

export default PlaceBeverageView;
