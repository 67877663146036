import React, { Fragment, useContext } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ItemEdit, {
  FieldRenderer,
  INPUT_VARIANT,
  ItemEditProps,
} from '../common/ItemEdit';
import LinkListEdit from '../common/LinkListEdit';
import MapEdit from '../common/MapEdit';
import SingleSelect from '../common/SingleSelect';
import StringListEdit from '../common/StringListEdit';
import TextEdit from '../common/TextEdit';
import { PicklistsContext } from '../../contexts';
import useUpdateBeverageTypeGraphQL from '../../graphql/hooks/useUpdateBeverageTypeGraphQL';
import { FormErrors, InputEvent, SelectEvent } from '../../helpers/useFormValidation';
import useListDuplicateDialog from '../../helpers/useListDuplicateDialog';
import { BeverageType, PicklistItem } from '../../models';


function validateBeverageType(values: BeverageType) {
  let errors: FormErrors<BeverageType> = {};

  // Name Errors
  if (!values.name) {
    errors.name = 'Name required';
  } else if (values.name.length < 2) {
    errors.name = 'Name must be at least 2 characters';
  }
  // Picklist Name Errors
  if (!values.picklistName) {
    errors.picklistName = 'Picklist Name required';
  } else if (values.picklistName.length < 2) {
    errors.picklistName = 'Picklist Name must be at least 2 characters';
  }

  return errors;
}

const INITIAL_VALUE = new BeverageType();

type Props = ItemEditProps<BeverageType> & {
  beverageType?: BeverageType | null,
  beverageTypes?: PicklistItem[],
  model?: typeof BeverageType,
  categoryName?: string,
  additionalFieldRenderer?: FieldRenderer<BeverageType>,
}

const BeverageTypeEdit = (props: Props) => {

  const {beverageType, beverageTypes,
    model=BeverageType, categoryName='Beverage Type', additionalFieldRenderer,
    onComplete, ...itemEditProps} = props;
  //console.log('BeverageTypeEdit', beverageType);

  const theme = useTheme();

  const {beverageCategories} = useContext(PicklistsContext);
  const {
    duplicatesDialog,
    setDuplicateSearchString
  } = useListDuplicateDialog(
    beverageTypes||null,
    `This new beverage type might be a duplicate of one of the following types.
             Please confirm that this new entry is not a duplicate`,
    onComplete
  );

  const renderFields = (
    originalItem: BeverageType|null,
    formItem: BeverageType|null,
    formErrors: FormErrors<BeverageType>,
    handleBlur: ()=> void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => {

    const handleNameBlur = () => {
      if (formItem?.id == null && formItem?.name && formItem.name.length >= 3) {
        setDuplicateSearchString(formItem.name);
      }
    }

    return (
      <Fragment>
        <Typography
          sx={theme.itemEdit.title}
          variant='h2'
        >
          {originalItem?.name || `New ${categoryName}`}
        </Typography>
        <TextEdit
          error={formErrors.name}
          label='Name'
          name='name'
          variant={INPUT_VARIANT}
          onBlur={handleNameBlur}
          onChange={handleChange}
          value={formItem?.name}
        />
        <TextEdit
          error={formErrors.picklistName}
          label='Picklist Name'
          name='picklistName'
          variant={INPUT_VARIANT}
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.picklistName}
        />
        <SingleSelect
          label='Category'
          name='Category'
          items={beverageCategories}
          value={formItem?.category}
          onChange={val => updateProperty('category', val)}
        />
        <TextEdit
          error={formErrors.description}
          label='Description'
          name='description'
          variant={INPUT_VARIANT}
          multiline
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.description}
        />
        <TextEdit
          error={formErrors.flavor}
          label='Flavor'
          name='flavor'
          variant={INPUT_VARIANT}
          multiline
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.flavor}
        />
        <StringListEdit
          label='Aliases'
          value={formItem?.aliases}
          onChange={(val) => updateProperty('aliases', val)}
        />
        <LinkListEdit
          value={formItem?.links}
          onChange={(val) => updateProperty('links', val)}
        />
        <MapEdit
          label='Additional Properties'
          value={formItem?.properties}
        />
        {additionalFieldRenderer && additionalFieldRenderer(
          originalItem,
          formItem,
          formErrors,
          handleBlur,
          handleChange,
          updateProperty,
        )}
      </Fragment>
    )
  };

  return (
    <div>
      <ItemEdit
        item={beverageType}
        defaultValue={INITIAL_VALUE}
        useUpdateGraphQL={useUpdateBeverageTypeGraphQL}
        useUpdateGraphQLProps={{model: model, categoryName: categoryName}}
        fieldRenderer={renderFields}
        validator={validateBeverageType}
        onCancel={onComplete}
        onComplete={onComplete}
        {...itemEditProps}
      />
      {duplicatesDialog}
    </div>
  );
};

export default BeverageTypeEdit;
