
const renameObjectKey = (
  oldKey: string,
  newKey: string,
  obj: {[key: string]: any}|null
) => {
  if (obj == null) {
    return null
  }
  const {[oldKey]: old, ...others} = obj;
  return {
    ...others,
    [newKey]: old,
  };
};

export default renameObjectKey;