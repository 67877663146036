import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ImagePicker from '../common/ImagePicker';
import ItemEdit, { FieldRenderer, ItemEditProps } from '../common/ItemEdit';
import LinkListEdit from '../common/LinkListEdit';
import LocationEdit from '../common/LocationEdit';
import MapEdit from '../common/MapEdit';
import MultiSelect from '../common/MultiSelect';
import TextEdit from '../common/TextEdit';
import useListPlacesGraphQL from '../../graphql/hooks/useListPlacesGraphQL';
import useListPlaceTypesGraphQL from '../../graphql/hooks/useListPlaceTypesGraphQL';
import useUpdatePlaceGraphQL from '../../graphql/hooks/useUpdatePlaceGraphQL';
import { uploadLogo } from '../../helpers/imageUpload';
import useDuplicateDialog from '../../helpers/useDuplicateDialog';
import { FormErrors, InputEvent, SelectEvent } from '../../helpers/useFormValidation';
import { Location, Place, Producer } from '../../models';
import useGetPicklistGraphQL from '../../graphql/hooks/useGetPicklistGraphQL';


function validatePlace(values: Place) {
  let errors: FormErrors<Place> = {};

  // Name Errors
  if (!values.name) {
    errors.name = 'Name required';
  } else if (values.name.length < 2) {
    errors.name = 'Name must be at least 2 characters';
  }

  return errors;
}

const INITIAL_VALUE = new Place();

const createItemDescription = (item: Place) => {
  if (item != null) {
    return `${item.name} from ${item.cityState}`;
  }
  return '';
};


type Props = ItemEditProps<Place> & {
  place?: Place|null,
  model?: typeof Place,
  typeName?: string,
  additionalFieldRenderer?: FieldRenderer<Place>,
}

const PlaceEdit = (props: Props) => {

  const {
    place, model=Producer, typeName='Place',
    additionalFieldRenderer,
    ...itemEditProps
  } = props;

  //console.log('PlaceEdit place', place);

  const theme = useTheme();
  const navigate = useNavigate();

  const {
    duplicatesDialog,
    setDuplicateSearchString
  } = useDuplicateDialog({
    useListGraphQL: useListPlacesGraphQL,
    useListGraphQLProps: {typeName: typeName},
    message: `This new place might be a duplicate of one of the following places.
             Please confirm that this new entry is not a duplicate`,
    createItemDescription: createItemDescription,
    onDuplicate: () => {navigate(-1);},
  });

  const [_logoFile, setLogoFile] = useState<File|null>(null);

  async function preSubmit(item: Place) {
    if (_logoFile != null) {
      item.logoUrls = await uploadLogo(_logoFile, 'logos/places');
    }
    return item;
  }

  const {items: placeTypes} = useListPlaceTypesGraphQL();
  const {strings: amenities} = useGetPicklistGraphQL({name: 'Amenities'});

  const renderFields = (
    originalItem: Place|null,
    formItem: Place|null,
    formErrors: FormErrors<Place>,
    handleBlur: ()=> void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => {

    const logoUrl = formItem?.logoUrls && formItem.logoUrls['original'];

    function handleLocationChange(location: Location) {
      updateProperty && updateProperty('location', location);
    }

    const handleNameBlur = () => {
      if (formItem?.id == null && formItem?.name && formItem.name.length >= 4) {
        setDuplicateSearchString(formItem.name);
      }
    }

    console.log('placeTypes', placeTypes, formItem?.placeTypes);

    return (
      <Box sx={theme.itemEdit.form}>
        <Typography
          sx={theme.itemEdit.title}
          variant='h2'
        >
          Editing {originalItem?.name || `New ${typeName}`}
        </Typography>
        <TextEdit
          error={formErrors.name}
          label='Name'
          name='name'
          onBlur={handleNameBlur}
          onChange={handleChange}
          value={formItem?.name}
        />
        <TextEdit
          error={formErrors.description}
          label='Description'
          name='description'
          multiline
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.description}
        />
        <LocationEdit
          location={formItem?.location}
          onChange={handleLocationChange}
        />
        <TextEdit
          error={formErrors.phone}
          label='Phone'
          name='phone'
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.phone}
        />
        <MultiSelect
          label='Place Types'
          name='placeTypes'
          items={placeTypes}
          values={formItem?.placeTypes}
          onChange={(vals) => updateProperty('placeTypes', vals)}
        />
        <MultiSelect
          label='Amenities'
          name='amenities'
          items={amenities}
          values={formItem?.amenities}
          onChange={(vals) => updateProperty('amenities', vals)}
        />
        <ImagePicker
          label='Logo'
          imageURL={logoUrl}
          folder='logos/places'
          onChange={setLogoFile}
        />
        <TextEdit
          error={formErrors.website}
          label='Website'
          name='website'
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.website}
        />
        <div>
          <LinkListEdit
            value={formItem?.links}
            onChange={(val) => updateProperty('links', val)}
          />
        </div>
        {formItem?.properties?.length &&
          <MapEdit
            label='Additional Properties'
            value={formItem?.properties}
          />
        }
        {additionalFieldRenderer && additionalFieldRenderer(
          originalItem,
          formItem,
          formErrors,
          handleBlur,
          handleChange,
          updateProperty,
        )}
      </Box>
    );
  };

  return (
    <div>
      <ItemEdit
        item={place}
        itemTypeName={typeName||'Place'}
        defaultValue={INITIAL_VALUE}
        useUpdateGraphQL={useUpdatePlaceGraphQL}
        useUpdateGraphQLProps={{model: model, typeName: typeName}}
        fieldRenderer={renderFields}
        validator={validatePlace}
        preSubmit={preSubmit}
        {...itemEditProps}
      />
      {duplicatesDialog}
    </div>
  )
};

export default PlaceEdit;
