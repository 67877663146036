import {PureQueryOptions, useMutation} from '@apollo/client';
import getBeverageQuery from '../queries/getBeverageQuery.graphql';
import listBeveragesQuery from '../queries/listBeveragesQuery.graphql';
import updateBeverageMutation from '../queries/updateBeverageMutation.graphql';
import {UpdateBeverage, UpdateBeverageVariables} from '../types/UpdateBeverage';
import deleteBeverageMutation from '../queries/deleteBeverageMutation.graphql';
import {DeleteBeverage, DeleteBeverageVariables} from '../types/DeleteBeverage';
import {Beverage} from '../../models';
import useGetBeverageGraphQL from './useGetBeverageGraphQL';
import {ListBeverages} from '../types/ListBeverages';

const Item = Beverage;
type Item = Beverage;
const UpdateMutation = updateBeverageMutation;
type UpdateItem = UpdateBeverage;
type UpdateVariables = UpdateBeverageVariables;
const DeleteMutation = deleteBeverageMutation;
type DeleteItem = DeleteBeverage;
type DeleteVariables = DeleteBeverageVariables;


type Props = {
  currentId?: string|null,
  name?: string,
  typeName?: string,
  model?: { new (): Item; },
}

const useUpdateBeverageGraphQL = (props?: Props) => {

  const {currentId, name, typeName, model=Item} = {...props};
  const {item, gettingItem, getError} = useGetBeverageGraphQL({
    currentId: currentId,
    name: name,
    model:model
  })

  const [_updateItem, {loading: updatingItem, error: updateError}] = useMutation<UpdateItem, UpdateVariables>(UpdateMutation, {
    refetchQueries: () => {
      const queries: PureQueryOptions[] = [{
        query: listBeveragesQuery,
        variables: {beverageType: {name: typeName}}
      }];
      if (currentId != null) {
        queries.push({
          query: getBeverageQuery,
          variables: {beverage: {beverageId: currentId, name: name}}
        });
      }
      return queries;
    }
  });

  const [_deleteItem, {error: deleteError}] = useMutation<DeleteItem, DeleteVariables>(DeleteMutation, {
    update: (client) => {
      const data = client.readQuery<ListBeverages>({
        query: listBeveragesQuery,
        variables: {beverageType: {name: typeName}}
      });
      const newData = {
        beverages: data?.beverages?.filter((i) => i?.beverageId !== currentId)
      }
      client.writeQuery({
        query: listBeveragesQuery,
        variables: {beverageType: {name: typeName}},
        data: newData
      });
    },
    refetchQueries: () => [{
      query: listBeveragesQuery,
      variables: {beverageType: {name: typeName}}
    }]
  });

  const updateItem = async (updatedItem: Item) => {
    if (updatedItem == null || _updateItem == null) {
      return;
    }
    const input = updatedItem.toInput(true);
    if (typeName != null && input.beverageType == null) {
      input.beverageType = {name: typeName};
    }
    await _updateItem({
      variables: {beverage: input},
    });
  }

  const deleteItem = async (deletedItem: Item, deleteReason?: string) => {
    if (deletedItem == null || _deleteItem == null) {
      return;
    }
    const input = deletedItem.toInput(true);
    await _deleteItem({
      variables: {beverage: input, deleteReason: deleteReason},
    });
  }

  return {
    item,
    gettingItem,
    getError,
    updateItem,
    updatingItem,
    updateError: updateError?.message || null,
    deleteItem,
    deleteError: deleteError?.message || null,
  };
}

export default useUpdateBeverageGraphQL;
