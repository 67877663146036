import React, {Fragment, FunctionComponent} from 'react';
import {useNavigate} from 'react-router';
import {Typography} from '@mui/material';

import {GridCardProps} from '../common/CardGrid';
import GridCard from '../common/GridCard';
import {Producer} from '../../models';


type Props = GridCardProps<Producer> & {
};


const ProducerCard: FunctionComponent<Props> = props => {

  const {item, name='Producer'} = props;
  const navigate = useNavigate();

  //console.log('ProducerCard producer', item);

  if (item == null) {
    return null;
  }

  const handleClick = () => {
    navigate(`/${name.toLowerCase()}/${item.producerId}`);
  };

  const logoUrl = item?.logoUrls && item.logoUrls['400px'];

  const CardContent = (
    <Fragment>
      <Typography gutterBottom variant='h5'>
        {item.name}
      </Typography>
      <Typography variant='body2' color='textSecondary'>
        {item.locations?.map(l => l.cityState).join('; ')}
      </Typography>
    </Fragment>
  );

  return (
    <GridCard
      title={item.name}
      logoUrl={logoUrl}
      cardContent={CardContent}
      onClick={handleClick}
    />
  );
};

export default ProducerCard;
