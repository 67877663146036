import {useState} from 'react';

import {useQuery} from '@apollo/client';
import listQuery from '../queries/listBeverageCategoriesQuery.graphql';
import {ListBeverageCategories} from '../types/ListBeverageCategories';

import {BeverageCategory} from '../../models';

const Item = BeverageCategory;
type ListItems = ListBeverageCategories;
type Item = BeverageCategory;


const useListBeverageCategoriesGraphQL = () => {

  const [items, setItems] = useState<Item[]>([]);

  const {loading, error} =
    useQuery<ListItems>(listQuery, {
      onCompleted: data => {
        if (data.beverageCategories != null) {
          setItems(data.beverageCategories.map(c => Item.fromGraphQL(c)));
        }
      }
    });

  return {
    items,
    gettingItems: loading,
    listError: error?.message || null,
  };
}

export default useListBeverageCategoriesGraphQL;
