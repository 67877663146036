import {useEffect, useState} from 'react';

import {useQuery} from '@apollo/client';
import {ListGraphQLProps} from './graphQLHookTypes';
import listPlaceBeveragesQuery from '../queries/listPlaceBeveragesQuery.graphql';
import {ListPlaceBeverages, ListPlaceBeveragesVariables} from '../types/ListPlaceBeverages';

import {Beverage, Place, PlaceBeverage} from '../../models';

const listQuery = listPlaceBeveragesQuery;
const Item = PlaceBeverage;
type ListItems = ListPlaceBeverages;
type ListVariables = ListPlaceBeveragesVariables;
type Item = PlaceBeverage;


export const createListPlaceBeveragesVariables = (
  place?: Place|null,
  beverage?: Beverage|null,
  filterString?: string,
  radius?: number,
  longitude?: number,
  latitude?: number,
  limit?: number,
  offset?: number,
) => {
  return {
    ...(place && {place: {placeId: place.placeId}}),
    ...(beverage && {beverage: {beverageId: beverage.placeId}}),
    filterString: filterString,
    radius: radius == null ? undefined : radius * 1609.34,
    longitude: longitude,
    latitude: latitude,
    limit: limit,
    offset: offset,
  }
}


export type ListPlaceBeveragesProps = ListGraphQLProps & {
  place?: Place|null,
  beverage?: Beverage|null,
  filterString?: string,
  radius?: number,
  longitude?: number,
  latitude?: number,
  limit?: number,
  offset?: number,
  skip?: boolean,
}

const useListPlaceBeveragesGraphQL = (props?: ListPlaceBeveragesProps) => {

  const {
    place, beverage,
    radius, longitude, latitude,
    skip=false, filterString, limit, offset} = {...props};

  const [items, setItems] = useState<Item[]>([]);

  const {loading, error, data} =
    useQuery<ListItems, ListVariables>(listQuery, {
      variables: createListPlaceBeveragesVariables(
        place, beverage, filterString, radius, longitude, latitude, limit, offset
      ),
      skip: skip || (limit === 0),
    });

  useEffect(() => {
    if (data?.placeBeverages != null) {
      setItems(data?.placeBeverages.map(i => Item.fromGraphQL(i)));
    }
  }, [data]);

  return {
    items,
    gettingItems: loading,
    listError: error?.message || null,
  };
}

export default useListPlaceBeveragesGraphQL;
