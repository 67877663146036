import React, {FunctionComponent, ReactNode, useEffect, useState} from 'react';
import {AuthService} from '../firebase';
import {User} from '../models';


interface ContextProps {
  currentUser: User | null,
  canApprove: boolean,
  canContribute: boolean,
  setCurrentUser: ((user: User|null) => void) | null,
  confirmSignUpEmailLink: (e: string, p: string) => Promise<void>,
  sendVerificationEmail: () => Promise<void>,
  signInWithEmailAndPassword: (e: string, p: string) => Promise<void>,
  signInWithFacebook: () => Promise<void>,
  signInWithGoogle: () => Promise<void>,
  signOut: () => Promise<void>,
  signUpWithEmail: (n: string, e: string, p: string) => Promise<void>,
}

export const AuthContext = React.createContext<ContextProps>({
  currentUser: null,
  canApprove: false,
  canContribute: false,
  setCurrentUser: null,
  confirmSignUpEmailLink: async () => {},
  sendVerificationEmail: async () => {},
  signInWithEmailAndPassword: async () => {},
  signInWithFacebook: async () => {},
  signInWithGoogle: async () => {},
  signOut: async () => {},
  signUpWithEmail: async () => {},
});

interface Props {
  children?: ReactNode,
}

export const AuthProvider: FunctionComponent<Props> = ({ children }) => {
  const [auth, setAuthService] = useState<AuthService|null>(null);
  const [currentUser, setCurrentUser] = useState<User|null>(null);
  const [canApprove, setCanApprove] = useState<boolean>(false);
  const [canContribute, setCanContribute] = useState<boolean>(false);


  useEffect(() => {
    //if (auth == null) {
      console.log('Initializing AuthContext.Provider');
      setAuthService(new AuthService(setCurrentUser));
    //}
  }, []);

  useEffect(() => {
    console.log('Current user changed');
    if (currentUser == null) {
      setCanApprove(false);
      setCanContribute(false);
    } else {
      const roleNames = currentUser?.roles?.map(r => r.name) || [];
      console.log('rolenames', roleNames);
      setCanApprove(roleNames.includes('Approver'));
      setCanContribute(roleNames.includes('Contributor'));
    }
  }, [currentUser]);

  const checkForAuth = () => {
    if (auth == null) {
      console.error('AuthContext auth is null');
      throw new Error('Not connected to authentication service');
    }
  };

  const confirmSignUpEmailLink = async (email: string, password: string) => {
    checkForAuth();
    await auth!.confirmSignUpEmailLink(email, password);
  };

  const sendVerificationEmail = async () => {
    checkForAuth();
    const fbUser = auth?.fbUser;
    fbUser && (await auth!.sendVerificationEmail(fbUser));
  };

  const signInWithEmailAndPassword = async (email: string, password: string) => {
    checkForAuth();
    await auth!.signInWithEmailAndPassword(email, password);
  };

  const signInWithFacebook = async () => {
    checkForAuth();
    await auth!.signInWithFacebook();
  };

  const signInWithGoogle = async () => {
    checkForAuth();
    await auth!.signInWithGoogle();
  };

  const signOut = async () => {
    checkForAuth();
    await auth!.signOut();
    //setAuthService(null);
  };

  const signUpWithEmail = async (name: string, email: string, password: string) => {
    checkForAuth();
    await auth!.signUpWithEmail(name, email, password);
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser, setCurrentUser,
        canApprove, canContribute,
        confirmSignUpEmailLink,
        sendVerificationEmail,
        signInWithEmailAndPassword,
        signInWithFacebook,
        signInWithGoogle,
        signOut,
        signUpWithEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
