import {useState} from 'react';

import {useQuery} from '@apollo/client';
import listQuery from '../queries/listPlaceTypesQuery.graphql';
import {ListPlaceTypes} from '../types/ListPlaceTypes';

import {PlaceType} from '../../models';

const Item = PlaceType;
type ListItems = ListPlaceTypes;
type Item = PlaceType;


const useListPlaceTypesGraphQL = () => {

  const [items, setItems] = useState<Item[]>([]);

  const {loading, error} =
    useQuery<ListItems>(listQuery, {
      //fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        if (data.placeTypes != null) {
          setItems(data.placeTypes.map(r => Item.fromGraphQL(r)));
        }
      }
    });

  return {
    items,
    gettingItems: loading,
    listError: error?.message || null,
  };
}

export default useListPlaceTypesGraphQL;
