import {BeverageCore} from './Beverage';
import {OfferedIn} from './OfferedIn';
import {PlaceCore} from './Place';
import {VersionedItem} from './VersionedItem';
import {PlaceBeverageDetails} from '../graphql/types/PlaceBeverageDetails';
import {PlaceBeverageInput} from '../graphql/types/globalTypes';

export type OfferedInMap = {[key: string]: OfferedIn};

export class PlaceBeverage extends VersionedItem {

  placeBeverageId?: string;
  place?: PlaceCore;
  beverage?: BeverageCore;
  status?: string;
  offeredIn?: OfferedInMap;
  distance?: number;

  get currentId() {
    return this.placeBeverageId;
  }

  /*
  constructor() {
    super();
    const newId = createUniqueId();
    const newOfferedIn = new OfferedIn();
    newOfferedIn.id = newId;
    this.offeredIn = {[newId]: newOfferedIn};
  }
  */

  setFromGraphQL(data: Partial<PlaceBeverageDetails>|null) {
    if (data == null) return;

    super.setFromGraphQL(data);
    this.placeBeverageId = data.placeBeverageId == null ? undefined : data.placeBeverageId;
    this.place = data.place == null
      ? undefined
      : PlaceCore.fromGraphQL(data.place);
    this.beverage = data.beverage == null
      ? undefined
      : BeverageCore.fromGraphQL(data.beverage);
    if (data.offeredIn != null) {
      this.offeredIn = Object.fromEntries(
        data.offeredIn.map(offeredIn => {
          const offeredInObject = OfferedIn.fromGraphQL(offeredIn);
          return [offeredIn?.id, offeredInObject];
        })
      );
    }
    this.status = data.status == null ? undefined : data.status;
    this.distance = data.distance == null ? undefined : data.distance/1609.34;
  }

  toInput(allProps=false): PlaceBeverageInput {
    const input = super.toInput(allProps);
    if (allProps) {
      return {
        ...input,
        placeBeverageId: this.placeBeverageId,
        place: this.place?.toInput(),
        beverage: this.beverage?.toInput(),
        status: this.status,
        offeredIn: this.offeredIn && Object.values(this.offeredIn).map(o => o.toInput(true)),
      };
    } else {
      return {
        ...input,
        placeBeverageId: this.placeBeverageId,
      };
    }
  }

  validate() {
    if (!super.validate()) {
      return false;
    }
    if (this.beverage == null) {
      return false;
    }
    return this.place != null;
  }
}
