import React, { Fragment, useContext } from 'react';

import { ItemEditProps } from '../common/ItemEdit';
import SingleSelect from '../common/SingleSelect';
import NumberEdit from '../common/NumberEdit';
import { PicklistsContext } from '../../contexts';
import { FormErrors, InputEvent, SelectEvent } from '../../helpers/useFormValidation';
import { Seltzer, ProducerCore } from '../../models';
import { BeverageEdit } from '../Beverage';


type Props = ItemEditProps<Seltzer> & {
  beverage?: Seltzer | null,
  producer?: ProducerCore | null,
}

const SeltzerEdit = (props: Props) => {

  const {
    beverage, producer,
    ...itemEditProps
  } = props;

  console.log('SeltzerEdit', beverage);

  const {availabilities} = useContext(PicklistsContext);

  const additionalFields = (
    originalItem: Seltzer|null,
    formItem: Seltzer|null,
    formErrors: FormErrors<Seltzer>,
    handleBlur: ()=> void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => {

    return (
      <Fragment>
        <NumberEdit
          error={formErrors.abv}
          label='ABV'
          name='abv'
          onBlur={handleBlur}
          onChange={abv => updateProperty('abv', abv)}
          value={formItem?.abv}
        />
        <div>
          <SingleSelect
            label='Availability'
            name='availability'
            items={availabilities}
            value={formItem?.availability}
            onChange={(val) => updateProperty('availability', val)}
          />
        </div>
      </Fragment>
    );
  };

  return (
    <div>
      <BeverageEdit
        beverage={beverage}
        model={Seltzer}
        typeName={'Seltzer'}
        producerTypeName={'Seltzery'}
        additionalFieldRenderer={additionalFields}
        {...itemEditProps}
      />
    </div>
  )
};

export default SeltzerEdit;
