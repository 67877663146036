import React, { Fragment, FunctionComponent } from 'react';

import LabeledText from '../common/LabeledText';
import { Beer } from '../../models';
import { BeverageView } from '../Beverage';
import BeerEditDialog from './BeerEditDialog';


interface Props {
  beer?: Beer | null,
  id?: string|null,
}

const BeerView: FunctionComponent<Props> = props => {

  const {beer, id} = props;

  const additionalFields = (item: Beer|null) => {
    return (
      <Fragment>
        <LabeledText
          label='ABV'
          text={item?.abv != null ? item?.abv.toLocaleString() : ''}
        />
        <LabeledText
          label='IBU'
          text={item?.ibu != null ? item?.ibu.toLocaleString() : ''}
        />
        <LabeledText
          label='BA Style'
          text={item?.brewersAssociationStyle?.name}
          toolTipText='Brewers Association Style'
        />
        <LabeledText
          label='Availability'
          text={item?.availability}
        />
        <LabeledText
          label='Hops'
          text={item?.hops && item?.hops.map(hop => hop.name).join(', ')}
        />
        <LabeledText
          label='Malts'
          text={item?.malts && item?.malts.map(malt => malt.name).join(', ')}
        />
        <LabeledText
          label='Yeasts'
          text={item?.yeasts && item?.yeasts.map(yeast => yeast.name).join(', ')}
        />
        <LabeledText
          label='Adjuncts'
          text={item?.adjuncts && item?.adjuncts.map(adjunct => adjunct.name).join(', ')}
        />
      </Fragment>
    );
  };

  return (
    <BeverageView
      beverage={beer}
      id={id}
      model={Beer}
      additionalFieldRenderer={additionalFields}
      editDialog={<BeerEditDialog/>}
    />
  )
};

export default BeerView;
