import React, { ElementType, useContext, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  List, ListItem, ListItemButton, ListItemText,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Edit } from '@mui/icons-material';

import { AuthContext } from '../../contexts';
import { BUTTON_VARIANT } from '../common/ItemView';
import useListIngredientsGraphQL from '../../graphql/hooks/useListIngredientsGraphQL';
import { Ingredient } from '../../models';
import { IngredientEdit, IngredientView } from './index';


type Props = {
  model?: typeof Ingredient,
  typeName?: string,
  title?: string,
  ViewComponent?: ElementType,
  EditComponent?: ElementType,
}

const IngredientsList = (props: Props) => {
  //console.log('Refreshing IngredientsList');

  const {
    model=Ingredient, typeName='Ingredient', title='Ingredients',
    ViewComponent=IngredientView, EditComponent=IngredientEdit,
  } = props;

  const theme = useTheme();
  const styles = {
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    list: {
      width: '100%',
      maxHeight: '60vh',
      maxWidth: '360px',
      overflow: 'auto',
    },
    title: {
      marginTop: theme.spacing(3)
    },
  };

  const [id, setId] = useState<string|null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number|null>(null);

  const {items} = useListIngredientsGraphQL({ingredientTypeName: typeName});

  const {canContribute} = useContext(AuthContext);

  const handleAdd = () => {
    setId(null);
    setSelectedIndex(null);
    setIsEditing(true);
  };

  const handleDoneEditing = () => {
    setIsEditing(false);
    setSelectedIndex(null);
  };

  const handleEdit = (item: Ingredient) => {
    if (item.currentId != null) {
      setId(item.currentId);
      setIsEditing(true);
    }
  };

  const handleSelect = (item: Ingredient, index: number) => {
    setSelectedIndex(index);
    setIsEditing(false);
    item.currentId && setId(item.currentId);
  };

  const renderComponent = () => {
    if (isEditing) {
      return (
        <EditComponent
          id={id}
          onComplete={handleDoneEditing}
          onDelete={handleDoneEditing}
          ingredients={items}
          model={model}
          typeName={typeName}
        />
      );
    } else {
      return (
        <ViewComponent id={id}/>
      );
    }
  };

  return (
    <Grid container sx={styles.root}>
      <Grid item xs={6}>
        <Typography sx={styles.title} variant='h2'>
          {title}
        </Typography>
        {canContribute &&
          <Button
            size='small'
            color='primary'
            variant={BUTTON_VARIANT}
            onClick={handleAdd}
          >
            Add A New {typeName}
          </Button>
        }
        <List sx={styles.list} dense>
          {items?.map((item, i) => (
            <ListItem
              key={i}
              divider
              secondaryAction={
                canContribute &&
                  <IconButton
                    edge='end'
                    aria-label='edit'
                    onClick={() => handleEdit(item)}
                    size="large">
                    <Edit/>
                  </IconButton>
              }
            >
              <ListItemButton
                selected={selectedIndex === i}
                onClick={() => handleSelect(item, i)}
              >
                <ListItemText
                  primary={item.name}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={6}>
        {renderComponent()}
      </Grid>
    </Grid>
  );
};

export default IngredientsList;
