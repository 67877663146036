import React from 'react';
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { BaseModel } from '../../models';


type DuplicatesDialogProps<S extends BaseModel> = {
  onClose: () => void,
  onDuplicate: () => void,
  open: boolean,
  items: S[] | null,
  message: string,
  itemDescription: (item: S) => string,
}

const DuplicatesDialog = <S extends BaseModel>(props: DuplicatesDialogProps<S>) => {

  const {onClose, onDuplicate, open, items, message, itemDescription} = props;

  return (
      <Dialog
        open={open}
        onClose={() => onClose()}
        aria-labelledby='duplicates-dialog-title'
        aria-describedby='duplicates-dialog-description'
      >
        <DialogTitle id='duplicates-dialog-title'>
          Possible Duplicate
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='duplicates-dialog-description'>
            {message}
          </DialogContentText>
          {items?.map(item =>
            <DialogContentText id={item.id} key={item.id}>
              {itemDescription(item)}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color='primary'>
            Not a Duplicate
          </Button>
          <Button onClick={() => onDuplicate()} color='primary'>
            Might Be a Duplicate
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default DuplicatesDialog;