import React, { Fragment, useContext } from 'react';

import { ItemEditProps } from '../common/ItemEdit';
import MultiSelect from '../common/MultiSelect';
import SingleSelect from '../common/SingleSelect';
import NumberEdit from '../common/NumberEdit';
import { PicklistsContext } from '../../contexts';
import { FormErrors, InputEvent, SelectEvent } from '../../helpers/useFormValidation';
import { Beer, ProducerCore } from '../../models';
import { BeverageEdit } from '../Beverage';


type Props = ItemEditProps<Beer> & {
  beverage?: Beer | null,
  producer?: ProducerCore | null,
}

const BeerEdit = (props: Props) => {

  const {
    beverage, producer,
    ...itemEditProps
  } = props;

  console.log('BeerEdit', beverage);

  const {adjuncts, hops, malts, yeasts, availabilities} = useContext(PicklistsContext);

  const additionalFields = (
    originalItem: Beer|null,
    formItem: Beer|null,
    formErrors: FormErrors<Beer>,
    handleBlur: ()=> void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => {

    return (
      <Fragment>
        {/**
        <SingleSelect
          label='BA Style'
          name='brewersAssociationStyle'
          tooltip='Brewers Association style'
          items={itemLists.brewersAssociationStyles}
          onChange={(val) => updateProperty('brewersAssociationStyle', val)}
          value={formItem?.brewersAssociationStyle}
        />
        **/}
        <NumberEdit
          error={formErrors.abv}
          label='ABV'
          name='abv'
          onBlur={handleBlur}
          onChange={abv => updateProperty('abv', abv)}
          value={formItem?.abv}
        />
        <NumberEdit
          error={formErrors.ibu}
          label='IBU'
          name='ibu'
          onBlur={handleBlur}
          onChange={ibu => updateProperty('ibu', ibu)}
          value={formItem?.ibu}
        />
        <div>
          <SingleSelect
            label='Availability'
            name='availability'
            items={availabilities}
            value={formItem?.availability}
            onChange={(val) => updateProperty('availability', val)}
          />
        </div>
        <div>
          <MultiSelect
            label='Hops'
            name='hops'
            items={hops}
            values={formItem?.hops}
            onChange={(vals) => updateProperty('hops', vals)}
          />
        </div>
        <div>
          <MultiSelect
            label='Malts'
            name='malts'
            items={malts}
            values={formItem?.malts}
            onChange={(vals) => updateProperty('malts', vals)}
          />
        </div>
        <div>
          <MultiSelect
            label='Yeasts'
            name='yeasts'
            items={yeasts}
            values={formItem?.yeasts}
            onChange={(vals) => updateProperty('yeasts', vals)}
          />
        </div>
        <div>
          <MultiSelect
            label='Adjuncts'
            name='adjuncts'
            items={adjuncts}
            values={formItem?.adjuncts}
            onChange={(vals) => updateProperty('adjuncts', vals)}
          />
        </div>
      </Fragment>
    );
  };

  return (
    <div>
      <BeverageEdit
        beverage={beverage}
        model={Beer}
        typeName={'Beer'}
        producerTypeName={'Brewery'}
        additionalFieldRenderer={additionalFields}
        {...itemEditProps}
      />
    </div>
  )
};

export default BeerEdit;
