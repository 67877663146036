import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, SxProps } from '@mui/material/styles';
import {
  Box,
  Card, CardActionArea, CardContent, CardMedia,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { People, Storage, Web } from '@mui/icons-material';

import placeImage from '../images/bar_blue_800x400.jpg';
import placeBrowseImage from '../images/bar_dark_800x400.jpg';
import beerImage from '../images/beer_flight_800x400.jpg';
import beerBrowseImage from '../images/beer_bottles_800x400.jpg';
import breweryBrowseImage from '../images/brewery_800x400.jpg';


interface Props {
  sx?: SxProps,
}

const HomeView: FunctionComponent<Props> = props => {

  const { sx } = props;

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(4),
    },
    title: {
      marginTop: theme.spacing(3)
    },
    body: {
      marginTop: theme.spacing(2)
    },
    h2: {
      marginTop: theme.spacing(5)
    },
    h3: {
      marginTop: theme.spacing(2)
    },
    h4: {
      marginTop: theme.spacing(2)
    },
    moreButton: {
      marginLeft: theme.spacing(1),
    },
    section: {
      marginBottom: 40,
    },
    sectionTitle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    card: {
      maxWidth: 400,
      height: '100%',
      margin: 'auto',
      //paddingBottom: 40,
    },
    cardMedia: {
      height: 200,
    },
    divider: {
      margin: 40,
    },
    benefit: {
      width: 200,
      height: 200,
      textAlign: 'center',
      margin: 'auto',
    },
    benefitIcon: {
      color: theme.palette.primary.main,
      fontSize: 50,
    },
  };

  const navigate = useNavigate();

  return (
    <Box sx={styles.root}>

      <Box sx={styles.section}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Card sx={styles.card}>
              <CardActionArea
                onClick={() => navigate('/places')}
              >
                <CardMedia
                  sx={styles.cardMedia}
                  image={placeImage}
                  title='Photo by Benjamin Zanatta on Unsplash'
                />
                <CardContent>
                  <Typography variant='h3'>
                    Find a Nearby Place
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card sx={styles.card}>
              <CardActionArea
                onClick={() => navigate('/place-beverages')}
              >
                <CardMedia
                  sx={styles.cardMedia}
                  image={beerImage}
                  title='Photo by Sal Gh on Unsplash'
                />
                <CardContent>
                  <Typography variant='h3'>
                    Find a Nearby Beverage
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card sx={styles.card}>
              <CardActionArea
                onClick={() => navigate('/producers')}
              >
                <CardMedia
                  sx={styles.cardMedia}
                  image={breweryBrowseImage}
                  title='Photo by Evan Dvorkin on Unsplash'
                />
                <CardContent>
                  <Typography variant='h3'>
                    Browse Producers
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card sx={styles.card}>
              <CardActionArea
                onClick={() => navigate('/beverages')}
              >
                <CardMedia
                  sx={styles.cardMedia}
                  image={beerBrowseImage}
                  title='Photo by Alban Martel on Unsplash'
                />
                <CardContent>
                  <Typography variant='h3'>
                    Browse Beverages
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card sx={styles.card}>
              <CardActionArea
                onClick={() => navigate('/places')}
              >
                <CardMedia
                  sx={styles.cardMedia}
                  image={placeBrowseImage}
                  title='Photo by Patrick Tomasso on Unsplash'
                />
                <CardContent>
                  <Typography variant='h3'>
                    Browse Places
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

        </Grid>
      </Box>

      <Box sx={styles.section}>

        <Divider sx={styles.divider}/>
      </Box>

      <Box sx={styles.section}>
        <Typography variant='h3' align='center' color='primary'>
          Why BevDb?
        </Typography>

      </Box>

      <Box sx={styles.section}>
        <Grid container>
          <Grid item xs={4}>
            <Box sx={styles.benefit}>
              <People sx={styles.benefitIcon}/>
              <Typography variant='h4'>
                Crowdsourced Data Collection
              </Typography>
              <Typography variant='body2'>
                More eyes means more comprehensive data
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box sx={styles.benefit}>
              <Storage sx={styles.benefitIcon}/>
              <Typography variant='h4'>
                Open Data
              </Typography>
              <Typography variant='body2'>
                Data entered into BevDb by the community is made available to community without restrictions above those imposed by the content owners
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box sx={styles.benefit}>
              <Web sx={styles.benefitIcon}/>
              <Typography variant='h4'>
                Power Your App
              </Typography>
              <Typography variant='body2'>
                Data downloaded from BevDb can be used to power applications, websites, or even other databases
              </Typography>
            </Box>
          </Grid>

        </Grid>
      </Box>
      {/**
      <Box sx={styles.section}>
        <Box sx={styles.sectionTitle}>
          <Typography variant='h3'>
            Places
          </Typography>
          <Button
            sx={styles.moreButton}
            onClick={() => history.push('/places')}
            variant='outlined'
          >
            See More
          </Button>
        </Box>
        <PlacesCardGrid numRows={1} hideHeader hideMore/>
      </Box>
      <Box sx={styles.section}>
        <Box sx={styles.sectionTitle}>
          <Typography variant='h3'>
            Beers
          </Typography>
          <Button
            sx={styles.moreButton}
            onClick={() => history.push('/beers')}
            variant='outlined'
          >
            See More
          </Button>
        </Box>
        <BeersCardGrid numRows={1} hideHeader hideMore/>
      </Box>
      <Box sx={styles.section}>
        <Box sx={styles.sectionTitle}>
          <Typography variant='h3'>
            Breweries
          </Typography>
          <Button
            sx={styles.moreButton}
            onClick={() => history.push('/breweries')}
            variant='outlined'
          >
            See More
          </Button>
        </Box>
        <BreweriesCardGrid numRows={1} hideHeader hideMore/>
      </Box>
      **/}
    </Box>
  );
};

export default HomeView;