import React, {FunctionComponent, ReactNode} from 'react';
import {PicklistItem} from '../models';
import useListBeverageCategoriesGraphQL
  from '../graphql/hooks/useListBeverageCategoriesGraphQL';
import useListIngredientsGraphQL
  from '../graphql/hooks/useListIngredientsGraphQL';
import useGetPicklistGraphQL from '../graphql/hooks/useGetPicklistGraphQL';
import useListBeverageTypesGraphQL
  from '../graphql/hooks/useListBeverageTypesGraphQL';


interface ContextProps {
  adjuncts: PicklistItem[],
  availabilities: string[],
  beerTypes: PicklistItem[],
  beverageCategories: PicklistItem[],
  brewersAssociationStyles: PicklistItem[],
  countries: string[],
  hops: PicklistItem[],
  malts: PicklistItem[],
  offeredInBottleOptions: string[],
  offeredInCanOptions: string[],
  offeredInDraftOptions: string[],
  offeredInServedIn: string[],
  offeredInSources: string[],
  offeredInUnits: string[],
  services: string[],
  yeasts: PicklistItem[],
}

export const PicklistsContext = React.createContext<ContextProps>({
  adjuncts: [],
  availabilities: [],
  beerTypes: [],
  brewersAssociationStyles: [],
  beverageCategories: [],
  countries: [],
  hops: [],
  malts: [],
  offeredInBottleOptions: [],
  offeredInCanOptions: [],
  offeredInDraftOptions: [],
  offeredInServedIn: [],
  offeredInSources: [],
  offeredInUnits: [],
  services: [],
  yeasts: [],
});


interface Props {
  children?: ReactNode,
}

export const PicklistsProvider: FunctionComponent<Props> = ({ children }) => {

  const {items: beverageCategories} = useListBeverageCategoriesGraphQL();

  const {items: beerTypes} = useListBeverageTypesGraphQL({
    categoryName: 'Beer'});

  const {items: adjuncts} = useListIngredientsGraphQL({
    ingredientTypeName: 'Adjunct',
    sortByAttributeName: 'picklistName',
  });
  const {items: hops} = useListIngredientsGraphQL({
    ingredientTypeName: 'Hop',
    sortByAttributeName: 'picklistName',
  });
  const {items: malts} = useListIngredientsGraphQL({
    ingredientTypeName: 'Malt',
    sortByAttributeName: 'picklistName',
  });
  const {items: yeasts} = useListIngredientsGraphQL({
    ingredientTypeName: 'Yeast',
    sortByAttributeName: 'picklistName',
  });

  const {strings: availabilities} = useGetPicklistGraphQL({
    name: 'Availabilities'});
  const {strings: countries} = useGetPicklistGraphQL({
    name: 'Countries'});
  const {strings: offeredInSources} = useGetPicklistGraphQL({
    name: 'Offered In Sources'});
  const {strings: offeredInUnits} = useGetPicklistGraphQL({
    name: 'Offered In Units'});
  const {strings: offeredInServedIn} = useGetPicklistGraphQL({
    name: 'Offered In Served In'});
  const {strings: offeredInDraftOptions} = useGetPicklistGraphQL({
    name: 'Offered In Draft Options'});
  const {strings: offeredInBottleOptions} = useGetPicklistGraphQL({
    name: 'Offered In Bottle Options'});
  const {strings: offeredInCanOptions} = useGetPicklistGraphQL({
    name: 'Offered In Can Options'});
  const {strings: services} = useGetPicklistGraphQL({
    name: 'Services'});

  const {maps: brewersAssociationStylesList} = useGetPicklistGraphQL({
    name: 'Brewers Association Styles'});
  const brewersAssociationStyles = brewersAssociationStylesList.map(
    m => PicklistItem.fromMap(m)
  );

  return (
    <PicklistsContext.Provider
      value={{
        adjuncts: adjuncts || [],
        availabilities,
        beerTypes: beerTypes || [],
        beverageCategories: beverageCategories || [],
        brewersAssociationStyles: brewersAssociationStyles || [],
        countries: countries || [],
        hops: hops || [],
        malts: malts || [],
        offeredInBottleOptions,
        offeredInCanOptions,
        offeredInDraftOptions,
        offeredInServedIn,
        offeredInSources,
        offeredInUnits,
        services: services || [],
        yeasts: yeasts || [],
      }}
    >
      {children}
    </PicklistsContext.Provider>
  );
};
