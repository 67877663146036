import React, {ComponentType, ReactNode, useContext} from 'react';
import {Navigate} from 'react-router-dom';
import {AuthContext} from '../contexts';


interface Props {
  children: ReactNode;
  layout: ComponentType<any>;
}

const AuthRouteWithLayout = (props: Props) => {
  const {layout: Layout, children} = props;
  const {currentUser} = useContext(AuthContext);

  return (
    !!currentUser ?
      <Layout>
        {children}
      </Layout>
    :
      <Navigate to='/sign-in' />
  );
};

export default AuthRouteWithLayout;