import React from 'react';

import {NearbyBeveragesCardGrid} from '../components/Beverage';


export default function NearbyBeveragesView() {

  return (
    <div>
      <NearbyBeveragesCardGrid

      />
    </div>
  );
}
