import {Item} from './Item';

export type UserRoleInput = {
  name?: string | null;
  description?: string | null;
  __typename?: string,
}

export class UserRole extends Item {

  description?: string;

  setFromGraphQL(data: UserRoleInput|null) {
    if (data == null) return;

    super.setFromGraphQL(data);
    this.description = data.description == null ? undefined : data.description;
  }

  validate() {
    if (!super.validate()) {
      return false;
    }
    if (this.name == null || this.name === '') {
      return false;
    }
    return true;
  }
}
