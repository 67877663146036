import React, { FunctionComponent } from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';


interface Props {
  sx?: SxProps,
}

const Footer: FunctionComponent<Props> = props => {
  const { sx, ...rest } = props;

  const theme = useTheme();
  const styles = {
    root: {
      padding: theme.spacing(4)
    }
  };

  return (
    <Box
      {...rest}
      sx={[styles.root, ...(Array.isArray(sx) ? sx : [sx]),]}
    >
      <Grid container>
        <Grid item xs={4}>
          <Typography variant='body1'>
            &copy;{' '}
            <Link
              component='a'
              href='https://bevdb.org/'
              target='_blank'
            >
              BevDb
            </Link>
            {' '}2023
          </Typography>
          <Typography variant='caption'>
            The open beverage database.
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            color='textSecondary'
            variant='body1'
          >
            <Link
              component={RouterLink}
              to='/terms'
              replace={true}
              variant='h6'
            >
              Terms
            </Link>
          </Typography>
          <Typography
            color='textSecondary'
            variant='body1'
          >
            <Link
              component={RouterLink}
              to='/privacy'
              replace={true}
              variant='h6'
            >
              Privacy
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='body1'>
            <Link
              component='a'
              href='https://groups.google.com/a/bevdb.org/d/forum/bevdb-support'
              target='newTab'
              >
              Support
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;