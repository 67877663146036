import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';
import { Add } from '@mui/icons-material';

import { Location } from '../../models';
import LocationEdit from './LocationEdit';


interface Props {
  locations?: Location[];
  sx?: SxProps;
  onChange?: (locations: Location[]) => void;
}

const LocationsEdit = (props: Props) => {
  const {locations, sx, onChange} = props;

  const theme = useTheme();
  const styles = {
    body: {
      marginTop: theme.spacing(2)
    },
    button: {
      margin: theme.spacing(1),
    },
  };

  const [_locations, setLocations] = useState<Location[]>([]);
  useEffect(() => {
    if (locations != null) {
      setLocations(locations);
    }
  }, [locations]);

  const handleChange = (value: Location, index: number) => {
    _locations[index] = value;
    onChange && onChange(_locations);
  };

  const handleAddClick = () => {
    _locations.push(new Location());
    onChange && onChange(_locations);
  };

  return (
    <Box>
      <Typography
        sx={[styles.body, ...(Array.isArray(sx) ? sx : [sx]),]}
        variant='body1'
      >
        Locations
      </Typography>
      {_locations.map((location, i) =>
        <span key={i}>
          <LocationEdit
              location={location}
              onChange={val => handleChange(val, i)}
          />
        </span>
      )}
      <IconButton
        color='secondary'
        sx={[styles.button, ...(Array.isArray(sx) ? sx : [sx]),]}
        aria-label='add'
        onClick={handleAddClick}
        size='large'>
        <Add/>
        <Typography
          sx={[styles.body, ...(Array.isArray(sx) ? sx : [sx]),]}
          variant='body1'
        >
          Location
        </Typography>
      </IconButton>
     </Box>
  );
};

export default LocationsEdit;
