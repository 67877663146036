import React, { FunctionComponent, ReactNode } from 'react';
import { Box } from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';

import TopBar from './TopBar';

interface Props {
  sx?: SxProps,
  children?: ReactNode,
}

const Minimal: FunctionComponent<Props> = props => {
  const { children, sx } = props;

  const theme = useTheme();
  const styles = {
    root: {
      paddingTop: 4,
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 4
      }
    },
    content: {
      height: '100%'
    }
  };

  return (
    <Box sx={[styles.root, ...(Array.isArray(sx) ? sx : [sx]),]}>
      <TopBar />
      <Box component='main' sx={[styles.content, ...(Array.isArray(sx) ? sx : [sx]),]}>
        {children}
      </Box>
    </Box>
  );
};

export default Minimal;