import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ItemEdit, { ItemEditProps } from '../common/ItemEdit';
import { FormErrors, InputEvent, SelectEvent } from '../../helpers/useFormValidation';
import {
  OfferedInMap,
  PlaceBeverage,
  PlaceCore,
} from '../../models';
import OfferedInTable from './OfferedInTable';
import useUpdatePlaceBeverageGraphQL from '../../graphql/hooks/useUpdatePlaceBeverageGraphQL';


function validatePlaceBeverage(values: PlaceBeverage) {
  let errors: FormErrors<PlaceBeverage> = {};

  // Beverage Errors
  if (!values.offeredIn || Object.keys(values.offeredIn).length === 0) {
    errors.offeredIn = 'Packaging required';
  }

  return errors;
}

const INITIAL_VALUE = new PlaceBeverage();

type Props = ItemEditProps<PlaceBeverage> & {
  place: PlaceCore,
  placeBeverage?: PlaceBeverage|null,
  beverageTypeName?: string,
  setDisableSave?: (disable: boolean) => void,
}

export const PlaceBeverageEdit = (props: Props) => {

  const {
    place, placeBeverage,
    beverageTypeName,
    setDisableSave,
    ...itemEditProps
  } = props;

  console.log('PlaceBeverageEdit', placeBeverage);

  const theme = useTheme();
  const styles = {
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    beverageList: {
      maxHeight: '200px',
      overflow: 'auto',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    beverageListItem: {
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
    },
    offeredIn: {
      marginTop: '10px',
    }
  };

  useEffect(() => {
    setDisableSave && setDisableSave(true);
  }, [setDisableSave]);

  async function preSubmit(item: PlaceBeverage) {
    item.status = 'Available';
    return item;
  }

  const renderFields = (
    originalItem: PlaceBeverage|null,
    formItem: PlaceBeverage|null,
    formErrors: FormErrors<PlaceBeverage>,
    handleBlur: ()=> void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => {

    const handleOfferedInChange = async (offeredIn: OfferedInMap|null) => {
      await updateProperty('offeredIn', offeredIn);
    }

    return (
      <Box sx={styles.root}>
        <Box sx={styles.offeredIn}>
          <OfferedInTable
            offeredIn={formItem?.offeredIn}
            setDisableSave={setDisableSave}
            onChange={val => handleOfferedInChange(val)}
          />
        </Box>
      </Box>
    );
  };

  return (
    <ItemEdit
      item={placeBeverage}
      defaultValue={INITIAL_VALUE}
      useUpdateGraphQL={useUpdatePlaceBeverageGraphQL}
      useUpdateGraphQLProps={{place: place}}
      fieldRenderer={renderFields}
      validator={validatePlaceBeverage}
      preSubmit={preSubmit}
      formId='placeBeverageEdit'
      {...itemEditProps}
    />
  )
};

export default PlaceBeverageEdit;
