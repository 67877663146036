import React, { FunctionComponent } from 'react';
import {
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {SignIn, SignUp, VerifySignIn} from '../components/SignIn';


const SignInView: FunctionComponent = () => {

  const location = useLocation();
  const navigate = useNavigate();

  console.log('location', location);

  const theme = useTheme();
  const styles = {
    root: {
      backgroundColor: theme.palette.white,
      height: '100%'
    },
    grid: {
      height: '100%'
    },
    quoteContainer: {
      [theme.breakpoints.down('xl')]: {
        display: 'none'
      },
    },
    quote: {
      backgroundColor: theme.palette.text.primary,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: 'url(/images/auth.jpg)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    },
    quoteInner: {
      textAlign: 'center',
      flexBasis: '600px'
    },
    quoteText: {
      color: theme.palette.white,
      fontWeight: 300
    },
    name: {
      marginTop: theme.spacing(3),
      color: theme.palette.white
    },
    bio: {
      color: theme.palette.white
    },
    contentContainer: {},
    content: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      marginTop: 2,
    },
    contentHeader: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    logoImage: {
      marginLeft: theme.spacing(4)
    },
    contentBody: {
      flexGrow: 1,
      flex: 5,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xl')]: {
        justifyContent: 'center'
      }
    },
  };

  const handleBack = () => {
    navigate(-1);
  };

  const goBack = () => {
    if (navigate.length <= 1) {
      navigate('/home', {replace: true});
    } else {
      navigate(-1);
    }
  };

  const createSignInComponent = () => {
    switch (location.pathname) {
      case '/sign-up':
        return <SignUp onComplete={goBack}/>;
      case '/verify-sign-in':
        return <VerifySignIn onComplete={goBack}/>;
      default:
        return <SignIn onComplete={goBack}/>;
    }
  };

  return (
    <Box sx={styles.root}>
        <Box sx={styles.content}>
          <Box sx={styles.contentHeader}>
            <IconButton onClick={handleBack} size='large'>
              <ArrowBackIcon />
            </IconButton>
          </Box>
          <Box sx={styles.contentBody}>
            {createSignInComponent()}
          </Box>
        </Box>
    </Box>
  );
};

export default SignInView;
