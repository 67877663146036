import {PureQueryOptions, useMutation} from '@apollo/client';
import listPlaceBeveragesQuery from '../queries/listPlaceBeveragesQuery.graphql';
import updateMutation from '../queries/updatePlaceBeverageMutation.graphql';
import {UpdatePlaceBeverage, UpdatePlaceBeverageVariables} from '../types/UpdatePlaceBeverage';
import deleteMutation from '../queries/deletePlaceBeverageMutation.graphql';
import {DeletePlaceBeverage, DeletePlaceBeverageVariables} from '../types/DeletePlaceBeverage';
import {Place, PlaceBeverage} from '../../models';
import {createListPlaceBeveragesVariables} from './useListPlaceBeveragesGraphQL';


type Item = PlaceBeverage;
type UpdateItem = UpdatePlaceBeverage;
type UpdateVariables = UpdatePlaceBeverageVariables;
type DeleteItem = DeletePlaceBeverage;
type DeleteVariables = DeletePlaceBeverageVariables;


type Props = {
  currentId?: string|null,
  name?: string,
  typeName?: string,
  model?: { new (): Item; },
  place?: Place|null,
}

const useUpdatePlaceBeverageGraphQL = (props?: Props) => {

  const {
    place,
  } = {...props};

  const [_updateItem, {loading: updatingItem, error: updateError}] =
    useMutation<UpdateItem, UpdateVariables>(updateMutation, {
      /*
      update(cache, {data}) {
        const updatedPlaceBeverage = data?.updatePlaceBeverage;
        console.log('update: updatedPlaceBeverage', updatedPlaceBeverage);
        if (updatedPlaceBeverage != null) {
          const variables = createListPlaceBeveragesVariables(place);
          const {placeBeverages} = cache.readQuery<ListPlaceBeverages>({
            query: listPlaceBeveragesQuery,
            variables: variables,
          }) || {};
          console.log('update: placeBeverages', placeBeverages);
          if (placeBeverages == null) {
            return;
          }
          const updatedPlaceBeverages = placeBeverages.map(pb =>
            pb?.placeBeverageId === updatedPlaceBeverage.placeBeverageId ? updatedPlaceBeverage : {...pb}
          );
          console.log('update: updatedPlaceBeverages', updatedPlaceBeverages);
          cache.writeQuery({
            query: listPlaceBeveragesQuery,
            variables: variables,
            data: updatedPlaceBeverages,
          });
        }
      },
      */
      refetchQueries: () => {
        const queries: PureQueryOptions[] = [{
          query: listPlaceBeveragesQuery,
          variables: createListPlaceBeveragesVariables(place),
        }];
        return queries;
      },
  });

  const [_deleteItem, {error: deleteError}] =
    useMutation<DeleteItem, DeleteVariables>(deleteMutation, {
      refetchQueries: () => [{
        query: listPlaceBeveragesQuery,
        variables: createListPlaceBeveragesVariables(place),
      }],
  });

  const updateItem = async (updatedItem: Item) => {
    if (updatedItem == null || _updateItem == null) {
      return;
    }
    const input = updatedItem.toInput(true);
    await _updateItem({
      variables: {placeBeverage: input},
    });
  }

  const deleteItem = async (deletedItem: Item, deleteReason?: string) => {
    if (deletedItem == null || _deleteItem == null) {
      return;
    }
    const input = deletedItem.toInput(true);
    await _deleteItem({
      variables: {placeBeverage: input, deleteReason: deleteReason},
    });
  }

  return {
    item: null,
    gettingItem: false,
    getError: null,
    updateItem,
    updatingItem,
    updateError: updateError?.message || null,
    deleteItem,
    deleteError: deleteError?.message || null,
  };
}

export default useUpdatePlaceBeverageGraphQL;
