import React from 'react';

import {BeveragesCardGrid} from '../components/Beverage';


export default function WinesView() {

  return (
    <div>
      <BeveragesCardGrid
        categoryName='Wine'
      />
    </div>
  );
}
