import React from 'react';
import { Beverage, ProducerCore } from '../../models';
import BeverageEdit from './BeverageEdit';
import ItemEditDialog from '../common/ItemEditDialog';

type Props = {
  beverage?: Beverage | null,
  model?: typeof Beverage,
  typeName?: string,
  producer?: ProducerCore | null,
  onClose?: (value: string) => void,
  onDelete?: () => Promise<void>,
  open?: boolean,
}


const BeverageEditDialog = (props: Props) => {

  const {
    beverage, producer, model=Beverage, typeName='Beverage',
    onClose, onDelete, open=false
  } = props;

  return (
    <ItemEditDialog
      onClose={onClose}
      open={open}
      title={`Edit ${typeName}`}
    >
      <BeverageEdit
        beverage={beverage}
        model={model}
        typeName={typeName}
        producer={producer}
        onDelete={onDelete}
      />
    </ItemEditDialog>
  );
};

export default BeverageEditDialog;
