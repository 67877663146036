import React from 'react';

import { Beer, ProducerCore } from '../../models';
import BeerEdit from './BeerEdit';
import ItemEditDialog from '../common/ItemEditDialog';


type Props = {
  beverage?: Beer | null,
  producer?: ProducerCore | null,
  onClose?: (value: string) => void,
  onDelete?: () => Promise<void>,
  open?: boolean,
}


const BeerEditDialog = (props: Props) => {

  const { beverage, producer, onClose, onDelete, open=false } = props;

  console.log('Beer Edit Dialog', beverage);
  return (
    <ItemEditDialog
      onClose={onClose}
      open={open}
      title='Edit Beer'
    >
      <BeerEdit
        beverage={beverage}
        producer={producer}
        onDelete={onDelete}
      />
    </ItemEditDialog>
  );
};

export default BeerEditDialog;
