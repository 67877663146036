import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import {
  Add as AddIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Check as SaveIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  DataGrid,
  GridColTypeDef,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes, GridRowModesModel,
  GridRowParams,
  GridToolbarContainer,
  MuiEvent,
} from '@mui/x-data-grid';


import { AuthContext, PicklistsContext } from '../../contexts';
import { createUniqueId } from '../../helpers/createUniqueId';
import { OfferedIn, OfferedInMap } from '../../models';


const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const usdPrice: GridColTypeDef = {
  type: 'number',
  valueFormatter: ({ value }) => currencyFormatter.format(value),
};

interface EditToolbarProps {
  isEditable: boolean;
  onAdd: () => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { isEditable, onAdd } = props;

  const handleClick = () => {
    onAdd();
  };

  return (
    <GridToolbarContainer>
      {isEditable
        ?
          <Button color='primary' startIcon={<AddIcon/>} onClick={handleClick}>
            Add OfferedIn
          </Button>
        :
        <div/>
      }
    </GridToolbarContainer>
  );
}


interface Props {
  offeredIn?: OfferedInMap,
  label?: string;
  labeled?: boolean,
  onChange?: (val: OfferedInMap) => void,
  setDisableSave?: (disable: boolean) => void,
}

const OfferedInTable = (props: Props) => {

  const {
    offeredIn, label='OfferedIn', labeled=false,
    onChange, setDisableSave,
  } = props;

  console.log('OfferedInTable', offeredIn);

  const [offeredInList, setOfferedInList] = useState<OfferedIn[]>([]);
  useEffect(() => {
    setOfferedInList(Object.values(offeredIn||{}))
  }, [offeredIn]);

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const {canContribute} = useContext(AuthContext);

  const [isEditable, setIsEditable] = useState<boolean>(false);
  useEffect(() => {
    setIsEditable(onChange != null && canContribute);
  }, [onChange, canContribute]);

  const theme = useTheme();

  const {
    offeredInSources, offeredInUnits, offeredInServedIn,
    offeredInBottleOptions, offeredInCanOptions, offeredInDraftOptions
  } = useContext(PicklistsContext);


  const handleAdd = () => {
    setDisableSave && setDisableSave(false);
    const newId = createUniqueId();
    const newOfferedIn = new OfferedIn();
    newOfferedIn.id = newId;
    onChange && onChange({
      ...offeredIn,
      [newId]: newOfferedIn,
    });
    setRowModesModel({
      ...rowModesModel,
      [newId]: { mode: GridRowModes.Edit, fieldToFocus: 'source' },
    });
  };

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleEditSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    const editedData = offeredInList.find((row) => row.id === id)
    if (editedData == null) return;
    if (id != null) {
      let updatedOfferedIn = {...offeredIn};
      if (id in updatedOfferedIn) {
        updatedOfferedIn[id].setFromGraphQL(editedData);
      } else {
        updatedOfferedIn[id] = OfferedIn.fromMap(editedData);
      }
      setDisableSave && setDisableSave(false);
      onChange && onChange(updatedOfferedIn);
    }
  };

  const handleCancelEdit = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  }

  const handleDelete = (id: GridRowId) => () => {
    if (id) {
      const updatedOfferedIn = {...offeredIn};
      delete updatedOfferedIn[id];
      setDisableSave && setDisableSave(false);
      onChange && onChange(updatedOfferedIn);
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const id = newRow.id;
    let updatedOfferedIn = {...offeredIn};
    if (id in updatedOfferedIn) {
      updatedOfferedIn[id].setFromGraphQL(newRow);
    } else {
      updatedOfferedIn[id] = OfferedIn.fromMap(newRow);
    }
    onChange && onChange(updatedOfferedIn);
    return newRow;
  };

  const columns:  GridColDef[] = [
    {
      field: 'source', headerName: 'Source', editable: true,
      type: 'singleSelect', valueOptions: offeredInSources,
      flex: 1, minWidth: 100,
    }, {
      field: 'volume', headerName: 'Volume', editable: true,
      type: 'number',
      flex: 0.5, minWidth: 50,
    }, {
      field: 'unit', headerName: 'Unit', editable: true,
      type: 'singleSelect', valueOptions: offeredInUnits,
      flex: 0.5, minWidth: 50,
    }, {
      field: 'quantity', headerName: 'Quantity', editable: true,
      type: 'number',
      flex: 0.5, minWidth: 50,
    }, {
      field: 'price', headerName: 'Price', editable: true,
      ...usdPrice,
      flex: 0.75, minWidth: 75,
    }, {
      field: 'options', headerName: 'Options', editable: true,
      type: 'multipleSelect',
      flex: 2, minWidth: 200,
      valueOptions: params => {
        // Filter params does not include row so all options should be included
        if (params.row == null) {
          return [...new Set([
            ...offeredInBottleOptions,
            ...offeredInCanOptions,
            ...offeredInDraftOptions
          ])];
        }
        switch (params.row.source) {
          case 'Bottle':
            return offeredInBottleOptions;
          case 'Can':
            return offeredInCanOptions;
          case 'Draft':
            return offeredInDraftOptions;
          default:
            return [];
        }
      },
    }, {
      field: 'location', headerName: 'Location', editable: true,
      flex: 1, minWidth: 100,
    }, {
      field: 'servedIn', headerName: 'Served In', editable: true,
      type: 'singleSelect', valueOptions: offeredInServedIn,
      flex: 1, minWidth: 100,
    },
  ];

  if (isEditable) {
    columns.push(
      {
        field: 'actions',
        type: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label='Save'
                onClick={handleEditSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label='Cancel'
                className='textPrimary'
                onClick={handleCancelEdit(id)}
                color='inherit'
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label='Edit'
              className='textPrimary'
              onClick={handleEditClick(id)}
              color='inherit'
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label='Delete'
              onClick={handleDelete(id)}
              color='inherit'
            />,
          ];
        },
      },
    )
  }

  const createOfferedInTable = () => (
    <Box
      sx={{
        height: '500px',
        width: '100%',
      }}>
      <DataGrid
        sx={{
          actions: {
            color: 'text.secondary',
          },
          textPrimary: {
            color: 'text.primary',
          },
        }}
        //autoHeight
        columns={columns}
        rows={offeredInList}
        //initialState={{
        //  pagination: { paginationModel: { pageSize: 5 } },
        //}}
        //pageSize={5}
        autoPageSize
        editMode='row'
        rowModesModel={rowModesModel}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: {isEditable: isEditable, onAdd: handleAdd},
        }}
        //experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );

  if (labeled) {
    return (
      <Box>
        <Box sx={theme.itemView.textBlock}>
          <Typography sx={theme.itemView.label} variant='h6'>
            {label}
          </Typography>
          {createOfferedInTable()}
        </Box>
      </Box>
    );
  } else {
    return createOfferedInTable();
  }
};

export default OfferedInTable