import React, { FunctionComponent, ReactElement, useState } from 'react';
import {
  Card,
  CardActionArea, CardActions,
  CardContent,
  CardMedia, Collapse, IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {ExpandMore} from '@mui/icons-material';


export type GridCardProps = {
  actionContent?: ReactElement;
  cardContent?: ReactElement;
  editable?: boolean,
  expandedContent?: ReactElement;
  logoUrl?: string,
  onClick?: () => void;
  title?: string,
}


const GridCard: FunctionComponent<GridCardProps> =
    ({editable=false,
       ...props}) => {

  const {actionContent, cardContent, expandedContent, logoUrl, onClick, title} = props;

  //console.log('GridCard');

  const theme = useTheme();
  const styles = {
    card: {
      maxWidth: 345,
      height: '100%'
    },
    media: {
      height: 200,
      width: '100%',
      objectFit: 'contain',
      maxWidth: 300,
      backgroundSize: 'contain',
    },
    cardContent: {
      paddingBottom: 0,
    },
    actions: {
      paddingBottom: 5,
      paddingTop: 5,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    expandedContent: {
      paddingTop: 0,
    },
  };

  const [expanded, setExpanded] = useState<boolean>(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={styles.card}>
      <CardActionArea onClick={onClick}>
        <CardMedia
          sx={styles.media}
          image={logoUrl || '/images/logo512.png'}
          title={title}
        />
        <CardContent sx={styles.cardContent}>
          {cardContent}
        </CardContent>
      </CardActionArea>
      {(actionContent || expandedContent) &&
        <CardActions disableSpacing sx={styles.actions}>
          {actionContent}
          <IconButton
            sx={expanded ? styles.expandOpen : styles.expand}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='show more'
            size='large'>
            <ExpandMore />
          </IconButton>
        </CardActions>
      }
      {expandedContent &&
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent sx={styles.expandedContent}>
            {expandedContent}
          </CardContent>
        </Collapse>
      }
    </Card>
  );
};

export default GridCard;
