import {Ingredient} from './Ingredient';
import {IngredientInput} from '../graphql/types/globalTypes';
import {IngredientDetails} from '../graphql/types/IngredientDetails';


export class Hop extends Ingredient {

  alphaMin?: number;
  alphaMax?: number;
  properties?: {
    alphaMin?: number,
    alphaMax?: number,
    [key:string]: any,
  };

  setFromGraphQL(data: Partial<IngredientDetails>|null) {
    super.setFromGraphQL(data);
    const {alphaMin, alphaMax, ...rest} = {...this.properties};
    this.alphaMin = alphaMin == null ? undefined : Number(alphaMin);
    this.alphaMax = alphaMax == null ? undefined : Number(alphaMax);
    this.properties = rest;
  }

  toInput(allProps=false): IngredientInput {
    const input = super.toInput(allProps);
    if (allProps) {
      const properties = {
        ...this.properties,
        ...(this.alphaMin == null ? {} : {'alphaMin': this.alphaMin}),
        ...(this.alphaMax == null ? {} : {'alphaMax': this.alphaMax}),
      };
      input.properties = JSON.stringify(properties);
    }
    return input;
  }

}
