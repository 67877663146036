import {BeverageTypeDetails} from '../graphql/types/BeverageTypeDetails';
import {BeverageTypeInput} from '../graphql/types/globalTypes';
import {MapType} from './Base';
import {VersionedItem} from './VersionedItem';
import {BeverageCategory} from './BeverageCategory';

export class BeverageType extends VersionedItem {
  beverageTypeId?: string;
  picklistName?: string;
  description?: string;
  flavor?: string;
  aliases?: string[];
  links?: MapType;
  properties?: MapType;
  category?: BeverageCategory | null;

  get currentId() {
    return this.beverageTypeId;
  }

  setFromGraphQL(data: Partial<BeverageTypeDetails>|null) {
    if (data == null) return;

    super.setFromGraphQL(data);
    this.beverageTypeId = data.beverageTypeId == null ? undefined : data.beverageTypeId;
    this.picklistName = data.picklistName == null ? undefined : data.picklistName;
    this.description = data.description == null ? undefined : data.description;
    this.flavor = data.flavor == null ? undefined : data.flavor;
    this.aliases = data.aliases == null ? undefined : data.aliases.filter(a => a != null) as string[];
    this.links = data.links;
    this.properties = data.properties;
    this.category = data.category == null ? null : BeverageCategory.fromGraphQL(data.category);
  }

  toInput(allProps=false): BeverageTypeInput {
    const input = super.toInput(allProps);
    if (allProps) {
      return {
        ...input,
        beverageTypeId: this.beverageTypeId,
        picklistName: this.picklistName,
        description: this.description,
        flavor: this.flavor,
        aliases: this.aliases,
        links: JSON.stringify(this.links),
        properties: JSON.stringify(this.properties),
        category: this.category?.toInput(),
      };
    } else {
      return {
        ...input,
        beverageTypeId: this.beverageTypeId,
      };
    }
  }

  validate() {
    if (!super.validate()) {
      return false;
    }
    if (this.name == null || this.name === '') {
      return false;
    }
    if (this.picklistName == null || this.picklistName === '') {
      return false;
    }
    return true;
  }
}
