import {Beverage, BeverageCore} from './Beverage';
import {IngredientInput} from '../graphql/types/globalTypes';
import {BeverageDetails} from '../graphql/types/BeverageDetails';

export class SeltzerCore extends BeverageCore {

  static fromSeltzer(seltzer: Seltzer): SeltzerCore {
    return BeverageCore.fromBeverage(seltzer);
  }

}

export class Seltzer extends Beverage {

  abv?: number;
  availability?: string;
  properties?: {
    abv?: number,
    availability?: string;
    [key:string]: any,
  };

  setFromGraphQL(data: BeverageDetails) {
    super.setFromGraphQL(data);

    const {abv, availability, ...rest} = {...this.properties};
    this.abv = abv == null ? undefined : Number(abv);
    this.availability = availability == null ? undefined : availability;
    this.properties = rest;
  }

  toInput(allProps=false): IngredientInput {
    const input = super.toInput(allProps);
    if (allProps) {
      const properties = {
        ...this.properties,
        ...(this.abv == null ? {} : {'abv': this.abv}),
        ...(this.availability == null ? {} : {'availability': this.availability}),
      };
      input.properties = JSON.stringify(properties);
    }
    return input;
  }
}
