import React from 'react';
import { Box, Link, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';


interface Props {
  label?: string,
  link?: string,
  text?: string|number|null,
  toolTipText?: string,
}


const LabeledText = (props: Props) => {

  const { label, link, text, toolTipText } = props;

  const theme = useTheme();

  const renderText = () =>
      <Typography sx={theme.itemView.text} variant='body1'>
        {text}
      </Typography>

  return (
    <Box>
      <Tooltip title={toolTipText || ''}>
      <Box sx={theme.itemView.textBlock}>
        <Typography sx={theme.itemView.label} variant='h6'>
          {label}
        </Typography>
        {link
          ? <Link component={RouterLink} to={link}>
              {renderText()}
            </Link>
          : renderText()
        }
      </Box>
      </Tooltip>
    </Box>
  )
};

export default LabeledText;
