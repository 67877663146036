import React, { FunctionComponent, useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Menu, Notifications } from '@mui/icons-material';
import {
  AppBar,
  Toolbar,
  Badge,
  Button,
  Hidden,
  IconButton,
  Typography, Box
} from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';

import { AuthContext } from '../../contexts';
import Profile from './Profile';


type Props = {
  sx?: SxProps,
  onSideBarOpen?: Function,
}

const TopBar: FunctionComponent<Props> = props => {
  const { sx, onSideBarOpen } = props;

  const theme = useTheme();
  const styles = {
    root: {
      //boxShadow: 'none',
      background: theme.palette.white,
    },
    flexGrow: {
      flexGrow: 1
    },
    signInButton: {
      marginLeft: theme.spacing(1)
    },
    signOutButton: {
      marginLeft: theme.spacing(1)
    }
  };

  const navigate = useNavigate();

  const {currentUser} = useContext(AuthContext);

  const [notifications] = useState([]);

  function renderAuthenticationButton() {
    if (currentUser) {
      return (
        <Profile />
      )
    } else {
      return (
        <Button
          sx={styles.signInButton}
          //color="inherit"
          onClick={() => navigate('/sign-in')}
          variant='outlined'
        >
          Sign In To Contribute
        </Button>
      )
    }
  }

  return (
    <AppBar
      sx={[styles.root, ...(Array.isArray(sx) ? sx : [sx]),]}
    >
      <Toolbar>

        <IconButton onClick={() => onSideBarOpen && onSideBarOpen()} size='large'>
          <Menu />
        </IconButton>

        <RouterLink to='/' style={{ textDecoration: 'none' }}>
          <span>
            <Typography variant='h2' color='primary'>
              BevDb
            </Typography>
          </span>
        </RouterLink>

        <Box sx={styles.flexGrow} />

        <Hidden xlDown>
          <IconButton color='inherit' size='large'>
            <Badge
              badgeContent={notifications.length}
              color='primary'
              variant='dot'
            >
              <Notifications />
            </Badge>
          </IconButton>
        </Hidden>

        {renderAuthenticationButton()}

      </Toolbar>
    </AppBar>
  );
};

export default TopBar;