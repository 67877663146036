import React, { useState } from 'react';
import { PlaceBeverage, PlaceCore } from '../../models';
import ItemEditDialog from '../common/ItemEditDialog';
import PlaceBeverageEdit from './PlaceBeverageEdit';


type DialogProps = {
  place: PlaceCore,
  placeBeverage?: PlaceBeverage|null,
  onClose: (value: string|null) => void,
  open: boolean,
}

export const PlaceBeverageEditDialog = (props: DialogProps) => {

  const {
    place, placeBeverage,
    onClose, open
  } = props;

  const [disableSave, setDisableSave] = useState<boolean>(false);

  return (
    <ItemEditDialog
      onClose={onClose}
      open={open}
      title={`Edit ${placeBeverage?.beverage?.name || 'Beverage'}`}
      hideDelete
      disableSave={disableSave}
      maxWidth='xl'
      fullWidth={true}
    >
      <PlaceBeverageEdit
        place={place}
        placeBeverage={placeBeverage}
        setDisableSave={setDisableSave}
      />
    </ItemEditDialog>
  );
};

export default PlaceBeverageEditDialog;
