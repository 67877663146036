import {useState} from 'react';

import {useQuery} from '@apollo/client';

import getQuery from '../queries/getBeverageQuery.graphql';
import {GetBeverage, GetBeverageVariables} from '../types/GetBeverage';

import {Beverage} from '../../models';

//import {loader} from 'graphql.macro';
//const getQuery = loader('../queries/getBeverageQuery.graphql');

const Item = Beverage;
type GetItem = GetBeverage;
type GetVariables = GetBeverageVariables;
type Item = Beverage;

type Props = {
  currentId?: string|null,
  name?: string,
  model?: { new (): Item; },
}

const useGetBeverageGraphQL = (props?: Props) => {

  const {currentId, name, model=Item} = {...props};

  const [item, setItem] = useState<Item|null>(null);

  const {loading, error} = useQuery<GetItem, GetVariables>(getQuery, {
    variables: {beverage: {beverageId: currentId, name: name}},
    fetchPolicy: 'cache-and-network',
    skip: currentId == null && name == null,
    onCompleted: data => {
      if (data.beverage != null) {
        const newItem = new model();
        newItem.setFromGraphQL(data.beverage);
        setItem(newItem);
      }
    },
  });

  return {
    item,
    gettingItem: loading,
    getError: error?.message || null,
  };
}

export default useGetBeverageGraphQL;
