import { SxProps, createTheme } from '@mui/material/styles';
import type { } from '@mui/x-data-grid/themeAugmentation';

import components from './components';
import palette from './palette';
import typography from './typography';

declare module '@mui/material/styles' {
  interface Theme {
    itemEdit: {
      form: SxProps,
      title: SxProps,
      formControl: SxProps,
      textField: SxProps,
      footer: SxProps,
      errorText: SxProps,
      actions: SxProps,
      addButton: SxProps,
      cancelButton: SxProps,
      deleteButton: SxProps,
      submitButton: SxProps,
    },
    itemView: {
      root: SxProps,
      title: SxProps,
      body: SxProps,
      header: SxProps,
      info: SxProps,
      editButton: SxProps,
      leftCentered: SxProps,
      image: SxProps,
      textBlock: SxProps,
      label: SxProps,
      text: SxProps,
      error: SxProps,
    },
    info: {
      root: SxProps,
      title: SxProps,
      body: SxProps,
      error: SxProps,
      h2: SxProps,
      h3: SxProps,
      h4: SxProps,
      date: SxProps,
    },
    signIn: {
      form: SxProps,
      title: SxProps,
      socialButtons: SxProps,
      socialIcon: SxProps,
      suggestion: SxProps,
      textField: SxProps,
      signInButton: SxProps,
      errorText: SxProps,
      policy: SxProps,
      policyText: SxProps,
      policyCheckbox: SxProps,
    },
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    itemEdit?: {
      form: SxProps,
      title: SxProps,
      formControl: SxProps,
      textField: SxProps,
      footer: SxProps,
      errorText: SxProps,
      actions: SxProps,
      addButton: SxProps,
      cancelButton: SxProps,
      deleteButton: SxProps,
      submitButton: SxProps,
    },
    itemView?: {
      root: SxProps,
      title: SxProps,
      body: SxProps,
      header: SxProps,
      info: SxProps,
      editButton: SxProps,
      leftCentered: SxProps,
      image: SxProps,
      textBlock: SxProps,
      label: SxProps,
      text: SxProps,
      error: SxProps,
    },
    info?: {
      root: SxProps,
      title: SxProps,
      body: SxProps,
      error: SxProps,
      h2: SxProps,
      h3: SxProps,
      h4: SxProps,
      date: SxProps,
    },
    signIn?: {
      form: SxProps,
      title: SxProps,
      socialButtons: SxProps,
      socialIcon: SxProps,
      suggestion: SxProps,
      textField: SxProps,
      signInButton: SxProps,
      errorText: SxProps,
      policy: SxProps,
      policyText: SxProps,
      policyCheckbox: SxProps,
    },
  }
}

let theme = createTheme({

  components,
  palette,
  typography,
  breakpoints: {
    values: {
      xs: 0, // default: 0
      sm: 600, // default: 600
      md: 960, // default: 960
      lg: 1280, // default: 1280
      xl: 1500, // default: 1920
    }
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100
  },
});

theme = createTheme(theme, {
  itemEdit: {
    form: {
      flexBasis: 700,
      [theme.breakpoints.down('xl')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      marginLeft: '10px',
      marginRight: '10px',
    },
    title: {
      marginTop: theme.spacing(3)
    },
    formControl: {
      minWidth: '120px',
    },
    textField: {
      marginTop: theme.spacing(2),
    },
    footer: {
    },
    errorText: {
      color: theme.palette.error.main,
      marginLeft: '10px',
    },
    actions: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    addButton: {
      margin: theme.spacing(1),
      justifyContent: 'left',
    },
    cancelButton: {
      margin: theme.spacing(2, 0),
      marginLeft: '20px',
    },
    deleteButton: {
      margin: theme.spacing(2, 0),
      marginRight: '40px',
      color: theme.palette.error.main,
    },
    submitButton: {
      margin: theme.spacing(2, 0),
      marginLeft: '20px',
    },
  },
  itemView: {
    root: {
      marginLeft: '10px',
    },
    title: {
      marginTop: theme.spacing(1),
      fontSize: '30px',
    },
    body: {
      marginTop: theme.spacing(2),
    },
    header: {
      display: 'inline-flex',
      width: '100%',
    },
    info: {
      display: 'inline-flex',
      flex: 1,
      justifyContent: 'space-between',
      marginLeft: '20px',
    },
    editButton: {
      float: 'inline-end',
      justifyContent: 'flex-end',
      marginTop: '10px',
      marginRight: '10px',
    },
    leftCentered: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
    image: {
      height: '400px',
      maxWidth: '400px',
      width: '50vw',
      objectFit: 'contain',
      display: 'block',
    },
    textBlock: {
      display: 'inline-flex',
    },
    label: {
      minWidth: '75px',
    },
    text: {
      marginLeft: '20px',
    },
    error: {
      color: theme.palette.error.main
    }
  },
  info: {
    root: {
      flexGrow: 1,
      padding: theme.spacing(4),
    },
    title: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    body: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    error: {
      color: theme.palette.error.main
    },
    h2: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
    h3: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
    h4: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
    date: {
      marginLeft: theme.spacing(1),
    }
  },
  signIn: {
    form: {
      paddingLeft: '100px',
      paddingRight: '100px',
      paddingBottom: '125px',
      flexBasis: 700,
      [theme.breakpoints.down('xl')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      }
    },
    title: {
      marginTop: theme.spacing(3)
    },
    socialButtons: {
      marginTop: theme.spacing(3)
    },
    socialIcon: {
      marginRight: theme.spacing(1)
    },
    suggestion: {
      marginTop: theme.spacing(2)
    },
    textField: {
      marginTop: theme.spacing(2)
    },
    signInButton: {
      margin: theme.spacing(2, 0)
    },
    errorText: {
      color: theme.palette.error.main
    },
    policy: {
      marginTop: theme.spacing(1),
      display: 'flex',
      alignItems: 'center'
    },
    policyText: {},
    policyCheckbox: {
      marginLeft: '-14px'
    },
  },
});

export default theme;
