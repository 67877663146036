import React, { Fragment, FunctionComponent, useContext } from 'react';
import {
  List,
  Divider
} from '@mui/material';
import {
  Business,
  LocalBar,
  LocalDrink,
  List as ListIcon,
  VerticalAlignBottom
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import { AuthContext } from '../../contexts';

import SidebarNavItem from './SideBarNavItem';


interface Props {
  isCollapsed?: boolean,
  onSelect?: () => void,
}


const SidebarNav: FunctionComponent<Props> = ({isCollapsed=false, ...props}) => {
  const { onSelect } = props;

  const theme = useTheme();
  const styles = {
    navList: {
      //width: theme.sidebar.width,
      fontSize: '1.1em',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    navListHeader: {
      textAlign: 'center',
    },
    divider: {
      margin: theme.spacing(2, 0)
    },
  };

  const beverageItems = [
    {
      name: 'All Beverages',
      link: '/beverages',
      Icon: LocalDrink,
    },
    {
      name: 'Beers',
      link: '/beers',
      Icon: LocalDrink,
    },
    {
      name: 'Ciders',
      link: '/ciders',
      Icon: LocalDrink,
    },
    {
      name: 'Seltzers',
      link: '/seltzers',
      Icon: LocalDrink,
    },
    {
      name: 'Spirits',
      link: '/spirits',
      Icon: LocalBar,
    },
    {
      name: 'Wines',
      link: '/wines',
      Icon: LocalDrink,
    },
    {
      name: 'Beverage Types',
      link: '/beverage-types',
      Icon: ListIcon,
    },
  ];

  const producerItems = [
    {
      name: 'All Producers',
      link: '/producers',
      Icon: Business,
    },
    {
      name: 'Breweries',
      link: '/breweries',
      Icon: Business,
    },
    {
      name: 'Wineries',
      link: '/wineries',
      Icon: Business,
    },
    {
      name: 'Distilleries',
      link: '/distilleries',
      Icon: Business,
    },
  ];

  const ingredientItems = [
    {
      name: 'Beer Ingredients',
      link: '/beer-ingredients',
      Icon: ListIcon,
    },
    {
      name: 'Wine Ingredients',
      link: '/wine-ingredients',
      Icon: ListIcon,
    },
    {
      name: 'Spirit Ingredients',
      link: '/spirit-ingredients',
      Icon: ListIcon,
    },
  ];

  const adminItems = [
    {
      name: 'Migrate Picklists',
      link: '/admin/migrate/picklists/',
      Icon: VerticalAlignBottom,
    },
    {
      name: 'Migrate Ingredients',
      link: '/admin/migrate/ingredients/',
      Icon: VerticalAlignBottom,
    },
    {
      name: 'Migrate Beverage Types',
      link: '/admin/migrate/beverage_types/',
      Icon: VerticalAlignBottom,
    },
    {
      name: 'Migrate Producers',
      link: '/admin/migrate/producers/',
      Icon: VerticalAlignBottom,
    },
    {
      name: 'Migrate Beverages',
      link: '/admin/migrate/beverages/',
      Icon: VerticalAlignBottom,
    },
    {
      name: 'Migrate Places',
      link: '/admin/migrate/places/',
      Icon: VerticalAlignBottom,
    },
  ];

  const {canApprove} = useContext(AuthContext);

  if (canApprove) {
    adminItems.push({
      name: 'Transactions',
      link: '/transactions',
      Icon: ListIcon,
    });
  }

  return (
    <div>
      <SidebarNavItem
        name='Home'
        iconPadding={0}
        link='/home'
        onClick={onSelect}
      />

      <Divider sx={styles.divider} />

      <SidebarNavItem
        isCollapsed={false}
        name='Places'
        iconPadding={0}
        link='/places/'
        onClick={onSelect}
      />

      <Divider sx={styles.divider} />

      <List sx={styles.navList} disablePadding>
        <SidebarNavItem
            isCollapsed={isCollapsed}
            name='Beverages'
            iconPadding={0}
            items={beverageItems}
            onClick={onSelect}
        />
      </List>

      <Divider sx={styles.divider} />

      <List sx={styles.navList} disablePadding>
        <SidebarNavItem
            isCollapsed={false}
            name='Producers'
            iconPadding={0}
            items={producerItems}
            onClick={onSelect}
        />
      </List>

      <Divider sx={styles.divider} />

      <List sx={styles.navList} disablePadding>
        <SidebarNavItem
            isCollapsed={false}
            name='Ingredients'
            iconPadding={0}
            items={ingredientItems}
            onClick={onSelect}
        />
      </List>

      {adminItems.length > 0 &&
        <Fragment>
          <Divider sx={styles.divider} />

          <List sx={styles.navList} disablePadding>
            <SidebarNavItem
                isCollapsed={false}
                name='Admin'
                iconPadding={0}
                items={adminItems}
                onClick={onSelect}
            />
          </List>
        </Fragment>
      }
    </div>
  );
};

export default SidebarNav;
