import React, {
  cloneElement,
  FunctionComponent, ReactElement,
  ReactNode,
  useContext,
  useState
} from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ItemView, { ItemViewProps } from '../common/ItemView';
import LabeledLinks from '../common/LabeledLinks';
import LabeledMap from '../common/LabeledMap';
import LabeledText from '../common/LabeledText';
import LabeledURL from '../common/LabeledURL';
import LocationView from '../common/LocationView';
import { AuthContext } from '../../contexts';
import useGetPlaceGraphQL from '../../graphql/hooks/useGetPlaceGraphQL';
import { Place, PlaceBeverage, PlaceCore } from '../../models';

import PlaceEditDialog from './PlaceEditDialog';
import PlaceBeveragesCardGrid from './PlaceBeveragesCardGrid';
import PlaceBeverageAddDialog from './PlaceBeverageAddDialog';
import PlaceBeverageEditDialog from './PlaceBeverageEditDialog';
import PlaceBeverageViewDialog from './PlaceBeverageViewDialog';


type Props = ItemViewProps<Place> & {
  place?: Place | null,
  id?: string|null,
  model?: typeof Place,
  typeName?: string,
  beverageTypeName?: string,
  additionalFieldRenderer?: (item: Place|null) => ReactNode,
  editDialog?: ReactNode,
}

const PlaceView: FunctionComponent<Props> = props => {

  const {
    place, id,
    model=Place, typeName='Place', beverageTypeName='Beverage',
    additionalFieldRenderer, editDialog,
  } = props;

  //console.log('PlaceView', place);

  const theme = useTheme();
  const styles = {
    column: {
      paddingLeft: '10px',
      marginTop: '10px',
      marginBottom: '10px',
    },
    beverageList: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
    },
    beverageListHeader: {
      display: 'inline-flex',
      width: '100%',
    },
    beverageListTitle: {
      display: 'inline-flex',
      flex: 1,
      justifyContent: 'space-between',
      marginTop: '15px',
      fontSize: '25px',

    },
    addBeverage: {
      float: 'inline-end',
      justifyContent: 'flex-end',
      marginTop: '10px',
      marginRight: '10px',
    },
  };

  const {canContribute} = useContext(AuthContext);

  const [openPlaceEditDialog, setOpenPlaceEditDialog] = useState<boolean>(false);
  const [selectedPlaceBeverage, setSelectedPlaceBeverage] = useState<PlaceBeverage|null>(null);
  const [openPlaceBeverageAddDialog, setOpenPlaceBeverageAddDialog] = useState<boolean>(false);
  const [openPlaceBeverageEditDialog, setOpenPlaceBeverageEditDialog] = useState<boolean>(false);
  const [openPlaceBeverageViewDialog, setOpenPlaceBeverageViewDialog] = useState<boolean>(false);

  const handleClickEdit = (item: Place|null) => {
    /*
    if (item?.id != null) {
      history.push(`/beer-edit/${item.id}`)
    }
    */
    setOpenPlaceEditDialog(true);
  };

  const handleClosePlaceEditDialog = () => {
    setOpenPlaceEditDialog(false);
  };

  const EditDialog = editDialog == null
    ? PlaceEditDialog
    : (props: any) => {
      return cloneElement(editDialog as ReactElement<any>, {
        place: props.place,
        typeName: typeName,
        model: model,
        open: openPlaceEditDialog,
        onClose: handleClosePlaceEditDialog,
      });
    }

  const handleAddPlaceBeverage = () => {
    setSelectedPlaceBeverage(null);
    setOpenPlaceBeverageAddDialog(true);
  };

  const handlePlaceBeverageClick = (placeBeverage: PlaceBeverage|null) => {
    if (placeBeverage != null) {
      setSelectedPlaceBeverage(placeBeverage);
      setOpenPlaceBeverageViewDialog(true);
    }
  };

  const handlePlaceBeverageEdit = () => {
    setOpenPlaceBeverageViewDialog(false);
    setOpenPlaceBeverageEditDialog(true);
  }

  const handleClosePlaceBeverageAddDialog = () => {
    setOpenPlaceBeverageAddDialog(false);
    setSelectedPlaceBeverage(null);
  };

  const handleClosePlaceBeverageEditDialog = () => {
    setOpenPlaceBeverageEditDialog(false);
    setSelectedPlaceBeverage(null);
  };

  const handleClosePlaceBeverageViewDialog = () => {
    setOpenPlaceBeverageViewDialog(false);
    setSelectedPlaceBeverage(null);
  };

  const renderView = (item: Place|null) => {
    return (
      <Box sx={theme.itemView.root}>
        <Box sx={theme.itemView.header}>
          <Box sx={theme.itemView.leftCentered}>
            {item?.logoUrls &&
              <Box component='img' sx={theme.itemView.image}
                src={item?.logoUrls['400px']}
                alt='Logo'
              />
            }
          </Box>
          <Box sx={theme.itemView.info}>
            <Box sx={theme.itemView.leftCentered}>
              <Typography sx={theme.itemView.title} variant='h2'>
                {item?.name}
              </Typography>

              <Typography sx={theme.itemView.body} variant='body2' color='textSecondary'>
                {item?.description}
              </Typography>

              <LabeledText
                label='Type of Place'
                text={item?.placeTypes && item?.placeTypes.map(type => type.name).join(', ')}
              />

              <LocationView location={item?.location} labeled/>
              <LabeledText
                label='Phone'
                text={item?.phone}
              />
              <LabeledURL
                label='Website'
                url={item?.website}
              />
              <LabeledLinks
                label='Links'
                links={item?.links}
              />

              <LabeledText
                label='Amenities'
                text={item?.amenities && item?.amenities.join(', ')}
              />

              {additionalFieldRenderer && additionalFieldRenderer(item)}
              {item?.properties && item.properties.length > 0 &&
                <LabeledMap
                  label='Properties'
                  map={item?.properties}
                />
              }
            </Box>
            {canContribute &&
              <Box sx={theme.itemView.editButton}>
                <Button color='primary'
                        variant='outlined'
                        onClick={() => handleClickEdit(item)}>
                  {`Edit This ${typeName}`}
                </Button>
                <EditDialog
                  place={item}
                  model={model}
                  typeName={typeName}
                  open={openPlaceEditDialog}
                  onClose={handleClosePlaceEditDialog}
                />
              </Box>
            }
          </Box>
        </Box>

        <Box sx={styles.beverageList}>
          <Box sx={styles.beverageListHeader}>
            <Typography sx={styles.beverageListTitle} variant='h4'>
              {`${beverageTypeName}s`}
            </Typography>
            {canContribute &&
              <Box sx={styles.addBeverage}>
                  <Button color='primary'
                          variant='outlined'
                          onClick={handleAddPlaceBeverage}>
                    {`Add A Beverage`}
                  </Button>
              </Box>
            }
          </Box>
          <Box>
            <PlaceBeveragesCardGrid
              place={item}
              onSelect={handlePlaceBeverageClick}
            />
            <PlaceBeverageAddDialog
              place={PlaceCore.fromPlace(item)}
              open={openPlaceBeverageAddDialog}
              onClose={handleClosePlaceBeverageAddDialog}
            />
            <PlaceBeverageEditDialog
              place={PlaceCore.fromPlace(item)}
              placeBeverage={selectedPlaceBeverage}
              open={openPlaceBeverageEditDialog}
              onClose={handleClosePlaceBeverageEditDialog}
            />
            <PlaceBeverageViewDialog
              placeBeverage={selectedPlaceBeverage}
              open={openPlaceBeverageViewDialog}
              onClose={handleClosePlaceBeverageViewDialog}
              onEdit={handlePlaceBeverageEdit}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <ItemView
      item={place}
      id={id}
      useGetGraphQL={useGetPlaceGraphQL}
      useGetGraphQLProps={{model: model}}
      viewRenderer={renderView}
    />
  )
};

export default PlaceView;
