import React, {FunctionComponent, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Box,
  Button,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {AuthContext} from '../../contexts';


const ResendVerificationEmail: FunctionComponent = () => {

  const navigate = useNavigate();
  const {sendVerificationEmail} = useContext(AuthContext);

  const theme = useTheme();

  const handleSendEmail = async () => {
    await sendVerificationEmail();
    navigate('/home', {replace: true});
  };

  return (
    <Box component='form'
      sx={theme.signIn.form}
      onSubmit={handleSendEmail}
    >
      <Typography
        sx={theme.signIn.title}
        variant='h2'
      >
        Resend Verification Email
      </Typography>
      <Typography
        align='center'
        sx={theme.signIn.suggestion}
        color='textSecondary'
        variant='body1'
      >
        Press the button to have a new email sent. Check spam or junk folders if not received shortly.
      </Typography>
      <Button
        sx={theme.signIn.signInButton}
        color='primary'
        fullWidth
        size='large'
        type='submit'
        variant='contained'
      >
        Resend Email
      </Button>
    </Box>
  );

};

export default ResendVerificationEmail;
