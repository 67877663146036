import {ProducerDetails} from '../graphql/types/ProducerDetails';
import {ProducerInput} from '../graphql/types/globalTypes';
import {MapType} from './Base';
import {VersionedItem} from './VersionedItem';
import {Location} from './Location';
import {ProducerType} from './ProducerType';
import {ProducerMinimum} from '../graphql/types/ProducerMinimum';


export class ProducerCore extends VersionedItem {

  producerId?: string;
  description?: string;
  website?: string;
  logoUrls?: MapType;
  links?: MapType;
  properties?: MapType;

  get currentId() {
    return this.producerId;
  }

  static fromProducer(producer: Producer): ProducerCore {
    const core = new ProducerCore();
    if (producer != null) {
      core.id = producer.id;
      core.name = producer.name;
      core.producerId = producer.producerId;
      core.description = producer.description;
      core.website = producer.website;
      core.logoUrls = producer.logoUrls;
      core.links = producer.links;
      core.properties = producer.properties;
    }
    return core;
  }

  setFromGraphQL(data: Partial<ProducerMinimum>|null) {
    if (data == null) return;

    super.setFromGraphQL(data);
    this.producerId = data.producerId == null ? undefined : data.producerId;
    this.description = data.description == null ? undefined : data.description;
    this.website = data.website == null ? undefined : data.website;
    this.logoUrls = data.logoUrls;
    this.links = data.links;
    this.properties = data.properties;
  }
}


export class Producer extends ProducerCore {
  producerTypes?: ProducerType[];
  locations?: Location[];

  setFromGraphQL(data: Partial<ProducerDetails>|null) {
    if (data == null) return;

    super.setFromGraphQL(data);
    this.producerTypes = data.producerTypes == null
      ? undefined
      : data.producerTypes.map(pt => ProducerType.fromGraphQL(pt));
    this.locations = data.locations == null
      ? undefined
      : data.locations.map(l => Location.fromGraphQL(l));
  }

  toCore = () => {
    return ProducerCore.fromProducer(this);
  }

  toInput(allProps=false): ProducerInput {
    const input = super.toInput(allProps);
    if (allProps) {
      return {
        ...input,
        producerId: this.producerId,
        description: this.description,
        website: this.website,
        logoUrls: JSON.stringify(this.logoUrls),
        links: JSON.stringify(this.links),
        properties: JSON.stringify(this.properties),
        producerTypes: this.producerTypes?.map(pt => pt.toInput()),
        locations: this.locations?.map(l => l.toInput()),
      };
    } else {
      return {
        ...input,
        producerId: this.producerId,
      };
    }
  }
}
