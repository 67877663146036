import React, { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { LocalDrink } from '@mui/icons-material';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';


interface Props {
  sx?: SxProps,
}

const TopBar: FunctionComponent<Props> = props => {
  const { sx, ...rest } = props;

  const theme = useTheme();
  const styles = {
    root: {
      boxShadow: 'none',
      background: theme.palette.white,
    }
  };

  return (
    <AppBar
      {...rest}
      sx={[styles.root, ...(Array.isArray(sx) ? sx : [sx]),]}
      color='primary'
      position='fixed'
    >
      <Toolbar>
        <RouterLink to='/'>
          <span>
          <LocalDrink/>
          <Typography>
            BevDb
          </Typography>
          </span>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;