import cloneDeep from 'lodash/cloneDeep';
import {difference} from '../helpers/objectDifference';


export type JSONType = {[key: string]: string};
export type MapType = {[key: string]: any};

export type BaseInput = {
}

export class BaseModel {
  [key: string]: any;

  static fromGraphQL<T extends typeof BaseModel>(this: T, data: BaseInput|null) {
    const base = new this() as InstanceType<T>;
    if (data != null) {
      base.setFromGraphQL(data);
    }
    return base;
  }

  setFromGraphQL(data: BaseInput|null) {
    if (data == null) {
      return;
    }
  }

  toInput(allProps=false) {
    return {
    };
  }

  static fromMap<T extends typeof BaseModel>(this: T, data?: Partial<InstanceType<T>>|null): InstanceType<T> {
    const base = new this() as InstanceType<T>;
    base.setFromMap(data);
    return base;
  }

  // Create a map containing this instances properties
  toMap(): {[key:string]: any} {
    const map: {[key:string]: any} = {};
    Object.entries(this).forEach(([key, value]) => {
      if (value != null) {
        if (value instanceof BaseModel) {
          map[key] = value.toMap();
        } else {
          map[key] = value;
        }
      }
    });
    return map;
  }

  // Extract the values for this instance from the provided document data
  setFromMap(map?: Partial<this>|null) {
    if (map == null) {
      return;
    }
    Object.entries(map).forEach(([key, value]) => {
      if (this[key] instanceof BaseModel) {
        this[key].setFromMap(value);
      } else {
        this[key] = value;
      }
    });
  }

  clone() {
    //const copy = new (this.constructor as { new (): T })();
    //Object.assign(copy, cloneDeep<T>(this));
    //const clone = Object.create(Object.getPrototypeOf(this));
    //const descriptors = Object.getOwnPropertyDescriptors(this);
    //Object.defineProperties(clone, descriptors);
    return cloneDeep(this) || null;
  }

  changesFrom(other: this) {
    const changes = difference(other, this);
    // console.log('changes', changes);
    return changes;
  }

  validate() {
    return true;
  }
}