import React, { FunctionComponent, useContext, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Divider,
  IconButton,
  Menu, MenuItem,
  ListItemIcon, ListItemText,
  Typography,
} from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';
import {
  AccountBox,
  ArrowDropDown,
  ExitToApp,
  Settings
} from '@mui/icons-material';

import {AuthContext} from '../../contexts';
import LabeledText from '../../components/common/LabeledText';


interface Props {
  sx?: SxProps,
}

const Profile: FunctionComponent<Props> = props => {
  const { sx } = props;

  const theme = useTheme();
  const styles = {
    root: {
      display: 'inline-flex',
      //display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: 'fit-content',
    },
    avatar: {
      //width: 35,
      //height: 35,
      marginRight: '10px',
    },
    button: {
      borderRadius: 30,
      //fontSize: '1.2rem',
      padding: '8px',
    },
    menu: {
      marginLeft: '-16px',
    },
    name: {
      fontWeight: 400,
      marginRight: '5px',
      [theme.breakpoints.down('xl')]: {
        display: 'none',
      },
    }
  };

  const [openProfile, setOpenProfile] = useState<boolean>(false);
  const [openSettings, setOpenSettings] = useState<boolean>(false);

  const {currentUser, signOut} = useContext(AuthContext);
  console.log('Profile currentUser', currentUser);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  if (currentUser == null) {
    return (
      <div>
        <Typography
          sx={styles.name}
          variant='h4'
        >
          Not Logged In
        </Typography>
      </div>
    )
  }

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleProfileClick() {
    setOpenProfile(true);
    handleClose();
  }

  function handleCloseProfile() {
    setOpenProfile(false);
  }

  function handleSettingsClick() {
    setOpenSettings(true);
    handleClose();
  }

  function handleCloseSettings() {
    setOpenSettings(false);
  }

  async function handleLogout() {
    handleClose();
    await signOut();
  }

  return (
    <Box sx={[styles.root, ...(Array.isArray(sx) ? sx : [sx]),]}>
      <IconButton
        edge='start'
        color='inherit'
        aria-label='Search'
        sx={styles.button}
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <Avatar
          sx={styles.avatar}
          alt={currentUser.name || undefined}
          src={currentUser.avatarURL || undefined}
        />
        <Typography
          sx={styles.name}
          variant='h4'
        >
          {currentUser.name || ''}
        </Typography>
        <ArrowDropDown color='primary'
        />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          paper: styles.menu,
        }}
      >
        <MenuItem onClick={handleProfileClick}>
          <ListItemIcon>
            <AccountBox />
          </ListItemIcon>
          <ListItemText primary='My Profile' />
        </MenuItem>
        <MenuItem onClick={handleSettingsClick}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary='Settings' />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary='Logout' />
        </MenuItem>
      </Menu>

      <Dialog
          open={openProfile}
          onClose={handleCloseProfile}
          aria-labelledby='profile-dialog-title'
      >
        <DialogTitle id='profile-dialog-title'>Profile</DialogTitle>
        <DialogContent>
          <LabeledText label='Name' text={currentUser.name}/>
          <LabeledText label='Email' text={currentUser.email}/>
          <LabeledText label='Points' text={currentUser?.points}/>
          <LabeledText
            label='Roles'
            text={currentUser?.roles?.map(role => role.name).join(', ')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseProfile} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
          open={openSettings}
          onClose={handleCloseSettings}
          aria-labelledby='settings-dialog-title'
      >
        <DialogTitle id='settings-dialog-title'>Settings</DialogTitle>
        <DialogContent>
          <Typography variant='h3'>
            Coming Soon
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSettings} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );

};

export default Profile;
