import React, { useContext, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  List, ListItemButton, ListItemSecondaryAction, ListItemText,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Edit } from '@mui/icons-material';

import { AuthContext, PicklistsContext } from '../../contexts';
import { BUTTON_VARIANT } from '../common/ItemView';

import { BrewersAssociationStyleEdit, BrewersAssociationStyleView } from './index';
import { BrewersAssociationStyle } from '../../models';


const BrewersAssociationStylesList = () => {
  //console.log('Refreshing IngredientsList');

  const theme = useTheme();
  const styles = {
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    list: {
      width: '100%',
      maxHeight: '60vh',
      maxWidth: 360,
      overflow: 'auto',
    },
    title: {
      marginTop: theme.spacing(3)
    },
  };

  const [id, setId] = useState<string|null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number|null>(null);

  const {brewersAssociationStyles: items} = useContext(PicklistsContext);

  const {canContribute} = useContext(AuthContext);

  const handleAdd = () => {
    setId(null);
    setSelectedIndex(null);
    setIsEditing(true);
  };

  const handleDoneEditing = async () => {
    setIsEditing(false);
    setSelectedIndex(null);
  };

  const handleEdit = (item: BrewersAssociationStyle) => {
    if (item.currentId != null) {
      setId(item.currentId);
      setIsEditing(true);
    }
  };

  const handleSelect = (item: BrewersAssociationStyle, index: number) => {
    setSelectedIndex(index);
    setIsEditing(false);
    item.currentId && setId(item.currentId);
  };

  const renderComponent = () => {
    if (isEditing) {
      return (
        <BrewersAssociationStyleEdit
          id={id}
          onComplete={handleDoneEditing}
          onDelete={handleDoneEditing}
        />
      );
    } else {
      return (
        <BrewersAssociationStyleView id={id}/>
      );
    }
  };

  return (
    <Grid container sx={styles.root}>
      <Grid item xs={6}>
        <Typography sx={styles.title} variant='h2'>
          Brewers Association Styles
        </Typography>
        {canContribute &&
          <Button
            size='small'
            color='primary'
            variant={BUTTON_VARIANT}
            onClick={handleAdd}
          >
            Add A New Style
          </Button>
        }
        <List sx={styles.list} dense>
          {items?.map((item, i) => (
            <ListItemButton
              key={i}
              divider
              selected={selectedIndex === i}
              onClick={() => handleSelect(item, i)}
            >
              <ListItemText
                primary={item.name}
              />
              {canContribute &&
                <ListItemSecondaryAction>
                  <IconButton
                    edge='end'
                    aria-label='edit'
                    onClick={() => handleEdit(item)}
                    size="large">
                    <Edit/>
                  </IconButton>
                </ListItemSecondaryAction>
              }
            </ListItemButton>
          ))}
        </List>
      </Grid>
      <Grid item xs={6}>
        {renderComponent()}
      </Grid>
    </Grid>
  );
};

export default BrewersAssociationStylesList;
