import React from 'react';

import {BeveragesCardGrid} from '../components/Beverage';


export default function SeltzersView() {

  return (
    <div>
      <BeveragesCardGrid
        categoryName='Seltzer'
      />
    </div>
  );
}
