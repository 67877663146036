import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Add } from "@mui/icons-material";


interface TableProps {
  label?: string;
  value?: string[];
  onChange?: (param: string[]) => void;
}

const StringListEdit = ({value=[], ...props}: TableProps) => {
  const { label, onChange } = props;
  console.log('StringListEdit', props);

  const theme = useTheme();
  const styles = {
    body: {
      marginTop: theme.spacing(2)
    },
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
  };

  const newStrings = value || [];

  const handleChange = (value: string, index: number) => {
    console.log('handle change', value, index);
    newStrings[index] = value;
    onChange && onChange(newStrings);
  };

  const handleAddClick = () => {
    console.log('handle add');
    newStrings.push('');
    onChange && onChange(newStrings);
  };

  return (
    <div>
      {value.map((val, i) =>
        <input
            key={i}
            type='text'
            value={val}
            onChange={e => handleChange(e.target.value, i)}
        />
      )}
      <IconButton
        color='secondary'
        sx={styles.button}
        aria-label='add'
        onClick={handleAddClick}
        size="large">
        <Add/>
        <Typography
          sx={styles.body}
          variant='body1'
        >
          {label || ''}
        </Typography>
      </IconButton>
     </div>
  );
};

export default StringListEdit;
