import React, {ChangeEvent} from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';

export type InputEvent = ChangeEvent<HTMLInputElement>;
export type SelectEvent = ChangeEvent<HTMLInputElement>;

type Override<S, T> = Omit<S, keyof T> & T;

type Props = Override<TextFieldProps, {
  sx?: SxProps,
  error?: string | null,
  id?: string,
  label?: string | null,
  name?: string,
  onBlur?: () => void,
  onChange?: (value: number|null) => void,
  ref?: any,
  value?: number | null,
  variant?:  'filled' | 'standard' | 'outlined',
}>;

const NumberEdit = (props: Props) => {

  const {
    sx, error, id, label, name, onBlur, onChange, ref, value, variant,
    ...otherTextFieldProps
  } = props;

  const theme = useTheme();

  const handleChange = (event: InputEvent|SelectEvent) => {
    event.persist();
    if (onChange == null) {
      return;
    }
    const val = event.target.value;
    let num: number|null;
    if (val == null || val === '') {
      num = null;
    } else {
      num = Number(val);
    }
    onChange(num);
  };

  const _id = id || name;

  return (
    <TextField
      autoComplete='off'
      sx={[theme.itemEdit.textField, ...(Array.isArray(sx) ? sx : [sx]),]}
      error={error != null}
      fullWidth
      helperText={error}
      id={_id}
      label={label}
      name={name}
      onChange={handleChange}
      onBlur={onBlur}
      ref={ref}
      type='number'
      value={value || ''}
      variant={variant || 'outlined'}
      {...otherTextFieldProps}
    />
  );
}

export default NumberEdit;