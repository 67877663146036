import React from 'react';

import {BeveragesCardGrid} from '../components/Beverage';


export default function BeersView() {

  return (
    <div>
      <BeveragesCardGrid
        categoryName='Beer'
      />
    </div>
  );
}
