import {useState} from 'react';

import {useQuery} from '@apollo/client';
import listQuery from '../queries/listProducerTypesQuery.graphql';
import {ListProducerTypes} from '../types/ListProducerTypes';

import {ProducerType} from '../../models';

const Item = ProducerType;
type ListItems = ListProducerTypes;
type Item = ProducerType;


const useListProducerTypesGraphQL = () => {

  const [items, setItems] = useState<Item[]>([]);

  const {loading, error} =
    useQuery<ListItems>(listQuery, {
      //fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        if (data.producerTypes != null) {
          setItems(data.producerTypes.map(r => Item.fromGraphQL(r)));
        }
      }
    });

  return {
    items,
    gettingItems: loading,
    listError: error?.message || null,
  };
}

export default useListProducerTypesGraphQL;
