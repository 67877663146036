import React from 'react';

import {PlacesCardGrid} from '../components/Place';


export default function PlacesView() {

  return (
    <div>
      <PlacesCardGrid />
    </div>
  );
}
