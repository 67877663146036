import React, { Fragment } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FormErrors, InputEvent, SelectEvent } from '../../helpers/useFormValidation';
import { BrewersAssociationStyle } from '../../models';
import ItemEdit, { ItemEditProps } from '../common/ItemEdit';
import TextEdit from '../common/TextEdit';
import SingleSelect from '../common/SingleSelect';


function validateBrewersAssociationStyle(values: BrewersAssociationStyle) {
  let errors: FormErrors<BrewersAssociationStyle> = {};

  // Name Errors
  if (!values.name) {
    errors.name = 'Name required';
  } else if (values.name.length < 2) {
    errors.name = 'Name must be at least 2 characters';
  }
  // Picklist Name Errors
  if (!values.picklistName) {
    errors.picklistName = 'Picklist Name required';
  } else if (values.picklistName.length < 2) {
    errors.picklistName = 'Picklist Name must be at least 2 characters';
  }

  return errors;
}

const INITIAL_VALUE = new BrewersAssociationStyle();
const VARIANT = 'standard';

type ORIGINS_TYPE = {[key: string]: string[]};

const ORIGINS: ORIGINS_TYPE = {
  'Ale Styles': [
    'British Origin Ale Styles',
    'North American Origin Ale Styles',
    'Belgian and French Origin Ale Styles',
    'Irish Origin Ale Styles',
    'German Origin Ale Styles',
    'Other Origin Ale Styles',
  ],
  'Lager Styles': [
    'European Origin Lager Styles',
    'North American Origin Lager Styles',
    'Other Origin Lager Styles',
  ],
  'Hybrid/Mixed Lagers or Ale': [
    'All Origin Hybrid/Mixed Lagers or Ale',
  ],
}

type Props = ItemEditProps<BrewersAssociationStyle> & {
  style?: BrewersAssociationStyle | null
}

const BrewersAssociationStyleEdit = (props: Props) => {

  const {style, ...itemEditProps} = props;
  console.log('BrewersAssociationStyleEdit', style);

  const theme = useTheme();

  const renderFields = (
    originalItem: BrewersAssociationStyle|null,
    formItem: BrewersAssociationStyle|null,
    formErrors: FormErrors<BrewersAssociationStyle>,
    handleBlur: ()=> void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => {
    return (
      <Fragment>
        <Typography
          sx={theme.itemEdit.title}
          variant='h2'
        >
          {originalItem?.name || 'New Brewers Association Style'}
        </Typography>
        <SingleSelect
          label='Family'
          name='family'
          items={Object.keys(ORIGINS)}
          onChange={(val) => updateProperty('family', val)}
          value={formItem?.family}
        />
        <SingleSelect
          label='Origin'
          name='origin'
          items={formItem?.family == null ? [] : ORIGINS[formItem.family]}
          onChange={(val) => updateProperty('origin', val)}
          value={formItem?.origin}
        />
        <TextEdit
          error={formErrors.name}
          label='Name'
          name='name'
          variant={VARIANT}
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.name}
        />
        <TextEdit
          error={formErrors.picklistName}
          label='Picklist Name'
          name='picklistName'
          variant={VARIANT}
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.picklistName}
        />
        <TextEdit
          error={formErrors.description}
          label='Description'
          name='description'
          variant={VARIANT}
          multiline
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.description}
        />
        <TextEdit
          error={formErrors.website}
          label='Website'
          name='website'
          variant={VARIANT}
          onBlur={handleBlur}
          onChange={handleChange}
          value={formItem?.website}
        />
      </Fragment>
    )
  };

  return (
    <ItemEdit
      item={style}
      defaultValue={INITIAL_VALUE}
      fieldRenderer={renderFields}
      validator={validateBrewersAssociationStyle}
      {...itemEditProps}
    />
  );
};

export default BrewersAssociationStyleEdit;
