import {PureQueryOptions, useMutation} from '@apollo/client';
import getProducerQuery from '../queries/getProducerQuery.graphql';
import listProducersQuery from '../queries/listProducersQuery.graphql';
import updateProducerMutation from '../queries/updateProducerMutation.graphql';
import {UpdateProducer, UpdateProducerVariables} from '../types/UpdateProducer';
import deleteProducerMutation from '../queries/deleteProducerMutation.graphql';
import {DeleteProducer, DeleteProducerVariables} from '../types/DeleteProducer';
import {Producer} from '../../models';
import useGetProducerGraphQL from './useGetProducerGraphQL';
import {ListProducers} from '../types/ListProducers';

const Item = Producer;
type Item = Producer;
const UpdateMutation = updateProducerMutation;
type UpdateItem = UpdateProducer;
type UpdateVariables = UpdateProducerVariables;
const DeleteMutation = deleteProducerMutation;
type DeleteItem = DeleteProducer;
type DeleteVariables = DeleteProducerVariables;


type Props = {
  currentId?: string|null,
  name?: string,
  typeName?: string,
  model?: { new (): Item; },
}

const useUpdateProducerGraphQL = (props?: Props) => {

  const {currentId, name, typeName, model=Item} = {...props};
  const {item, gettingItem, getError} = useGetProducerGraphQL({currentId: currentId, name: name, model:model})

  const [_updateItem, {loading: updatingItem, error: updateError}] = useMutation<UpdateItem, UpdateVariables>(UpdateMutation, {
    refetchQueries: () => {
      const queries: PureQueryOptions[] = [{
        query: listProducersQuery,
        variables: {producerType: {name: typeName}}
      }];
      if (currentId != null) {
        queries.push({
          query: getProducerQuery,
          variables: {producer: {producerId: currentId, name: name}}
        });
      }
      return queries;
    }
  });

  const [_deleteItem, {error: deleteError}] = useMutation<DeleteItem, DeleteVariables>(DeleteMutation, {
    update: (client) => {
      const data = client.readQuery<ListProducers>({
        query: listProducersQuery,
        variables: {producerType: {name: typeName}}
      });
      const newData = {
        producers: data?.producers?.filter((i) => i?.producerId !== currentId)
      }
      client.writeQuery({
        query: listProducersQuery,
        variables: {producerType: {name: typeName}},
        data: newData
      });
    },
    refetchQueries: () => [{
      query: listProducersQuery,
      variables: {producerType: {name: typeName}}
    }]
  });

  const updateItem = async (updatedItem: Item) => {
    if (updatedItem == null || _updateItem == null) {
      return;
    }
    const input = updatedItem.toInput(true);
    console.log('updateProducer', typeName, input.producerTypes);
    if (typeName != null && (input.producerTypes == null || input.producerTypes.length < 1)) {
      input.producerTypes = [{name: typeName}];
    }
    await _updateItem({
      variables: {producer: input},
    });
  }

  const deleteItem = async (deletedItem: Item, deleteReason?: string) => {
    if (deletedItem == null || _deleteItem == null) {
      return;
    }
    const input = deletedItem.toInput(true);
    await _deleteItem({
      variables: {producer: input, deleteReason: deleteReason},
    });
  }

  return {
    item,
    gettingItem,
    getError,
    updateItem,
    updatingItem,
    updateError: updateError?.message || null,
    deleteItem,
    deleteError: deleteError?.message || null,
  };
}

export default useUpdateProducerGraphQL;
