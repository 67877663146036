import {BaseModel} from './Base';
import {LocationInput} from '../graphql/types/globalTypes';


export class Location extends BaseModel {

  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;

  longitude?: number;
  latitude?: number;
  distance?: number;

  constructor(country: string = "United States") {
    super();
    this.country = country;
  }

  public get cityState(): string {
    let cityState = '';
    if (this.city != null) {
      cityState += this.city;
    }
    if (this.city != null && this.state != null) {
      cityState += ', ';
    }
    if (this.state != null) {
      cityState += this.state;
    }
    return cityState;
  }

  get cityStateZip(): string {
    let cityStateZip = '';
    const cityState = this.cityState;
    if (cityState != null) {
      cityStateZip += cityState;
    }
    if (cityStateZip.length > 0 && this.zip != null) {
      cityStateZip += ' ' + this.zip;
    }
    return cityStateZip;
  }

  setFromGraphQL(data: LocationInput) {
    super.setFromGraphQL(data);
    if (data != null) {
      this.street = data.street == null ? undefined : data.street;
      this.street2 = data.street2 == null ? undefined : data.street2;
      this.city = data.city == null ? undefined : data.city;
      this.state = data.state == null ? undefined : data.state;
      this.zip = data.zip == null ? undefined : data.zip;
      this.country = data.country == null ? undefined : data.country;
      this.longitude = data.longitude == null ? undefined : data.longitude;
      this.latitude = data.latitude == null ? undefined : data.latitude;
      this.distance = data.distance == null ? undefined : data.distance/1609.34;
    }
  }

  toInput(allProps=false): LocationInput {
    return {
      street: this.street,
      street2: this.street2,
      city: this.city,
      state: this.state,
      zip: this.zip,
      country: this.country,
      longitude: this.longitude,
      latitude: this.latitude,
    };
  }
}
