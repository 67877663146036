import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LabeledText from './LabeledText';


type MapType = {[key: string]: any};

interface Props {
  label?: string,
  map?: MapType,
}

const LabeledMap = (props: Props) => {

  const {label, map} = props;

  const theme = useTheme();

  return (
    <Box>
      <Box sx={theme.itemView.textBlock}>
        {label &&
          <Typography sx={theme.itemView.label} variant='h6'>
            {label}
          </Typography>
        }
        <Box sx={theme.itemView.text}>
          {map && Object.keys(map).map((name, i) => (
            <Box key={i}>
              <LabeledText
                label={name}
                text={JSON.stringify(map[name])}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
};

export default LabeledMap;
