import React from 'react';

import {BeveragesCardGrid} from '../components/Beverage';


export default function BeveragesView() {

  return (
    <div>
      <BeveragesCardGrid/>
    </div>
  );
}
