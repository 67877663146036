import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  InputAdornment,
  List, ListItemButton, ListItemText,
  TextField,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Search } from '@mui/icons-material';

import { BUTTON_VARIANT } from '../common/ItemView';
import useListBeveragesGraphQL from '../../graphql/hooks/useListBeveragesGraphQL';
import { BeverageCore } from '../../models';


const styles = {
  root: {
    //marginLeft: 10,
  },
  selectRow: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'space-between',
    flex: 1,
    marginLeft: 10,
  },
  text: {
  },
  button: {
    alignSelf: 'center',
  }
};


interface SelectProps {
  beverage?: BeverageCore|null,
  typeName?: string,
  onSelect?: (item: BeverageCore|null) => void,
}

const BeverageSelectList = (props: SelectProps) => {
  console.log('Refreshing BeverageSelect');

  const { beverage, typeName, onSelect } = props;

  const [searchString, setSearchString] = useState('');

  const {items: graphQLItems} = useListBeveragesGraphQL({
    filterString: searchString,
    limit: 20,
  });

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    event.persist();
    setSearchString(event.target.value);
  }

  const [selectedIndex, setSelectedIndex] = React.useState(1);

  useEffect(() => {
    if (beverage != null && graphQLItems != null) {
      const index = graphQLItems.findIndex(item => item.id === beverage.id);
      if (index != null && index > 0) {
        setSelectedIndex(index);
      }
    }
  }, [beverage, graphQLItems]);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    onSelect && onSelect(graphQLItems![index].toCore());
  };

  return (
    <Fragment>
      <TextField
        helperText={`Filter ${typeName||'Beverage'}s`}
        value={searchString}
        onChange={handleSearchChange}
        type='text'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
                <Search />
            </InputAdornment>
          )
        }}
      />
      <List  component='nav'>
        {graphQLItems?.map((beverage, i) =>
          <ListItemButton
            key={i}
            selected={selectedIndex === i}
            onClick={event => handleListItemClick(event, i)}
          >
            <ListItemText
              primary={beverage.name}
              secondary={beverage.cityState}
            />
          </ListItemButton>
        )}
      </List>
    </Fragment>
  );
};

interface DialogProps {
  beverage: BeverageCore|null,
  typeName?: string,
  onClose: (value: BeverageCore|null) => void,
  onSelect?: (value: BeverageCore|null) => void,
  open: boolean,
}

const BeverageSelectDialog = (props: DialogProps) => {

  const {beverage, typeName, onClose, onSelect, open} = props;

  const handleClose = () => {
    onClose(beverage);
  };

  return (
    <div>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='beverage-select-dialog-title'
      >
        <DialogTitle id='beverage-select-dialog-title'>
          Select {typeName||'Beverage'}
        </DialogTitle>
        <DialogContent>
          <BeverageSelectList
              beverage={beverage}
              typeName={typeName}
              onSelect={onSelect}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button
              onClick={handleClose}
              color='primary'
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};


interface Props {
  beverage?: BeverageCore|null,
  typeName?: string,
  closeOnSelect?: boolean,
  onChange?: (item: BeverageCore|null) => void,
}

const BeverageSelect = (props: Props) => {
  //console.log('Refreshing BeverageSelect');

  const { beverage, typeName, closeOnSelect, onChange } = props;

  const theme = useTheme();

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);

  const handleCloseDialog = (value: BeverageCore|null) => {
    setOpenAddDialog(false);
    //onChange && onChange(value);
  };

  const handleSelect = (value: BeverageCore|null) => {
    if (closeOnSelect) {
      setOpenAddDialog(false);
    }
    onChange && onChange(value);
  }

  const displayTypeName = typeName || 'Beverage';
  const buttonText = beverage?.name
    ? `Select A Different ${displayTypeName}`
    : `Select A ${displayTypeName}`

  return (
    <Box sx={styles.root}>
      <Typography sx={theme.itemView.label} variant='h6'>
        {displayTypeName}:
      </Typography>
      <Box sx={styles.selectRow}>
        {beverage?.name &&
          <Typography sx={styles.text} variant='body1'>
            {beverage?.name}
          </Typography>
        }
        <Button
            sx={styles.button}
            color='primary'
            variant={BUTTON_VARIANT}
            onClick={() => setOpenAddDialog(true)}
        >
          {buttonText}
        </Button>
      </Box>
      <BeverageSelectDialog
          beverage={beverage || null}
          typeName={typeName}
          onClose={handleCloseDialog}
          onSelect={handleSelect}
          open={openAddDialog}
      />
    </Box>
  );
};

export default BeverageSelect;
