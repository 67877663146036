import {useState} from 'react';

import {useQuery} from '@apollo/client';
import {ListGraphQLProps} from './graphQLHookTypes';
import listPlacesQuery from '../queries/listPlacesQuery.graphql';
import {ListPlaces, ListPlacesVariables} from '../types/ListPlaces';

import {Place} from '../../models';

const listQuery = listPlacesQuery;
const Item = Place;
type ListItems = ListPlaces;
type ListVariables = ListPlacesVariables;
type Item = Place;


export type ListPlacesProps = ListGraphQLProps & {
  typeName?: string,
  filterString?: string,
  radius?: number,
  longitude?: number,
  latitude?: number,
  limit?: number,
  offset?: number,
  skip?: boolean,
}

const useListPlacesGraphQL = (props?: ListPlacesProps) => {

  const {
    typeName,
    radius, longitude, latitude,
    skip=false, filterString, limit, offset} = {...props};

  const [items, setItems] = useState<Item[]>([]);

  const {loading, error, } =
    useQuery<ListItems, ListVariables>(listQuery, {
      variables: {
        ...(typeName && {placeType: {name: typeName}}),
        filterString: filterString,
        radius: radius == null ? undefined : radius * 1609.34,
        longitude: longitude,
        latitude: latitude,
        limit: limit,
        offset: offset,
      },
      skip: skip || (limit === 0),
      //fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        if (data.places != null) {
          setItems(data.places.map(r => Item.fromGraphQL(r)));
        }
      }
    });

  return {
    items,
    gettingItems: loading,
    listError: error?.message || null,
  };
}

export default useListPlacesGraphQL;
