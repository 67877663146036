import React, { Fragment } from 'react';

import { ItemEditProps } from '../common/ItemEdit';
import NumberEdit from '../common/NumberEdit';
import { FormErrors, InputEvent, SelectEvent } from '../../helpers/useFormValidation';
import { Hop } from '../../models';
import { IngredientEdit } from '../Ingredient';


type Props = ItemEditProps<Hop> & {
  hops?: Hop[],
}

const HopEdit = (props: Props) => {

  const {hops, onComplete, ...itemEditProps} = props;
  console.log('HopEdit', hops);

  const additionalFields = (
    originalItem: Hop|null,
    formItem: Hop|null,
    formErrors: FormErrors<Hop>,
    handleBlur: ()=> void,
    handleChange: (event: InputEvent|SelectEvent) => void,
    updateProperty: (name: string, value: any) => void,
  ) => {

    return (
      <Fragment>
        <NumberEdit
          error={formErrors.alphaMin}
          label='Alpha Acid (Min %)'
          name='alphaMin'
          onBlur={handleBlur}
          onChange={alphaMin => updateProperty('alphaMin', alphaMin)}
          value={formItem?.alphaMin}
        />
        <NumberEdit
          error={formErrors.alphaMax}
          label='Alpha Acid (Max %)'
          name='alphaMax'
          onBlur={handleBlur}
          onChange={alphaMax => updateProperty('alphaMax', alphaMax)}
          value={formItem?.alphaMax}
        />
      </Fragment>
    );
  };

  return (
    <div>
      <IngredientEdit
        model={Hop}
        typeName='Hop'
        additionalFieldRenderer={additionalFields}
        onCancel={onComplete}
        onComplete={onComplete}
        {...itemEditProps}
      />
    </div>
  );
};

export default HopEdit;
