import {Item} from './Item';
import {UserRole} from './UserRole';
import {CurrentUser_currentUser} from '../graphql/types/CurrentUser';

export type UserInput = CurrentUser_currentUser;


export class User extends Item {

  firebaseId?: string;
  email?: string;
  avatarURL?: string;
  points?: number;
  roles?: UserRole[] | null;
  insertedAt?: Date;

  setFromGraphQL(data: UserInput|null) {
    if (data == null) return;

    super.setFromGraphQL(data);
    this.firebaseId = data.firebaseId == null ? undefined : data.firebaseId;
    this.email = data.email == null ? undefined : data.email;
    this.avatarURL = data.avatarUrl == null ? undefined : data.avatarUrl;
    this.points = data.points == null ? undefined : data.points;
    this.roles = data.roles == null ? undefined : data.roles.map(r => UserRole.fromGraphQL(r));
    this.insertedAt = data.insertedAt == null ? undefined : data.insertedAt;
  }

  validate() {
    if (!super.validate()) {
      return false;
    }
    if (this.name == null || this.name === "") {
      return false;
    }
    if (this.email == null || this.email === "") {
      return false;
    }
    return true;
  }
}
