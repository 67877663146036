import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import CardGrid, { CardGridProps } from '../common/CardGrid';
import NumberEdit from '../common/NumberEdit';
import useListPlaceBeveragesGraphQL, { ListPlaceBeveragesProps } from '../../graphql/hooks/useListPlaceBeveragesGraphQL';
import { PlaceBeverage } from '../../models';

import NearbyBeverageCard from './NearbyBeverageCard';


const styles = {
  header: {
    display: 'flex',
  },
  radius: {
    maxWidth: 75,
  },
};

type Props = CardGridProps & {
  onEdit?: (item: PlaceBeverage|null) => void,
  onSelect?: (item: PlaceBeverage|null) => void,
  beverageTypeName?: string,
  pluralName?: string,
}

const NearbyBeveragesCardGrid = (props: Props) => {
  //console.log("Refreshing PlacesCardGrid");

  const {
    onEdit, onSelect, beverageTypeName, pluralName,
    ...cardGridProps} = props;

  const [useNearby, setUseNearby] = useState<boolean>(true);
  const [radius, setRadius] = useState<number>(20);
  const [graphQLProps, setGraphQLProps] = useState<ListPlaceBeveragesProps>({});

  useEffect(() => {
    if (useNearby && 'geolocation' in navigator) {
      console.log('location enabled');
      navigator.geolocation.getCurrentPosition(function(position) {
        console.log('Latitude is :', position.coords.latitude);
        console.log('Longitude is :', position.coords.longitude);
        setGraphQLProps({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          radius: radius,
        })
      });
    } else {
      console.log('location not enabled');
      setGraphQLProps({});
    }
  }, [radius, useNearby, beverageTypeName]);

  const handleUseNearbyChange = () => {
    // Note that useNearby has not yet been toggled
    useNearby
      ? setGraphQLProps({})
      : setGraphQLProps({radius: radius});
    setUseNearby(old => !old);
  };

  const handleRadiusChange = (r: number|null) => {
    if (r != null) {
      setRadius(r);
    }
  }

  const header =
    <Box sx={styles.header}>
      <FormControlLabel
        control={
          <Checkbox
            checked={useNearby}
            onChange={handleUseNearbyChange}
            name='Nearby Beverages Only'
            color='primary'
          />
        }
        label='Nearby Beverages Only'
      />
      <NumberEdit
        sx={styles.radius}
        label='Radius (mi)'
        value={radius}
        onChange={handleRadiusChange}
        disabled={!useNearby}
      />
    </Box>;

  return (
    <CardGrid
      card={<NearbyBeverageCard/>}
      useListGraphQL={useListPlaceBeveragesGraphQL}
      useListGraphQLProps={graphQLProps}
      headerComponent={header}
      name='Beverage'
      onEdit={onEdit}
      onSelect={onSelect}
      {...cardGridProps}
    />
  );
};

export default NearbyBeveragesCardGrid;
