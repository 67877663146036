import {useState} from 'react';

import {useQuery} from '@apollo/client';
import {ListGraphQLProps} from './graphQLHookTypes';
import listQuery from '../queries/listBeveragesQuery.graphql';
import {ListBeverages, ListBeveragesVariables} from '../types/ListBeverages';

import {Beverage} from '../../models';

//import {loader} from 'graphql.macro';
//const listQuery = loader('../queries/listBeveragesQuery.graphql');

const Item = Beverage;
type ListItems = ListBeverages;
type ListVariables = ListBeveragesVariables;
type Item = Beverage;


export type ListBeveragesProps = ListGraphQLProps & {
  categoryName?: string,
  typeName?: string,
  producerId?: string,
  filterString?: string,
  limit?: number,
  offset?: number,
  skip?: boolean,
}

const useListBeveragesGraphQL = (props?: ListBeveragesProps) => {

  const {categoryName, typeName, producerId,
    skip=false, filterString, limit, offset} = {...props};

  const [items, setItems] = useState<Item[]>([]);

  const {loading, error, } =
    useQuery<ListItems, ListVariables>(listQuery, {
      variables: {
        ...(categoryName && {category: {name: categoryName}}),
        ...(typeName && {beverageType: {name: typeName}}),
        producerId: producerId,
        filterString: filterString,
        limit: limit,
        offset: offset,
      },
      skip: skip || (limit === 0),
      //fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        if (data.beverages != null) {
          setItems(data.beverages.map(r => Item.fromGraphQL(r)));
        }
      }
    });

  return {
    items,
    gettingItems: loading,
    listError: error?.message || null,
  };
}

export default useListBeveragesGraphQL;
