import React, { FunctionComponent, ReactNode, useState } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import SideBar from './SideBar';
import TopBar from './TopBar';
import Footer from './Footer';


interface Props {
  children?: ReactNode,
}

const Main: FunctionComponent<Props> = props => {
  const {children} = props;

  const theme = useTheme();
  const styles = {
    root: {
      paddingTop: '72px',
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        paddingTop: '72px'
      }
    },
    shiftContent: {
      paddingLeft: '240px'
    },
    content: {
      height: '100%'
    }
  };

  const [openSideBar, setOpenSideBar] = useState(false);

  const handleSideBarOpen = () => {
    setOpenSideBar(true);
  };

  const handleSideBarClose = () => {
    setOpenSideBar(false);
  };

  return (
    <Box sx={styles.root}>
      <TopBar
        onSideBarOpen={handleSideBarOpen}
      />
      <SideBar
        onClose={handleSideBarClose}
        open={openSideBar}
        variant={'temporary'}
      />
      <Box component='main' sx={styles.content}>
        {children}
        <Footer />
      </Box>
    </Box>
  );
};

export default Main;