import {PureQueryOptions, useMutation} from '@apollo/client';
import getPlaceQuery from '../queries/getPlaceQuery.graphql';
import listPlacesQuery from '../queries/listPlacesQuery.graphql';
import updateMutation from '../queries/updatePlaceMutation.graphql';
import {UpdatePlace, UpdatePlaceVariables} from '../types/UpdatePlace';
import deleteMutation from '../queries/deletePlaceMutation.graphql';
import {DeletePlace, DeletePlaceVariables} from '../types/DeletePlace';
import {Place} from '../../models';
import useGetPlaceGraphQL from './useGetPlaceGraphQL';
import {ListPlaces} from '../types/ListPlaces';

const Item = Place;
type Item = Place;
type UpdateItem = UpdatePlace;
type UpdateVariables = UpdatePlaceVariables;
type DeleteItem = DeletePlace;
type DeleteVariables = DeletePlaceVariables;


type Props = {
  currentId?: string|null,
  name?: string,
  typeName?: string,
  model?: { new (): Item; },
}

const useUpdatePlaceGraphQL = (props?: Props) => {

  const {currentId, name, typeName, model=Item} = {...props};
  const {item, gettingItem, getError} = useGetPlaceGraphQL({currentId: currentId, name: name, model:model})

  const [_updateItem, {loading: updatingItem, error: updateError}] =
    useMutation<UpdateItem, UpdateVariables>(updateMutation, {
      refetchQueries: () => {
        const queries: PureQueryOptions[] = [{
          query: listPlacesQuery,
          variables: {placeType: {name: typeName}}
        }];
        if (currentId != null) {
          queries.push({
            query: getPlaceQuery,
            variables: {place: {placeId: currentId, name: name}}
          });
        }
        return queries;
      }
  });

  const [_deleteItem, {error: deleteError}] =
    useMutation<DeleteItem, DeleteVariables>(deleteMutation, {
      update: (client) => {
        const data = client.readQuery<ListPlaces>({
          query: listPlacesQuery,
          variables: {placeType: {name: typeName}}
        });
        const newData = {
          places: data?.places?.filter((i) => i?.placeId !== currentId)
        }
        client.writeQuery({
          query: listPlacesQuery,
          variables: {placeType: {name: typeName}},
          data: newData
        });
      },
      refetchQueries: () => [{
        query: listPlacesQuery,
        variables: {placeType: {name: typeName}}
      }]
  });

  const updateItem = async (updatedItem: Item) => {
    if (updatedItem == null || _updateItem == null) {
      return;
    }
    const input = updatedItem.toInput(true);
    if (typeName != null && input.placeTypes == null) {
      input.placeTypes = [{name: typeName}];
    }
    await _updateItem({
      variables: {place: input},
    });
  }

  const deleteItem = async (deletedItem: Item, deleteReason?: string) => {
    if (deletedItem == null || _deleteItem == null) {
      return;
    }
    const input = deletedItem.toInput(true);
    await _deleteItem({
      variables: {place: input, deleteReason: deleteReason},
    });
  }

  return {
    item,
    gettingItem,
    getError,
    updateItem,
    updatingItem,
    updateError: updateError?.message || null,
    deleteItem,
    deleteError: deleteError?.message || null,
  };
}

export default useUpdatePlaceGraphQL;
