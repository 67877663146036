import React from 'react';
import ItemEditDialog from '../common/ItemEditDialog';
import { Producer } from '../../models';
import ProducerEdit from './ProducerEdit';


interface Props {
  producer?: Producer | null,
  model?: typeof Producer,
  typeName?: string,
  onClose?: (value: string) => void,
  onDelete?: () => Promise<void>,
  open?: boolean,
}


const ProducerEditDialog = (props: Props) => {

  const {
    producer, onClose, onDelete, open=false,
    model=Producer, typeName='Producer',
  } = props;

  return (
    <ItemEditDialog
      onClose={onClose}
      open={open}
      title={`Edit ${typeName}`}
    >
      <ProducerEdit
        producer={producer}
        model={model}
        typeName={typeName}
        onDelete={onDelete}
      />
    </ItemEditDialog>
  );
};

export default ProducerEditDialog;
