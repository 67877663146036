import React from 'react';
import { useNavigate } from 'react-router-dom';

import CardGrid, { CardGridProps } from '../common/CardGrid';
import { Beverage } from '../../models';

import BeverageCard from './BeverageCard';
import useListBeveragesGraphQL from '../../graphql/hooks/useListBeveragesGraphQL';


type Props = CardGridProps & {
  categoryName?: string,
  onEdit?: (item: Beverage|null) => void,
  onSelect?: (item: Beverage|null) => void,
}

const BeveragesCardGrid = (props: Props) => {
  //console.log('Refreshing BeveragesCardGrid');

  const { categoryName, onEdit, onSelect, ...cardGridProps } = props;
  const navigate = useNavigate();

  const displayCategoryName = categoryName || 'Beverage';

  const handleNewBeverage = () => {
    navigate(`/${displayCategoryName.toLowerCase()}-edit/new`)
  }

  return (
    <CardGrid
        card={<BeverageCard/>}
        useListGraphQL={useListBeveragesGraphQL}
        useListGraphQLProps={{categoryName: categoryName}}
        name={displayCategoryName}
        pluralName={displayCategoryName+'s'}
        onAddClick={handleNewBeverage}
        onEdit={onEdit}
        onSelect={onSelect}
        {...cardGridProps}
    />
  );
};

export default BeveragesCardGrid;
