import React from 'react';
import {
  Box,
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { SxProps, Theme, useTheme } from '@mui/material/styles';

import { SelectEvent } from '../../helpers/useFormValidation';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface MultiSelectItem {
  id?: string|null;
  name?: string|null;
  picklistName?: string;
  [key: string]: any;
}

function getStyles(item: string, items: string[], theme: Theme) {
  return {
    fontWeight:
      items.some(i => i === item)
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface Props<T> {
  sx?: SxProps,
  id?: string,
  items?: T[],
  label?: string|null,
  name: string,
  noForm?: boolean,
  onChange?: (values: T[]) => void,
  ref?: any,
  values?: T[] | null,
}

const MultiSelect = <T extends MultiSelectItem | string>(props: Props<T>) => {

  const {sx, id, items=[], label, name, noForm=false,
    onChange, ref, values=[],
  } = props;

  const theme = useTheme();
  const styles = {
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      //maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
  };

  const handleChange = (event: SelectEvent) => {
    if (onChange != null && items != null) {
      const selected = event.target.value;
      let matches: T[] | undefined;
      matches = (items as MultiSelectItem[]).filter(
        item => item.picklistName && selected.includes(item.picklistName)
      ) as T[]|undefined;
      if (matches != null && matches.length > 0) {
        onChange(matches);
        return;
      }
      matches = (items as MultiSelectItem[]).filter(
        item => item.name && selected.includes(item.name)
      ) as T[]|undefined;
      if (matches != null && matches.length > 0) {
        onChange(matches);
      }
      matches = (items as string[]).filter(
        item => selected.includes(item)
      ) as T[]|undefined;
      if (matches != null && matches.length > 0) {
        onChange(matches);
      }
    }
  };

  const valuesStrings = values?.map((value, i) =>
    (value as MultiSelectItem)?.picklistName
    || (value as MultiSelectItem)?.name
    || value as string
    || ''
  );

  const _id = id || name;

  const selectElement =
        <Select
          id={`${_id}-select`}
          labelId={`${_id}-label`}
          name={name}
          multiple
          value={valuesStrings}
          onChange={(event) =>
              handleChange(event as SelectEvent)}
          input={<Input id={`${_id}-input`} />}
          renderValue={selected => (
            <Box sx={styles.chips}>
              {(selected as string[]).map((val, i) => (
                <Chip key={i} label={val} sx={styles.chip} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {items?.map((item, i) => {
            const itemString =
              (item as MultiSelectItem)?.picklistName
              || (item as MultiSelectItem)?.name
              || item as string
              || '';
            return (
              <MenuItem key={i} value={itemString}
                        style={getStyles(itemString, valuesStrings||[], theme)}>
                {itemString}
              </MenuItem>
            );
          })}
        </Select>

  if (noForm) {
    return selectElement;
  }

  return (
    <div id={_id} ref={ref}>
      <FormControl sx={[styles.formControl, ...(Array.isArray(sx) ? sx : [sx]),]}>
        <InputLabel id={`${_id}-label`}>
          {label}
        </InputLabel>
        {selectElement}
      </FormControl>
    </div>
  );
};

export default MultiSelect;
