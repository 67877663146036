import {IngredientDetails} from '../graphql/types/IngredientDetails';
import {IngredientInput} from '../graphql/types/globalTypes';
import {MapType} from './Base';
import {IngredientType} from './IngredientType';
import {VersionedItem} from './VersionedItem';


export class Ingredient extends VersionedItem {
  ingredientId?: string;
  picklistName?: string;
  description?: string;
  flavor?: string;
  aliases?: string[];
  creator?: string;
  links?: MapType;
  properties?: MapType;
  ingredientType?: IngredientType | null;

  get currentId() {
    return this.ingredientId;
  }

  setFromGraphQL(data: Partial<IngredientDetails>|null) {
    if (data == null) return;

    super.setFromGraphQL(data);
    this.ingredientId = data.ingredientId == null ? undefined : data.ingredientId;
    this.picklistName = data.picklistName == null ? undefined : data.picklistName;
    this.description = data.description == null ? undefined : data.description;
    this.flavor = data.flavor == null ? undefined : data.flavor;
    this.aliases = data.aliases == null ? undefined : data.aliases.filter(a => a != null) as string[];
    this.creator = data.creator == null ? undefined : data.creator;
    this.links = data.links;
    this.properties = data.properties;
    this.ingredientType = data.ingredientType == null ? null : IngredientType.fromGraphQL(data.ingredientType);
  }

  toInput(allProps=false): IngredientInput {
    const input = super.toInput(allProps);
    if (allProps) {
      return {
        ...input,
        ingredientId: this.ingredientId,
        picklistName: this.picklistName,
        description: this.description,
        flavor: this.flavor,
        aliases: this.aliases,
        creator: this.creator,
        links: JSON.stringify(this.links),
        properties: JSON.stringify(this.properties),
        ingredientType: this.ingredientType?.toInput(),
      };
    } else {
      return {
        ...input,
        ingredientId: this.ingredientId,
      };
    }
  }

  validate() {
    if (!super.validate()) {
      return false;
    }
    if (this.name == null || this.name === '') {
      return false;
    }
    if (this.picklistName == null || this.picklistName === '') {
      return false;
    }
    return true;
  }
}
