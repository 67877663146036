import React, {
  cloneElement,
  FunctionComponent, ReactElement,
  ReactNode,
  useContext,
  useState
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ItemView, {BUTTON_VARIANT } from '../common/ItemView';
import LabeledLinks from '../common/LabeledLinks';
import LabeledText from '../common/LabeledText';
import LabeledURL from '../common/LabeledURL';
import { AuthContext } from '../../contexts';
import useGetBeverageGraphQL from '../../graphql/hooks/useGetBeverageGraphQL';
import { Beverage } from '../../models';

import BeverageEditDialog from './BeverageEditDialog';
import LabeledMap from '../common/LabeledMap';


interface Props {
  beverage?: Beverage | null,
  id?: string|null,
  model?: typeof Beverage,
  typeName?: string,
  additionalFieldRenderer?: (item: Beverage|null) => ReactNode,
  editDialog?: ReactNode,
}

const BeverageView: FunctionComponent<Props> = props => {

  const {
    beverage, id,
    model=Beverage, typeName='Beverage',
    additionalFieldRenderer, editDialog,
  } = props;

  //console.log('BeverageView', beverage);

  const theme = useTheme();

  const {canContribute} = useContext(AuthContext);

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleClickEdit = (item: Beverage|null) => {
    /*
    if (item?.id != null) {
      history.push(`/beverage-edit/${item.id}`)
    }
    */
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const EditDialog = editDialog == null
    ? BeverageEditDialog
    : (props: any) => {
      return cloneElement(editDialog as ReactElement<any>, {
        beverage: props.beverage,
        typeName: typeName,
        model: model,
        open: openDialog,
        onClose: handleCloseDialog,
      });
    }

  const renderView = (item: Beverage|null) => {

    return (
      <Box sx={theme.itemView.root}>
        <Box sx={theme.itemView.header}>
          <Box sx={theme.itemView.leftCentered}>
            {item?.logoUrls &&
              <Box component='img' sx={theme.itemView.image}
                src={item?.logoUrls['400px']}
                alt='Logo'
              />
            }
          </Box>
          <Box sx={theme.itemView.info}>
            <Box sx={theme.itemView.leftCentered}>
              <Typography sx={theme.itemView.title} variant='h2'>
                {item?.name}
              </Typography>
              <Typography sx={theme.itemView.body} variant='body1'>
                {item?.style}
              </Typography>
              {item?.producer &&
                <Typography sx={theme.itemView.body} variant='body1'>
                  <Link component={RouterLink} to={`/producer/${item?.producer.id}`}>
                    {item?.producer?.name}
                  </Link>
                </Typography>
              }
              <Typography sx={theme.itemView.body} variant='body1' color='textSecondary'>
                {item?.description}
              </Typography>
            </Box>
            {canContribute &&
              <Box sx={theme.itemView.editButton}>
                  <Button color='primary'
                          variant={BUTTON_VARIANT}
                          onClick={() => handleClickEdit(item)}>
                    {`Edit This ${typeName}`}
                  </Button>
                  <EditDialog
                      beverage={item}
                      typeName={typeName}
                      model={model}
                      open={openDialog}
                      onClose={handleCloseDialog}
                  />
              </Box>
            }
          </Box>
        </Box>
        <LabeledText
          label='Style Type'
          text={item?.beverageType?.name}
        />
        <LabeledURL
          label='Website'
          url={item?.website}
        />
        <LabeledLinks
          label='Links'
          links={item?.links}
        />
        {additionalFieldRenderer && additionalFieldRenderer(item)}
        {item?.properties && item.properties.length > 0 &&
          <LabeledMap
            label='Properties'
            map={item?.properties}
          />
        }
      </Box>
    );
  };

  return (
    <ItemView
      item={beverage}
      id={id}
      useGetGraphQL={useGetBeverageGraphQL}
      useGetGraphQLProps={{model: model}}
      viewRenderer={renderView}
    />
  )
};

export default BeverageView;
