import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { DeleteReason } from './ItemEdit';
import { createUniqueId } from '../../helpers/createUniqueId';
import EditButtons from './EditButtons';

type MaxWidth = false | 'xl' | 'xs' | 'sm' | 'md' | 'lg' | undefined;

interface Props {
  onClose?: (value: string) => void,
  open?: boolean,
  title?: string,
  hideDelete?: boolean,
  disableSave?: boolean,
  fullWidth?: boolean,
  maxWidth?: MaxWidth,
  children?: ReactNode,
}

const ItemEditDialog: FunctionComponent<Props> = (props) => {

  const {children, onClose, open=false, title, hideDelete=false, disableSave=false,
    fullWidth, maxWidth} = props;

  const [formId, setFormId] = useState<string>('itemForm');
  useEffect(() => {
    setFormId(createUniqueId());
  }, []);

  const [deleteReason, setDeleteReason] = useState<DeleteReason|null>(null);
  const [error, setError] = useState<string|null>(null);
  const handleError = (message: string) => {
    setError(message);
  };

  const handleClose = () => {
    onClose && onClose('Closed');
  };

  const handleDeleteReasonChange = (reason: DeleteReason) => {
    setDeleteReason(reason);
  }

  return (
    <div>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='item-edit-dialog-title'
          maxWidth={maxWidth}
          fullWidth={fullWidth}
      >
        <DialogTitle id='item-edit-dialog-title'>
          {title}
        </DialogTitle>
        <DialogContent>
          {React.Children.map(children, child =>
            React.cloneElement(child as React.ReactElement, {
              deleteReason: deleteReason,
              formId: formId,
              onComplete: handleClose,
              onError: handleError,
              hideButtons: true,
              hideError: true,
              ignoreParamId: true,
            })
          )}
        </DialogContent>
        <DialogActions>
          <EditButtons
            errorMessage={error}
            formId={formId}
            onCancel={handleClose}
            onDeleteRequested={handleDeleteReasonChange}
            hideDelete={hideDelete}
            disableSave={disableSave}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ItemEditDialog;
