import React, { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';


interface Props {
}

const ComingSoon: FunctionComponent<Props> = props => {

  const theme = useTheme();
  const styles = {
    title: {
      marginTop: theme.spacing(3)
    },
    body: {
      marginTop: theme.spacing(2)
    },
    error: {
      color: theme.palette.error.main
    }
  };

  return (
    <div>
      <Typography  sx={styles.title} variant='h2'>
        Coming Soon
      </Typography>
      <Typography  sx={styles.body} variant='body1'>
        This feature is currently under development.
      </Typography>
    </div>
  );
};

export default ComingSoon;
