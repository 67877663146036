import {
  applyActionCode,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
  Auth as FirebaseAuth,
  FacebookAuthProvider, GoogleAuthProvider,
  Unsubscribe,
  User as FirebaseUser,
} from 'firebase/auth';

import {User} from '../models';

import {client} from '../client';
import currentUserQuery from '../graphql/queries/currentUserQuery.graphql';
import createUserMutation from '../graphql/queries/createUserMutation.graphql';
import {CurrentUser} from '../graphql/types/CurrentUser'
import {CreateUser} from '../graphql/types/CreateUser'


const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: `https://${process.env.REACT_APP_DOMAIN}/verify-sign-in?mode=<action>&oobCode=<code>`,
  // This must be true.
  handleCodeInApp: true,
  //iOS: {
  //  bundleId: 'org.bevdb.ios'
  //},
  //android: {
  //  packageName: 'org.bevdb.android',
  //  installApp: true,
  //  minimumVersion: '12'
  //},
  //dynamicLinkDomain: 'example.page.link'
};

export class EmailNotVerifiedError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.name = EmailNotVerifiedError.name; // stack traces display correctly now
  }
}

export class AuthService {

  _firebaseAuth: FirebaseAuth = getAuth();
  fbUser: FirebaseUser|null = null;
  _currentUserSetter?: (user: User|null) => void;

  constructor(currentUserSetter?: (user: User|null) => void) {
    this._currentUserSetter = currentUserSetter;
    this.getUserFromLocalStorage();
  }

  async _userFromFirestore(fbUser: FirebaseUser|null) {
    console.log(`fbUser: ${fbUser && fbUser.uid}`);
    if (fbUser == null) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this._currentUserSetter && this._currentUserSetter(null);
      return null;
    }
    if (!fbUser.emailVerified) {
      console.log('Email not verified');
      this.fbUser = fbUser;
      this._currentUserSetter && this._currentUserSetter(null);
      throw new EmailNotVerifiedError();
    }
    localStorage.setItem('user', JSON.stringify(fbUser));
    if (fbUser.getIdToken) {
      localStorage.setItem('token', await fbUser.getIdToken());
    }
    //console.log('token', localStorage.getItem('token'));

    const {data} = await client.query<CurrentUser>({
      query: currentUserQuery,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    });
    let currentUser = data?.currentUser;
    console.log('currentUser', currentUser);
    if (currentUser == null) {
      const {data: createUserData} = await client.mutate<CreateUser>({
        mutation: createUserMutation});
      currentUser = createUserData?.createUser || null;
      if (currentUser == null) {
        this._currentUserSetter && this._currentUserSetter(null);
        return  null;
      }
    }
    const user = User.fromGraphQL(currentUser);
    this._currentUserSetter && this._currentUserSetter(user);
    return user;
  }

  get userStream(): Unsubscribe {
    return this._firebaseAuth.onAuthStateChanged(this._userFromFirestore);
  }

  get onAuthStateChanged(): Unsubscribe {
    return this._firebaseAuth.onAuthStateChanged(this._userFromFirestore);
  }

  async getUserFromLocalStorage() {
    const user = localStorage.getItem('user');
    console.log('getUserFromLocalStorage', user);
    if (user != null) {
      try {
        await this._userFromFirestore(JSON.parse(user));
        console.log('getUserFromLocalStorage complete');
      } catch (e) {
        localStorage.removeItem('user');
        console.log('getUserFromLocalStorage Error', e);
      }
    }
  }

  async signUpWithEmail(name: string, email: string, password: string) {
    try {
      const result = await createUserWithEmailAndPassword(this._firebaseAuth, email, password);
      if (result.user) {
        const user = result.user;
        await updateProfile(user,{displayName: name});
        await this._firebaseAuth.updateCurrentUser(user);
        await sendEmailVerification(user, actionCodeSettings);
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
      }
    } catch (e) {
      console.log('signUpEmail Error', e);
      throw e;
    }
  }

  async sendVerificationEmail(fbUser: FirebaseUser) {
    await this._firebaseAuth.updateCurrentUser(fbUser);
  }

  async confirmSignUpEmailLink(email: string, password: string) {
    // Confirm the link is a sign-in with email link.
    console.log('Verify URL:', window.location.href);
    //const params = new URL(window.location.href).searchParams;
    // @ts-ignore
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    console.log('params', params);
    const oobCode = params.get('oobCode');
    console.log('oobCode', oobCode);
    if (oobCode != null) {
      const result = await applyActionCode(this._firebaseAuth, oobCode);
      console.log('action code result', result);
      await this.signInWithEmailAndPassword(email, password)
    }
    /*
    if (this._firebaseAuth.isSignInWithEmailLink(window.location.href)) {
      console.log("is Valid:", this._firebaseAuth.isSignInWithEmailLink(window.location.href));
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      try {
        // The client SDK will parse the code from the link for you.
        const result = await this._firebaseAuth.signInWithEmailLink(email, window.location.href);
        console.log("signInWithEmailLink result", result);
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
        console.log("signInWithEmailLink user", result.user);
        this._userFromFirestore(result.user);
      } catch (e) {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        console.log("confirmSignUpEmailLink Error", e);
        throw e;
      }
    }
    */
  }

  signInWithEmailAndPassword(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return signInWithEmailAndPassword(this._firebaseAuth, email, password)
        .then((user) => {
          console.log('firebase user Credentials', user);
          resolve(this._userFromFirestore(user.user))
        })
        .catch((err) => {console.log('reject');reject(err);console.log('post');});
    });
  }

  async signInWithGoogle() {
    try {
      let provider = new GoogleAuthProvider();
      const result = await signInWithPopup(this._firebaseAuth, provider);
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const token = result.credential!.providerId;
      // The signed-in user info.
      const user = result.user;
      return this._userFromFirestore(user);
    } catch (e) {
      console.log('signInWithGoogle Error', e);
      throw e;
    }
  }

  async signInWithFacebook() {
    try {
      let provider = new FacebookAuthProvider();
      const result = await signInWithPopup(this._firebaseAuth, provider);
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      // const token = result.credential!.providerId;
      // The signed-in user info.
      const user = result.user;
      return this._userFromFirestore(user);
    } catch (e) {
      console.log('signInWithFacebook Error', e);
      throw e;
    }
  }

  async sendPasswordResetEmail(email: string) {
    await sendPasswordResetEmail(this._firebaseAuth, email);
  }

  async signOut() {
    localStorage.removeItem('user');
    this._currentUserSetter && this._currentUserSetter(null);
    return this._firebaseAuth.signOut();
  }
}
