import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Theme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { AuthProvider, PicklistsProvider } from './contexts';
import AllRoutes from './routes/Routes';
import theme from './theme';

import { ApolloProvider } from '@apollo/client';
import { client } from './client';


/*
declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
*/


function App() {
  return (
    <div className='App'>
      <AuthProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <ApolloProvider client={client}>
            <PicklistsProvider>
              <Router>
                <AllRoutes />
              </Router>
            </PicklistsProvider>
            </ApolloProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
