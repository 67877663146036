import {Item} from './Item';
import {IngredientTypeDetails} from '../graphql/types/IngredientTypeDetails';
import {IngredientTypeInput} from '../graphql/types/globalTypes';


export class IngredientType extends Item {
  description?: string|null;

  setFromGraphQL(data: Partial<IngredientTypeDetails>|null) {
    super.setFromGraphQL(data);
    if (data != null) {
      this.description = data.description == null ? undefined : data.description;
    }
  }

  toInput(allProps=false): IngredientTypeInput {
    const input = super.toInput(allProps);
    if (allProps) {
      return {
        ...input,
        description: this.description,
      };
    }
    if (this.id == null) {
      return {
        name: this.name,
      };
    }
    return input;
  }

  validate() {
    if (!super.validate()) {
      return false;
    }
    if (this.name == null || this.name === '') {
      return false;
    }
    return true;
  }
}
