import React, {
  cloneElement,
  FunctionComponent, ReactElement,
  ReactNode,
  useContext,
  useState
} from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ItemView from '../common/ItemView';
import LabeledLinks from '../common/LabeledLinks';
import LabeledMap from '../common/LabeledMap';
import LabeledURL from '../common/LabeledURL';
import LocationView from '../common/LocationView';
import { AuthContext } from '../../contexts';
import useGetProducerGraphQL from '../../graphql/hooks/useGetProducerGraphQL';
import { Beverage, Producer } from '../../models';

import { BeverageEditDialog } from '../Beverage';
import ProducerEditDialog from './ProducerEditDialog';
import BeverageCoresGrid from '../Beverage/BeverageCoresGrid';


interface Props {
  producer?: Producer | null,
  id?: string|null,
  model?: typeof Producer,
  typeName?: string,
  beverageTypeName?: string,
  additionalFieldRenderer?: (item: Producer|null) => ReactNode,
  editDialog?: ReactNode,
}

const ProducerView: FunctionComponent<Props> = props => {

  const {
    producer, id,
    model=Producer, typeName='Producer', beverageTypeName='Beverage',
    additionalFieldRenderer, editDialog,
  } = props;

  //console.log('ProducerView', producer);
  const navigate = useNavigate();

  const theme = useTheme();
  const styles = {
    beverageList: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
    },
    beverageListHeader: {
      display: 'inline-flex',
      width: '100%',
    },
    beverageListTitle: {
      display: 'inline-flex',
      flex: 1,
      justifyContent: 'space-between',
      paddingTop: 5,
      paddingBottom: 0,
      marginTop: 5,
      marginBottom: 5,
    },
    addBeverage: {
      float: 'inline-end',
      justifyContent: 'flex-end',
      marginTop: 10,
      marginRight: 10,
    }
  };

  const {canContribute} = useContext(AuthContext);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openBeverageDialog, setOpenBeverageDialog] = useState<boolean>(false);

  const handleClickEdit = (item: Producer|null) => {
    /*
    if (item?.id != null) {
      history.push(`/producer-edit/${item.id}`)
    }
    */
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const EditDialog = editDialog == null
    ? ProducerEditDialog
    : (props: any) => {
      return cloneElement(editDialog as ReactElement<any>, {
        producer: props.producer,
        typeName: typeName,
        model: model,
        open: openDialog,
        onClose: handleCloseDialog,
      });
    }

  const handleClickOpenBeverageDialog = () => {
    setOpenBeverageDialog(true);
  };

  const handleCloseBeverageDialog = () => {
    setOpenBeverageDialog(false);
  };

  const handleBeverageClick = (beverage: Beverage|null) => {
    console.log('BEVERAGE TYPE', beverage?.beverageType?.category?.name);
    if (beverage == null) {
      return;
    }
    switch (beverage.beverageType?.category?.name) {
      case 'Beer':
        navigate(`/beer/${beverage.beverageId}`);
        break;
      case 'Seltzer':
        navigate(`/seltzer/${beverage.beverageId}`);
        break;
      default:
        navigate(`/beverage/${beverage.beverageId}`);
    }
  };

  const renderView = (item: Producer|null) => {
    return (
      <Box sx={theme.itemView.root}>
        <Box sx={theme.itemView.header}>
          <Box sx={theme.itemView.leftCentered}>
            {item?.logoUrls &&
              <Box component='img' sx={theme.itemView.image}
                src={item?.logoUrls['400px']}
                alt='Logo'
              />
            }
          </Box>
          <Box sx={theme.itemView.info}>
            <Box sx={theme.itemView.leftCentered}>
              <Typography sx={theme.itemView.title} variant='h5'>
                {item?.name}
              </Typography>

              <Typography sx={theme.itemView.body} variant='body2' color='textSecondary'>
                {item?.description}
              </Typography>

              {item?.locations && item?.locations.map((location, i) =>
                <LocationView location={location} key={i} labeled/>
              )}
              <LabeledURL
                label='Website'
                url={item?.website}
              />
              <LabeledLinks
                label='Links'
                links={item?.links}
              />
              {additionalFieldRenderer && additionalFieldRenderer(item)}
              {item?.properties && item.properties.length > 0 &&
                <LabeledMap
                  label='Properties'
                  map={item?.properties}
                />
              }
            </Box>
            {canContribute &&
              <Box sx={theme.itemView.editButton}>
                <Button color='primary'
                        variant='outlined'
                        onClick={() => handleClickEdit(item)}>
                  {`Edit This ${typeName}`}
                </Button>
                <EditDialog
                  producer={item}
                  model={model}
                  typeName={typeName}
                  open={openDialog}
                  onClose={handleCloseDialog}
                />
              </Box>
            }
          </Box>
        </Box>

        <Box sx={styles.beverageList}>
          <Box sx={styles.beverageListHeader}>
            <Typography sx={styles.beverageListTitle} variant='h4'>
              {`${beverageTypeName}s`}
            </Typography>
            {canContribute &&
              <Box sx={styles.addBeverage}>
                  <Button color='primary'
                          variant='outlined'
                          onClick={handleClickOpenBeverageDialog}>
                    {`Add A Beverage`}
                  </Button>
                  <BeverageEditDialog
                      producer={item?.toCore()}
                      open={openBeverageDialog}
                      onClose={handleCloseBeverageDialog}
                  />
              </Box>
            }
          </Box>
          <Box>
            <BeverageCoresGrid
              producer={item}
              limit={50}
              onSelect={beverage => handleBeverageClick(beverage)}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <ItemView
      item={producer}
      id={id}
      useGetGraphQL={useGetProducerGraphQL}
      useGetGraphQLProps={{model: model}}
      viewRenderer={renderView}
    />
  )
};

export default ProducerView;
