import React, {useEffect, useState} from 'react';
import {PicklistItem} from '../models';
import DuplicatesDialog from '../components/common/DuplicateDialog';


function useListDuplicateDialog(
  list: PicklistItem[]|null,
  message: string,
  onDuplicate?: () => void,
  createItemDescription?: (item: PicklistItem) => string,
) {

  const [duplicateSearchString, setDuplicateSearchString] = useState<string>('');
  const [openDuplicateDialog, setOpenDuplicateDialog] = useState<boolean>(false);
  const [duplicateItems, setDuplicateItems] = useState<PicklistItem[]|null>(null);

  useEffect(() => {
    if (duplicateSearchString != null && duplicateSearchString !== '') {
      const dupes = list?.filter(
        item => item.name?.toLowerCase().includes(duplicateSearchString.toLowerCase()));
      setDuplicateItems(dupes || null);
    }
  }, [duplicateSearchString, list]);

  useEffect(() => {
    if (duplicateItems != null && duplicateItems.length > 0) {
        setOpenDuplicateDialog(true);
    }
  }, [duplicateItems]);

  const handleItemDescription = (item: PicklistItem) => {
    if (createItemDescription != null) {
      return createItemDescription(item);
    } else {
      if (item != null) {
        return `${item.name}`;
      }
      return '';
    }
  }

  const handleDuplicate = () => {
    console.log('Duplicate reported');
    setOpenDuplicateDialog(false);
    onDuplicate && onDuplicate();
  };

  const handleDuplicateDialogClose = () => {
    setOpenDuplicateDialog(false);
  };

  const duplicatesDialog = (
    <DuplicatesDialog
      onClose={handleDuplicateDialogClose}
      onDuplicate={handleDuplicate}
      open={openDuplicateDialog}
      items={duplicateItems}
      message={message}
      itemDescription={handleItemDescription}
    />
  );

  return {
    duplicatesDialog,
    duplicateItems,
    handleDuplicate,
    handleDuplicateDialogClose,
    openDuplicateDialog,
    setDuplicateSearchString,
  };
}

export default useListDuplicateDialog;
