import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';


interface Props {
  label?: string,
  url?: string,
}


const LabeledURL = (props: Props) => {

  const { label, url } = props;

  const theme = useTheme();

  const [_url, setURL] = useState<string>(url || '');

  useEffect(() => {
    if (url && !url.startsWith('http')) {
      setURL('http://' + url);
    }
  }, [url]);

  return (
    <Box>
      <Box sx={theme.itemView.textBlock}>
        <Typography sx={theme.itemView.label} variant='h6'>
          {label}:
        </Typography>
          <a href={_url} target='_blank' rel='noopener noreferrer'>
            <Typography sx={theme.itemView.text} variant='body1'>
                {url}
            </Typography>
          </a>
      </Box>
    </Box>
  )
};

export default LabeledURL;
