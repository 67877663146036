import {useState} from 'react';

import {useQuery} from '@apollo/client';
import getBeverageTypeQuery from '../queries/getBeverageTypeQuery.graphql';
import {GetBeverageType, GetBeverageTypeVariables} from '../types/GetBeverageType';

import {BeverageType} from '../../models';

const Item = BeverageType;
const getQuery = getBeverageTypeQuery;
type GetItem = GetBeverageType;
type GetVariables = GetBeverageTypeVariables;
type Item = BeverageType;


type Props = {
  currentId?: string|null,
  name?: string,
  model?: { new (): Item; },
}

const useGetBeverageTypeGraphQL = (props?: Props) => {

  const {currentId, name, model=Item} = {...props};

  const [item, setItem] = useState<Item|null>(null);

  const {loading, error} = useQuery<GetItem, GetVariables>(getQuery, {
    variables: {beverageType: {beverageTypeId: currentId, name: name}},
    fetchPolicy: 'cache-and-network',
    skip: currentId == null && name == null,
    onCompleted: data => {
      if (data.beverageType != null) {
        const newItem = new model();
        newItem.setFromGraphQL(data.beverageType);
        setItem(newItem);
      }
    },
  });

  return {
    item,
    gettingItem: loading,
    getError: error?.message || null,
  };
}

export default useGetBeverageTypeGraphQL;
