import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import LabeledURL from './LabeledURL';
import facebookLogo from '../../icons/FacebookIcon.png';
import googleMapsLogo from '../../icons/GoogleMapsIcon.svg';
import instagramLogo from '../../icons/InstagramIcon.png';
import twitterLogo from '../../icons/TwitterIcon.png';


type LinksType = {[key: string]: string};

const pop = (obj: LinksType, key: string) => {
  if (obj.hasOwnProperty(key)) {
    const value = obj[key];
    delete obj[key];
    return value;
  }
  return null;
}

const styles = {
  icon: {
    height: 30,
    width: 30,
    marginRight: 15,
  },
};


interface Props {
  label?: string,
  links?: LinksType,
}

const LabeledLinks = (props: Props) => {

  const { label, links } = props;

  const theme = useTheme();

  const _links: LinksType = {...links}

  const googleMapsLink = pop(_links, 'Google Maps');
  const facebookLink = pop(_links, 'Facebook');
  const twitterLink = pop(_links, 'Twitter');
  const instagramLink = pop(_links, 'Instagram');

  return (
    <Box>
      <Box sx={theme.itemView.textBlock}>
        {label &&
          <Typography sx={theme.itemView.label} variant='h6'>
            {label}
          </Typography>
        }
        <Box sx={theme.itemView.text}>
          {googleMapsLink &&
            <a href={googleMapsLink} target='_blank' rel='noopener noreferrer'>
              <Box component='img' src={googleMapsLogo} alt='Google Maps link' sx={styles.icon}/>
            </a>
          }
          {facebookLink &&
            <a href={facebookLink} target='_blank' rel='noopener noreferrer'>
              <Box component='img' src={facebookLogo} alt='Facebook link' sx={styles.icon}/>
            </a>
          }
          {twitterLink &&
            <a href={twitterLink} target='_blank' rel='noopener noreferrer'>
              <Box component='img' src={twitterLogo} alt='Twitter link' sx={styles.icon}/>
            </a>
          }
          {instagramLink &&
            <a href={instagramLink} target='_blank' rel='noopener noreferrer'>
              <Box component='img' src={instagramLogo} alt='Instagram link' sx={styles.icon}/>
            </a>
          }
          {_links && Object.keys(_links).map((name, i) => (
            <div key={i}>
              <LabeledURL
                label={name}
                url={_links[name]}
              />
            </div>
          ))}
        </Box>
      </Box>
    </Box>
  )
};

export default LabeledLinks;
