import React from 'react';
import {
  Box,
  Card, CardActionArea, CardMedia, CardContent,
  Grid,
  Typography
} from '@mui/material';

import { Beverage, Producer, ProducerCore } from '../../models';
import useListBeveragesGraphQL
  from '../../graphql/hooks/useListBeveragesGraphQL';


const styles = {
  root: {
    marginLeft: 10,
  },
  gridList: {
  },
  gridListTile: {
  },
  card: {
    width: 200,
  },
  cardMedia: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  image: {
    height: 140,
    maxWidth: '100%',
    objectFit: 'contain',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
};

interface Props {
  producer?: Producer|ProducerCore|null,
  limit?: number,
  onSelect?: (item: Beverage|null) => void,
}

const BeverageCoresGrid = (props: Props) => {
  //console.log('Refreshing BeveragesCardGrid');

  const { producer, limit=15, onSelect } = props;

  const {items: graphQLItems} = useListBeveragesGraphQL({
    producerId: producer?.producerId,
    limit: limit,
  });

  const handleClick = (beverage: Beverage) => {
    onSelect && onSelect(beverage);
  };

  return (
    <Box sx={styles.root}>
       <Grid container sx={styles.gridList} spacing={1}>
        {graphQLItems?.map(item => {
          const logoUrl = item.logoUrls && item.logoUrls['400px'];
          return (
            <Grid item
                  key={item.id}
                  sx={styles.gridListTile}
            >
              <Card sx={styles.card}>
                <CardActionArea
                  onClick={() => handleClick(item)}
                >
                  {logoUrl != null
                    ? <CardMedia
                        sx={styles.cardMedia}
                        title={item.name}
                      >
                        <Box component='img' sx={styles.image} alt='logo' src={logoUrl}/>
                      </CardMedia>
                    : <Box sx={styles.image}/>
                  }
                  <CardContent>
                    <Typography gutterBottom variant='h5'>
                      {item.name}
                    </Typography>
                    <Typography variant='body2' color='textSecondary'
                                component='p'>
                      {item.style}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
};

export default BeverageCoresGrid;
