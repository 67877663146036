import {useState} from 'react';

import {useQuery} from '@apollo/client';
import getIngredientQuery from '../queries/getIngredientQuery.graphql';
import {GetIngredient, GetIngredientVariables} from '../types/GetIngredient';

import {Ingredient} from '../../models';

const Item = Ingredient;
const getQuery = getIngredientQuery;
type GetItem = GetIngredient;
type GetVariables = GetIngredientVariables;
type Item = Ingredient;

/*
const buildVariables = (id?: string|null, name?: string|null): GetVariables|null => {
  if (id != null) {
    return {ingredient: {ingredientId: id}}
  }
  if (name != null) {
    return {ingredient: {name: name}}
  }
  return null;
}
*/

type Props = {
  currentId?: string|null,
  name?: string,
  model?: { new (): Item; },
}

const useGetIngredientGraphQL = (props?: Props) => {

  const {currentId, name, model=Item} = {...props};

  const [item, setItem] = useState<Item|null>(null);

  //const [getItem, {loading, error, data}] = useLazyQuery<GetItem, GetVariables>(getQuery);
  //const getResult = data?.ingredient;
  const {loading, error} = useQuery<GetItem, GetVariables>(getQuery, {
    variables: {ingredient: {ingredientId: currentId, name: name}},
    fetchPolicy: 'cache-and-network',
    skip: currentId == null && name == null,
    onCompleted: data => {
      if (data.ingredient != null) {
        const newItem = new model();
        newItem.setFromGraphQL(data.ingredient);
        setItem(newItem);
      }
    },
  });

  /*
  useEffect(() => {
    //console.log('ingredient variable changed', id, name);
    const variables = buildVariables(id, name);
    if (variables != null) {
      getItem({variables: variables});
    }
  }, [id, name, getItem]);

  useEffect(() => {
    //console.log('getResult changed', getResult);
    if (getResult != null && model) {
      const newItem = new model();
      newItem.setFromGraphQL(getResult);
      setItem(newItem);
    }
  }, [getResult, model]);
  */

  return {
    item,
    gettingItem: loading,
    getError: error?.message || null,
  };
}

export default useGetIngredientGraphQL;
