import {useState} from 'react';
import {sortBy} from 'lodash';
import {useQuery} from '@apollo/client';
import listIngredientsQuery from '../queries/listIngredientsQuery.graphql';
import {ListIngredients, ListIngredientsVariables} from '../types/ListIngredients';

import {Ingredient} from '../../models';

const listQuery = listIngredientsQuery;
const Item = Ingredient;
type ListItems = ListIngredients;
type ListVariables = ListIngredientsVariables;
type Item = Ingredient;


type Props = {
  ingredientTypeName: string,
  sortByAttributeName?: string,
}

const useListIngredientsGraphQL = (props?: Props) => {

  const {ingredientTypeName, sortByAttributeName} = {...props};

  const [items, setItems] = useState<Item[]>([]);

  const {loading, error, } =
    useQuery<ListItems, ListVariables>(listQuery, {
      variables: {ingredientType: {name: ingredientTypeName}},
      //fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        if (data.ingredients != null) {
          let ingredients = data.ingredients.map(r => Item.fromGraphQL(r));
          if (sortByAttributeName != null) {
            ingredients = sortBy(ingredients, sortByAttributeName);
          }
          setItems(ingredients);
        }
      }
    });

  return {
    items,
    gettingItems: loading,
    listError: error?.message || null,
  };
}

export default useListIngredientsGraphQL;
