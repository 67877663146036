import React from 'react';
import { useNavigate } from 'react-router-dom';

import CardGrid, { CardGridProps } from '../common/CardGrid';
import { Producer } from '../../models';

import ProducerCard from './ProducerCard';
import useListProducersGraphQL from '../../graphql/hooks/useListProducersGraphQL';


type Props = CardGridProps & {
  onEdit?: (item: Producer|null) => void,
  onSelect?: (item: Producer|null) => void,
  typeName?: string,
  pluralName?: string,
}

const ProducersCardGrid = (props: Props) => {
  //console.log('Refreshing ProducersCardGrid');

  const {
    onEdit, onSelect, typeName, pluralName,
    ...cardGridProps} = props;
  const navigate = useNavigate();

  const displayTypeName = typeName || 'Producer';

  const handleNewProducer = () => {
    navigate(`/${displayTypeName?.toLowerCase()}-edit/new`)
  }

  return (
    <CardGrid
      card={<ProducerCard/>}
      useListGraphQL={useListProducersGraphQL}
      useListGraphQLProps={{typeName: typeName}}
      name={displayTypeName}
      pluralName={pluralName || `${displayTypeName}s`}
      onAddClick={handleNewProducer}
      onEdit={onEdit}
      onSelect={onSelect}
      {...cardGridProps}
    />
  );
};

export default ProducersCardGrid;
