import {Item} from './Item';
import {PlaceTypeDetails} from '../graphql/types/PlaceTypeDetails';
import {PlaceTypeInput} from '../graphql/types/globalTypes';


export class PlaceType extends Item {
  description?: string|null;

  setFromGraphQL(data: Partial<PlaceTypeDetails>|null) {
    super.setFromGraphQL(data);
    if (data != null) {
      this.description = data.description == null ? undefined : data.description;
    }
  }

  toInput(allProps=false): PlaceTypeInput {
    const input = super.toInput(allProps);
    if (allProps) {
      return {
        ...input,
        description: this.description,
      };
    }
    return input;
  }
}
