import {BaseInput, BaseModel} from './Base';


export class ItemCore extends BaseModel {

  id?: string;

  static fromItem(item: Item): ItemCore {
    const base = new ItemCore();
    base.id = item.id;
    return base;
  }

  toInput(allProps=false): ItemInput {
    return {
      id: this.id,
    };
  }
}


export type ItemInput = BaseInput & {
  id?: string|null,
  name?: string|null,
}

export class Item extends BaseModel {

  constructor(id?: string, name?: string) {
    super();
    this.id = id;
    this.name = name;
  }

  id?: string;
  name?: string;

  setFromGraphQL(data: ItemInput|null) {
    if (data == null) return;

    super.setFromGraphQL(data);
    this.id = data.id == null ? undefined : data.id;
    this.name = data.name == null ? undefined : data.name;
  }

  toCore = () => {
    return ItemCore.fromItem(this);
  }
  toInput(allProps=false): ItemInput {
    if (allProps) {
      return {
        id: this.id,
        name: this.name,
      };
    } else {
      return {
        id: this.id,
      };
    }
  }
}
