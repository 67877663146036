import React, {
  ChangeEvent,
  Fragment,
  ReactElement,
  ReactNode,
  cloneElement,
  useContext,
  useState, useEffect,
} from 'react';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField, Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';

import { AuthContext } from '../../contexts';
import { useWidth } from '../../helpers/useWidth';
import { Item } from '../../models';


// TODO: Implement responsive ImageList
//  https://github.com/mui-org/material-ui/issues/17000


const styles = {
  root: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    marginLeft: '10px',
    //marginRight: 10,
    marginTop: '20px',
  },
  gridList: {
    marginTop: '20px',
  },
  gridListTile: {
  }
};

type Width = 10 | 6 | 4 | 3 | 2 | 5 | 1 | 12 | 7 | 8 | 9 | 11;

const WIDTHS: {[key: string]: Width} = {
  xs: 6,
  sm: 4,
  md: 3,
  lg: 3,
  xl: 2,
}

export type GridCardProps<T extends Item> = {
  item?: T,
  name?: string,
}

export type CardGridProps = {
  hideAdd?: boolean,
  hideHeader?: boolean,
  hideMore?: boolean,
  hideSearch?: boolean,
  hideTitle?: boolean,
  name?: string,
  numRows?: number,
  pluralName?: string,
}

export type UseListGraphQLReturnValue<T extends Item> = {
  items: T[],
  gettingItems: boolean,
  listError: string | null,
};

type Props<T extends Item> = CardGridProps & {
  card: ReactNode,
  useListGraphQL: (any: any) => UseListGraphQLReturnValue<T>,
  useListGraphQLProps?: {[key: string]: any},
  headerComponent?: ReactNode,
  onEdit?: (item: T|null) => void,
  onSelect?: (item: T|null) => void,
  onAddClick?: () => void,
}

function CardGrid<T extends Item>(
    props: Props<T>
) {
  //console.log('Refreshing CardGrid');

  const {card,
    hideAdd, hideHeader, hideMore, hideSearch, hideTitle,
    name, numRows=5, pluralName,
    useListGraphQL, useListGraphQLProps,
    headerComponent, onAddClick,
  } = props;

  const width = useWidth();

  const [numDisplayRows, setNumDisplayRows] = useState<number>(0);
  useEffect(() => {
    if (numRows != null) {
      setNumDisplayRows(numRows);
    }
  }, [numRows]);

  const [numDisplayCards, setNumDisplayCards] = useState<number>(0);
  useEffect(() => {
    const w = WIDTHS[width];
    if (w != null) {
      const numPerLine = Math.ceil(12/w);
      setNumDisplayCards(numPerLine * numDisplayRows);
    }
  }, [numDisplayRows, width]);

  const [searchString, setSearchString] = useState<string>('');

  const {items: graphQLItems} = useListGraphQL({
      filterString: searchString,
      limit: numDisplayCards,
    ...useListGraphQLProps
  });

  const {canContribute} = useContext(AuthContext);

  const GridCard =  (props: GridCardProps<T>) => cloneElement(
      card as ReactElement<any>,
      {item: props.item, name: name, editable: canContribute});

  /*
  // cloneElement seems to disable the form submit execution so this code
  // moved to each component referencing this component
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const EditDialog =  () => cloneElement(
      editDialog as ReactElement<any>,
      {open: openAddDialog, onClose: handleCloseDialog});

  const handleCloseDialog = (value: string) => {
    setOpenAddDialog(false);
  };
  */

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    event.persist();
    setSearchString(event.target.value);
  }

  const retrieveMore = () => {
    setNumDisplayRows(old => old + (numRows || 0));
  }

  const renderHeader = () => {
    return hideHeader ? null :
      <Box sx={styles.header}>
        {pluralName && !hideTitle &&
          <Typography variant='h2'>
            {pluralName}
          </Typography>
        }
        {!hideSearch &&
          <TextField
            label='Search'
            value={searchString}
            onChange={handleSearchChange}
            type='text'
            variant='outlined'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Search/>
                </InputAdornment>
              )
            }}
          />
        }
        {headerComponent &&
          headerComponent
        }
        {(!hideAdd && canContribute) &&
          <Fragment>
            <Button
              onClick={onAddClick}
              variant='outlined'
            >
              {`Add${name && (' A New '+ name)}`}
            </Button>
          </Fragment>
        }
      </Box>
  }

  return (
    <Box sx={styles.root}>
      {renderHeader()}
      <Grid container sx={styles.gridList} spacing={1}>
        {graphQLItems?.map((item: T) => (
          <Grid item xs={WIDTHS.xs} sm={WIDTHS.sm} md={WIDTHS.md}
                lg={WIDTHS.lg} xl={WIDTHS.xl}
              key={item.id}
              sx={styles.gridListTile}
          >
            <GridCard
              item={item}
              name={name}
            />
          </Grid>
        ))}
      </Grid>
      {!hideMore &&
        <Box sx={styles.footer}>
            <Button
              onClick={retrieveMore}
              variant='outlined'
            >
              See More
            </Button>
        </Box>
      }
    </Box>
  )
}

export default CardGrid;
