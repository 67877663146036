import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { InputEvent } from '../../helpers/useFormValidation';
import { Location } from '../../models';
import TextEdit from './TextEdit';
import SingleSelect from './SingleSelect';
import useGetPicklistGraphQL from '../../graphql/hooks/useGetPicklistGraphQL';


type Errors = {
  [K in keyof Location]?: string;
};

interface Props {
  location?: Location,
  onChange?: (v: Location) => void
}

const LocationEdit = (props: Props) => {

  const { location, onChange } = props;

  const errors = {} as Errors;

  const [_location, setLocation] = useState<Location|null>(null);

  const {strings: countryList} = useGetPicklistGraphQL({
    name: 'Countries'});
  const {strings: stateAbbrList} = useGetPicklistGraphQL({
    name: 'US States'});

  useEffect(() => {
    if (location != null) {
      if (location.country == null) {
        setLocation({...location, country: 'United States'} as Location);
      } else {
        setLocation(location);
      }
    } else {
      setLocation(Location.fromMap({country: 'United States'}));
    }
  }, [location]);

  const updateProperty = (name: string, value: any) => {
    const newLocation = _location == null ? new Location() :_location.clone();
    newLocation[name] = value;
    //setLocation(newLocation);
    onChange && onChange(newLocation);
  };

  const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target != null) {
      updateProperty(event.target.name, event.target.value);
    }
  };

  //const [_coords, setCoords] = useState<string>('');
  const _coordsString = _location?.latitude || _location?.longitude
    ? `${_location?.latitude || ''}, ${_location?.longitude || ''}`
    : '';
  function handleCoordsChange(event: InputEvent) {
    const newCoords = (event.target.value || '').split(',');
    console.log('newCoords', newCoords, event.target.value);
    const newLocation = _location == null ? new Location() :_location.clone();
    newLocation.latitude = newCoords.length > 0 ? Number(newCoords[0]) : undefined;
    newLocation.longitude = newCoords.length > 1 ? Number(newCoords[1]) : undefined;
    //setLocation(newLocation);
    onChange && onChange(newLocation);
  }

  return (
    <Box>
      <TextEdit
        name='street'
        label='Street'
        onChange={handleTextFieldChange}
        value={_location?.street}
      />
      <TextEdit
        name='street2'
        label='Street 2'
        onChange={handleTextFieldChange}
        value={_location?.street2}
      />
      <TextEdit
        name='city'
        label='City'
        onChange={handleTextFieldChange}
        value={_location?.city}
      />
      {_location?.country !== 'United States'
        ? <TextEdit
            name='state'
            label='State'
            onChange={handleTextFieldChange}
            value={_location?.state}
          />
        : <SingleSelect
            name='state'
            label='State'
            onChange={(val) => updateProperty('state', val)}
            value={_location?.state}
            items={stateAbbrList}
          />
      }
      <TextEdit
        error={errors.zip}
        label='Zipcode'
        name='zip'
        onChange={handleTextFieldChange}
        value={_location?.zip || ''}
      />
      <SingleSelect
        name='country'
        label='Country'
        onChange={(val) => updateProperty('country', val)}
        value={_location?.country || 'United States'}
        items={countryList}
      />
      <TextEdit
        error={errors.coordsString}
        label='Latitude, Longitude'
        name='coordinatesString'
        onChange={handleCoordsChange}
        value={_coordsString}
      />
    </Box>
  );

};

export default LocationEdit;
