import React, { forwardRef, HTMLAttributes } from 'react'
import {
  LinkProps as RouterLinkProps,
  NavLink,
  NavLinkProps
} from 'react-router-dom'
import {
  ExpandLess, ExpandMore, FiberManualRecord,
} from '@mui/icons-material';
import {
  Box,
  Collapse,
  List, ListItemButton, ListItemIcon, ListItemText,
  Tooltip,
} from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';
import { SvgIconProps } from '@mui/material/SvgIcon'


export interface SidebarNavItemProps {
  name?: string
  link?: string
  Icon?: React.ComponentType<SvgIconProps>
  IconStyles?: React.CSSProperties
  // IconClassName?: string
  IconClassNameActive?: string
  iconPadding?: number
  isCollapsed?: boolean
  isOpen?: boolean
  isNested?: boolean
  nestingLevel?: number
  nestingOffset?: number
  sx?: SxProps
  items?: SidebarNavItemProps[]
  match?: object
  onClick?: () => void
}

export interface ListItemLinkProps extends NavLinkProps {}

export interface ListItemComponentProps extends HTMLAttributes<HTMLElement> {
  link?: string | null
  children?: any
  isCollapsed?: boolean | null
  sx?: SxProps
}


export const ListItemLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => (
  <div style={{ flexGrow: 1 }} >
    <NavLink end {...props} ref={ref} />
  </div>
));


export const ListItemComponent: React.ExoticComponent<
  ListItemComponentProps
> = forwardRef((props: ListItemComponentProps, ref: React.Ref<HTMLDivElement>) => {
  // Omit isCollapsed
  const { isCollapsed, ...newProps } = props;

  const styles = {
    navItemCollapsedWrapper: {
      //width: theme.sidebar.widthCollapsed,
    },
  };

  const component =
    typeof props.link === 'string' ? (
      <ListItemButton {...newProps} component={ListItemLink} to={props.link} />
    ) : (
      <ListItemButton {...newProps} />
    );

  return (
    <Box ref={ref} sx={isCollapsed ? styles.navItemCollapsedWrapper : null}>
      {component}
    </Box>
  )
});

const SidebarNavItem: React.FC<SidebarNavItemProps> = (props: SidebarNavItemProps) => {
  const {
    name,
    link,
    Icon,
    IconStyles = {},
    // IconClassName = '',
    iconPadding = 40,
    isCollapsed,
    // isNested,
    nestingLevel = 0,
    nestingOffset = 0,
    sx,
    items = [],
    onClick,
  } = props;

  const isTooltipEnabeld = isCollapsed;

  const theme = useTheme();
  const styles = {
    // nested: {
    //   paddingLeft: theme.spacing(10),
    // },
    navItemWrapper: {
      position: 'relative',
    },
    navItemWrapperActive: {
      // background: 'rgba(0, 0, 0, 0.08)',
    },
    navItemWrapperActiveCollapsed: {
      background: 'rgba(0, 0, 0, 0.08)',
    },
    navItem: {
      position: 'relative',
      transition: 'background .23s ease',
      '&.active': {
        color: theme.palette.secondary.main,
        // background: 'rgba(0, 0, 0, 0.08)',
        '& .MuiListItemIcon-root': {
          // color: '#fff',
          color: theme.palette.secondary.main,
        },
      },
    },
    navItemChildren: {
      transition: 'background .23s ease',
      // position: 'absolute',
    },
    navItemChildrenActive: {
      // background: 'rgba(0, 0, 0, 0.1)',
    },
    navItemCollapsed: {
      whiteSpace: 'nowrap',
      flexWrap: 'nowrap',
      //width: theme.sidebar.widthCollapsed,
      '& $iconToggle': {
        position: 'absolute',
        bottom: -1,
        fontSize: 14,
        left: '50%',
        marginLeft: '-0.5em',
      },
      '&.active': {
        background: 'rgba(0, 0, 0, 0.08)',
      },
    },
    navItemCollapsedWrapper: {
      //width: theme.sidebar.widthCollapsed,
    },
    navItemIcon: {
      minWidth: 40,
    },
    iconToggle: {},
    iconSpacer: {
      fontSize: 13,
      marginLeft: 6,
    },
  };

  const hasChildren = items && items.length > 0;

  // Flattened array of all children
  function getItemsAll(items: SidebarNavItemProps[]): SidebarNavItemProps[] {
    return items.reduce((allItems: SidebarNavItemProps[], item: SidebarNavItemProps) => {
      // let res = allItems.concat([item])

      if (item.items && item.items.length) {
        return allItems.concat([item], getItemsAll(item.items))
      } else {
        return allItems.concat([item])
      }
    }, [])
  }

  const itemsAll = getItemsAll(items);
  const hasChildrenAndIsActive =
    hasChildren &&
    itemsAll.filter(item => `#${item.link}` === window.location.hash).length > 0;
  const isOpen = hasChildrenAndIsActive || false;
  const [open, setOpen] = React.useState(isOpen);

  function handleClick() {
    setOpen(!open);
    if (link != null && onClick != null) {
      onClick();
    }
  }

  const ListItemIconInner =
    (!!Icon && <Icon />) ||
    (isCollapsed && <FiberManualRecord sx={styles.iconSpacer} />) ||
    '';

  const nestingOffsetChildren = !isCollapsed ? nestingOffset + 16 : 16;

  const ListItemElement = (
    <ListItemComponent
      link={link}
      sx={[
        styles.navItem,
        isCollapsed ? styles.navItemCollapsed : null,
        // isNested && !isCollapsed && classes.nested,
        hasChildrenAndIsActive && 'active',
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      style={{
        fontSize: `${1 - 0.07 * nestingLevel}em`,
        paddingLeft: `${!ListItemIconInner ? nestingOffset + iconPadding : nestingOffset}px`,
      }}
      isCollapsed={isCollapsed}
      onClick={handleClick}
    >
      {!!ListItemIconInner && (
        <ListItemIcon
          style={IconStyles}
          // sx={[styles.navItemIcon, IconClassName]}
          sx={styles.navItemIcon}
        >
          {ListItemIconInner}
        </ListItemIcon>
      )}
      <ListItemText primary={name} disableTypography={true} />
      {hasChildren && !open && <ExpandMore sx={styles.iconToggle} />}
      {hasChildren && open && <ExpandLess sx={styles.iconToggle} />}
    </ListItemComponent>
  );

  const ListItemRoot = isTooltipEnabeld ? (
    <Tooltip
      disableFocusListener={!isTooltipEnabeld}
      disableHoverListener={!isTooltipEnabeld}
      disableTouchListener={!isTooltipEnabeld}
      title={name || ""}
      placement="right"
    >
      {ListItemElement}
    </Tooltip>
  ) : (
    ListItemElement
  );

  const ListItemChildren = hasChildren ? (
    <Box sx={styles.navItemChildren}>
      <Collapse in={open} timeout='auto' unmountOnExit>
        {/* <Divider /> */}
        <List component="div" disablePadding>
          {items.map(item => (
            <SidebarNavItem
              {...item}
              isNested={true}
              nestingLevel={nestingLevel + 1}
              isCollapsed={isCollapsed}
              key={item.name || item.link}
              isOpen={open}
              nestingOffset={nestingOffsetChildren}
              onClick={onClick}
            />
          ))}
        </List>
      </Collapse>
    </Box>
  ) : null;

  return (
    <Box
      sx={[
        hasChildrenAndIsActive ? styles.navItemWrapperActive : null,
        hasChildrenAndIsActive && isCollapsed ? styles.navItemWrapperActiveCollapsed : null,
      ]}
    >
      {ListItemRoot}
      {ListItemChildren}
    </Box>
  )
};

export default SidebarNavItem