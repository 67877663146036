import {useState} from 'react';

import {useQuery} from '@apollo/client';
import getQuery from '../queries/getPlaceQuery.graphql';
import {GetPlace, GetPlaceVariables} from '../types/GetPlace';

import {Place} from '../../models';

const Item = Place;
type GetItem = GetPlace;
type GetVariables = GetPlaceVariables;
type Item = Place;

type Props = {
  currentId?: string|null,
  name?: string,
  model?: { new (): Item; },
}

const useGetPlaceGraphQL = (props?: Props) => {

  const {currentId, name, model=Item} = {...props};

  const [item, setItem] = useState<Item|null>(null);

  const {loading, error} = useQuery<GetItem, GetVariables>(getQuery, {
    variables: {place: {placeId: currentId}},
    fetchPolicy: 'cache-and-network',
    skip: currentId == null && name == null,
    onCompleted: data => {
      if (data.place != null) {
        const newItem = new model();
        newItem.setFromGraphQL(data.place);
        setItem(newItem);
      }
    },
  });

  return {
    item,
    gettingItem: loading,
    getError: error?.message || null,
  };
}

export default useGetPlaceGraphQL;
