import {PicklistItem} from './PicklistItem';

export class BrewersAssociationStyle extends PicklistItem {

  family?: string;
  origin?: string;
  description?: string;
  website?: string;

  setFromGraphQL(data: Partial<BrewersAssociationStyle>|null) {
    if (data == null) {
      return;
    }
    const {family, origin, description, website} = data;
    this.family = family == null ? undefined : family;
    this.origin = origin == null ? undefined : origin;
    this.description = description == null ? undefined : description;
    this.website = website == null ? undefined : website;
  }
}
